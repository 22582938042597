import React, {useEffect, useState, useContext} from "react";
import moment from "moment";
import {AppContext} from "./../context/AppContext";

const Image = (props) => {
	const [trigImgLoad, setTrigImgLoad] = useState(false);
	const [imgIsLoading, setImgIsLoading] = useState(false);
	const [apiSrc, setApiSrc] = useState("");

	const {appState} = useContext(AppContext);

	const executeClickListeners = (isFirstInitialization) => {
		const changeEvent = props.events && props.events.find((ev) => {
			return (ev.listener === "click");
		});

		if (changeEvent) {
			const domevent = {target: {value: "", id: props.id}};

			props.assignEvents(domevent, changeEvent, props, isFirstInitialization, appState);
		}
	};

	const onClickHandler = (e) => {
		executeClickListeners(null);
	};

	const goToLink = (url, openInNewTab) => {
		onClickHandler();

		if (openInNewTab) {
			const win = window.open(url, "_blank");

			win.focus();
		} else {
			window.open(url);
		}
	};

	useEffect(() => {
		setImgIsLoading(true);

		if (props.classes.includes("trig-img-load")) {
			setTrigImgLoad(true);
			setTimeout(() => {
				setTrigImgLoad(false);
			}, 500);
		}

		let urlSrc;

		if (props.settings && props.settings.isApiImg) {
			urlSrc =process.env.REACT_APP_BACKEND_BASEURL + "/api/" + window._TOKEN_ + "/dinamica-attachment/" + props.value + "/" + Math.random() + "/" + moment().utcOffset();
		} else if (props.classes.includes("crop-service") && props.value) {
			urlSrc =process.env.REACT_APP_BACKEND_BASEURL + "/api/" + window._TOKEN_ + "/dinamica-attachment/" + props.value + "/" + Math.random() + "/" + moment().utcOffset();
		} else if (props.classes.includes("bareme-image")) {
			urlSrc =process.env.REACT_APP_BACKEND_BASEURL + "/api/" + window._TOKEN_ + "/bareme-image/" + Math.random();
		} else if (props.classes.includes("bareme-batch-image")) {
			urlSrc =process.env.REACT_APP_BACKEND_BASEURL + "/api/" + window._TOKEN_ + "/bareme-batch-image/" + Math.random();
		} else if (props.classes.includes("static-file")) {
			urlSrc =process.env.REACT_APP_BACKEND_BASEURL + "/api/static-file/" + props.value;
		} else if (props.value) {
			urlSrc = appState.images[props.value];
		}

		if (urlSrc) {
			setApiSrc(urlSrc + "?vitr_session=" + appState.tk_download);
		}
	}, []);

	const handleImageLoaded = (e) => {
		setImgIsLoading(false);
	};

	const handleImageErrored = (e) => {
		setImgIsLoading(false);
	};

	const isLoading = (props.settings && props.settings.isLoadingEnabled && (trigImgLoad || imgIsLoading));
	const hideSpinner = (props.settings && props.settings.hideSpinner);
	const loaderCmp = <div className={"circle-loader " + (props.classes.includes("invisible-load-spinner") ? "invisible-load-spinner" : "")}>
		<div className="checkmark draw"></div>
	</div>;
	const isLoadingClass = isLoading ? " is-loading" : "";
	const isLoadingEnabledClass = (props.settings && props.settings.isLoadingEnabled) ? " is-loading-enabled" : "";
	const spinner = isLoading ? loaderCmp : null;
	const isLinkClass = (props.settings && props.settings.isLink === true) ? " is-link" : "";

	return (
		<div className={props.type + " image-container " + props.classes + isLinkClass + isLoadingEnabledClass + isLoadingClass} style={props.style}>
			{!hideSpinner && spinner}
			<img
				className={imgIsLoading ? "is-loading" : "is-loaded"}
				src={apiSrc} alt={props.altValue}
				onLoad={handleImageLoaded}
				onError={handleImageErrored}
				onClick={(props.settings && props.settings.isLink === true) ? () => goToLink(props.settings.href, props.settings.openInNewTab) : null} />
		</div >
	);
};

export default Image;
