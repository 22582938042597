import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Uploader} from "../utils/imports";

class GrigImageUploader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			impactedPoints: [],
			veichlePrecenceWarningPresent: false,
			checkWarningInterval: null,
		};
	};

	mapUrtoImpactPoints = {
		"fronte-ns": ["top-left", "top-center", "top-right"],
		"destra-ns": ["top-right", "center-right", "bottom-right"],
		"retro-ns": ["bottom-left", "bottom-center", "bottom-right"],
		"sinistra-ns": ["top-left", "center-left", "bottom-left"],
	};

	componentDidMount() {
		const p = this.props;

		p.triggerCheckScrolldown(p);
		this.setState({impactedPoints: []});
		this.computeImpactedPoints();
		this.setState({
			checkWarningInterval: setInterval(() => {
				const vpWarningList = document.querySelectorAll(".vehicle-presence-warning");

				this.setState({veichlePrecenceWarningPresent: (vpWarningList && vpWarningList.length > 0)});
			}, 500),
		});
	}

	componentWillUnmount() {
		clearInterval(this.state.checkWarningInterval);
	}

	computeImpactedPoints() {
		let _impactedPoints = [];

		// If accident dynamic has been modified (if "id": "35-0-0-11" ("name": "bareme_dynamic_user_confirmed_or_modified") has value ["BAREMES_MODIFIED_CONFIRMED"]),
		// --> then take first assessment question, "Dove sei stato urtato?" ("id": "11-0-0-0", "name": "bareme_q_1") and give priority to that answer
		const btnConfirmedModifiedPlainValue = this.props.getTargetValueByName("bareme_dynamic_user_confirmed_or_modified", "value");
		const kascoCaseValue = this.props.getTargetValueByName("is_kasco_case", "value");

		if (btnConfirmedModifiedPlainValue === "BAREMES_MODIFIED_CONFIRMED") {
			const nsWidgetValue = this.props.getTargetValueByName("bareme_q_1", "value");

			_impactedPoints = nsWidgetValue && this.mapUrtoImpactPoints[nsWidgetValue];
		} else if ((btnConfirmedModifiedPlainValue === "BATCH_BAREMES_CONFIRMED") || (kascoCaseValue === "true")) {
			// Else, if the user confirmed the proposed dynamic (if "id": "35-0-0-11" ("name": "bareme_dynamic_user_confirmed_or_modified")
			// has value ["BATCH_BAREMES_CONFIRMED"]), or in case of kasco (if "id": "35-0-0-12" has value ["true"])
			// -->  than search for the main impact point
			// (template "main_impact_point" property, that is inserted as placeholder in "id": "35-0-0-10" ("name": "main_impact_point_widget")
			const mainImpactPointValue = this.props.getTargetValueByName("main_impact_point_widget", "value");

			if (mainImpactPointValue) {
				_impactedPoints.push(mainImpactPointValue.toLowerCase());
			}
		}

		if (_impactedPoints && _impactedPoints.length >= 0) {
			this.setState({impactedPoints: _impactedPoints});
		}
	}

	isGridItemSuggested(gridItemClasses) {
		const foundImpactedPoint = this.state.impactedPoints.filter((point) => {
			return !!gridItemClasses && gridItemClasses.indexOf(point) >= 0;
		});

		return foundImpactedPoint && foundImpactedPoint.length > 0;
	}

	renderUpload(gridItemEl) {
		const uploadEl = gridItemEl && gridItemEl.widgets && gridItemEl.widgets.length > 0 && gridItemEl.widgets[0];
		let returnedUploader = false;

		if (uploadEl) {
			returnedUploader = <Uploader
				appendValues={this.props.appendValues} removeValues={this.props.removeValues}
				triggerCheckScrolldown={this.props.triggerCheckScrolldown} globalStyle={this.props.globalStyle}
				{...uploadEl} composeWidget={this.props.composeWidget} propagatedAppState={this.props.propagatedAppState}
				data={this.props.data} assignEvents={this.props.assignEvents} addEvents={this.props.addEvents}/>;
		}

		return returnedUploader;
	}

	suggestedDamagesEnabled(wProps) {
		return wProps && wProps.settings && wProps.settings.suggestedDamagesEnabled === true;
	}

	renderGrid(wProps) {
		const gridItems = [];
		let rowElArray = [];

		wProps.widgets.forEach((gItem, index, arr) => {
			const currentElement = (
				<td
					key={index}
					className={gItem.classes + " grid-item " + (this.suggestedDamagesEnabled(wProps) && this.isGridItemSuggested(gItem.classes) ? "suggested-damaged-zone" : "")}>
					{(this.renderUpload(gItem))}
				</td>);

			rowElArray.push(currentElement);

			// if multiple of three, then create row
			if ((index + 1) % 3 == 0) {
				gridItems.push(<tr key={index}>{rowElArray}</tr>);
				rowElArray = [];
			}
		});

		const gridResult = <table className={wProps.type + " " + wProps.classes + " grid-container"} style={wProps.style}><tbody>{gridItems}</tbody></table>;

		return gridResult;
	}

	render() {
		const p = this.props;
		const carDesignImg = p.propagatedAppState.images.carDesignImg;
		const vehicleWarningElement = this.state.veichlePrecenceWarningPresent ?
			<div className="grid-vehicle-presence-warning"><span>*</span> Attenzione. Una o più foto non sembrano contenere un veicolo</div> : null;

		return (

			<div className={p.type + " " + p.classes} style={p.style}>
				<div className="pure-container">
					<div className="img-container">
						<img src={carDesignImg} alt="Disegno macchina" />
						{this.renderGrid(p)}
					</div>
					{vehicleWarningElement}
				</div>
			</div>
		);
	};
}

;

export default withRouter(GrigImageUploader);
