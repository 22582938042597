import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Markdown from "markdown-to-jsx";
import DOMPurify from "dompurify";

class Checkbox extends Component {
	constructor(props) {
		super(props);
		this.onCheckboxChange = this.onCheckboxChange.bind(this);
		this.onCheckboxClick = this.onCheckboxClick.bind(this);
	};

	/* onClick is introduced for Internet Explorer compatibility as onChange is not triggered on IE.
    onChange has been left to avoid react warning */
	onCheckboxClick(e) {
		const {value} = e.currentTarget;
		const {id, data, settings, computeStaticConditions, appendValue} = this.props;
		let newValue;

		// If the clicked checkbox is already selected then remove it otherwise add his value and update state
		if (this.props.value.includes(value)) {
			newValue = this.props.value.filter((val) => val !== value);
		} else {
			newValue = [...this.props.value, value];
		}

		const json = {id, domevent: {target: {value: newValue, id}}};

		// By using the appendValue function update the state
		appendValue(json, {target: null, value: null});

		if (settings && settings.computeStaticConditions === true) {
			computeStaticConditions(data);
		}

		this.executeChangeListeners(e, null, newValue);
	}

	executeChangeListeners(e, isFirstInitialization, value) {
		const {events, assignEvents, id} = this.props;
		const changeEvent = events && events.find((ev) => ev.listener === "change");

		if (changeEvent) {
			const domevent = {target: {value, id}};

			assignEvents(domevent, changeEvent, this.props, isFirstInitialization);
		}
	}

	onCheckboxChange(e) {
		return;
	}

	componentDidMount() {
		const {options, value, events, assignEvents, id} = this.props;

		options.forEach((opt) => {
			if (value.includes(opt.value)) {
				const el = {currentTarget: {value: opt.value, id: opt.id}};

				this.executeChangeListeners(el, true, value);
			}
		});

		const mountedEvent = events && events.find((ev) => ev.listener === "mount");

		if (mountedEvent) {
			const domevent = {target: {value: "", id}, showMessages: false};

			assignEvents(domevent, mountedEvent, this.props);
		}
	}

	isCheckboxChecked(_props, _elValue) {
		return _props.value.includes(_elValue);
	}

	render() {
		const {options, style, classes, label} = this.props;
		const result = options.map((elem, i) => (
			<div key={i} className={"hrms-checkbox" + (this.isCheckboxChecked(this.props, elem.value) ? " is-checked" : "")}>
				<label htmlFor={elem.id} className="int-label">
					<input
						disabled={elem.disabled}
						onClick={this.onCheckboxClick}
						onChange={this.onCheckboxChange}
						id={elem.id}
						type="checkbox"
						value={elem.value}
						checked={this.props.value.includes(elem.value)}
					/>
					<div disabled={elem.disabled} className="text">{elem.markdown ? <Markdown>{DOMPurify.sanitize(elem.markdown)}</Markdown> : <p>{elem.description}</p>}</div>
				</label>
			</div>
		));

		return <div style={style} className={`checkbox-ext-container ${classes ?? ""}`}>
			<label className="checkbox-ext-label" style={style && style.label}>{label}</label>
			<div className="checkbox-int-container" style={style && style["container"]}>{result}</div>
		</div >;
	};
}

export default withRouter(Checkbox);
