import React, {Component} from "react";

class Icon extends Component {
	constructor(props) {
		super(props);
		this.isFontAwesomIcon = this.isFontAwesomIcon.bind(this);
	};

	isFontAwesomIcon() {
		return this.props && this.props.classes && this.props.classes.indexOf("fas") >= 0;
	}

	render() {
		return (
			<div className="icon-wrapper">
				{this.isFontAwesomIcon() ?
					<i className={this.props.classes}></i> :
					<icon>{this.props.label}</icon>
				}
			</div>
		);
	}
}

export default Icon;
