import images from "../theme/images/images";

export const _LOADING_ERROR_ = {
	"steps": [{
		"id": "0",
		"widgets": [
			{
				"id": "0-0",
				"type": "hrms-card",
				"classes": "token-expired flex-column-start",
				"widgets": [
					{
						"id": "0-0-0",
						"type": "hrms-section",
						"value": "Errore temporaneo, si prega di provare più tardi",
					},
				],
			},
		],
	}],
};

export const initialAppState = {
	theme: null,
	loading: true,
	data: null,
	firstFlag: true,
	globalStyle: null,
	configValues: null,
	errorMessage: "",
	errorStatus: null,
	tk_download: null,
	actualRoute: 0,
	showScrollDown: true,
	isLoading: false,
	routeNavigated: false,
	historyListened: false,
	appHistory: [],
	images: images.unipol,
	// captcha
	challengeId: null,
	recaptchaValue: null,
	captchaDone: false,
	// actions
	backandDataLoaded: false,
	forceRedirect: 0,
	communicationSupportLocked: false,
	communicationSupportMessage: "Supporto in corso",
	_LOADING_ERROR_: _LOADING_ERROR_,
};
