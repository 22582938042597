import React, {Component} from "react";

class Scrolldown extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.timeout = null;
		this.state = {
			class: "footer",
			show: false,
		};
		this.listener = null;
		this.trackScrolling = this.trackScrolling.bind(this);
		this.h = null;
		this.cnt = 1;
	};

	componentDidMount() {
		this._isMounted = true;

		const body = document.body;
		const html = document.documentElement;
		const totalHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
		const windowHeight = window.innerHeight;
		const yOffset = window.pageYOffset;

		if ((totalHeight - windowHeight) !== yOffset) {
			this.h = window.innerHeight * this.cnt - 55;

			if (window.innerHeight <= (document.getElementsByClassName("globalRoot")[0] && document.getElementsByClassName("globalRoot")[0].getBoundingClientRect().bottom)) {
				this.setState({class: "footer"});
			} else {
				this.setState({class: null});
			}

			this.listener = document.addEventListener("scroll", () => {
				if (this._isMounted) {
					this.trackScrolling();
				}
			});

			this.timeout = setTimeout(() => {
				if (this._isMounted) {
					this.setState({show: true});
				}
			}, 600);
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.class !== nextState.class || this.state.show !== nextState.show) {
			return true;
		}

		return false;
	}

	componentWillUnmount() {
		this._isMounted = false;

		if (this.listener) {
			document.removeEventListener("scroll", this.listener);
		}

		clearTimeout(this.timeout);
	}

	componentDidUpdate() {
		this.h = window.innerHeight * this.cnt - 55;
	}

	trackScrolling() {
		const totalHeight = document.documentElement.getBoundingClientRect().bottom;
		const windowHeight = window.innerHeight;

		if ((totalHeight - windowHeight) > 5) {
			this.setState({class: "footer"});
		} else {
			this.setState({class: "footer fade-in"});
		}
	};

	scrollDown() {
		this.timer((this.cnt - 1) * window.innerHeight - 55, this.h);
		// console.log((this.cnt - 1) * window.innerHeight, this.h);
		this.cnt++;
		this.h = window.innerHeight * this.cnt - 55;
	}

	timer(height, maxHeight) {
		setTimeout(() => {
			window.scrollTo(0, height);

			if (height < maxHeight) {
				height += 3;
				this.timer(height, maxHeight);
			}
		}, 0.5);
	}

	render() {
		return (
			this.state.show && this.state.class && (
				<div className={this.state.class}>
					<div className="arrow" onClick={() => this.scrollDown()}></div>
				</div>
			)
		);
	};
}

export default Scrolldown;
