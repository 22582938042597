import React, {Component} from "react";
import Markdown from "markdown-to-jsx";
import conversions from "./../utils/custom_conversions";
import DOMPurify from "dompurify";

class Text extends Component {
	constructor(props) {
		super(props);
	};

	executeCustomConversions(txtIn) {
		let txtOut = txtIn;

		conversions.conversionFunctionsName.forEach((fName) => {
			if (txtIn.indexOf("$" + fName) >= 0) {
				txtOut = conversions[fName](txtOut, this.props.configValues);
			}
		});
		return txtOut;
	}

	render() {
		const p = this.props;
		const content = p.markdown ? <Markdown>{DOMPurify.sanitize(this.executeCustomConversions(p.markdown))}</Markdown> : p.value;

		return (
			<div className={p.type + " " + p.classes} style={p.style}>{content}</div>
		);
	};
}

;

export default Text;
