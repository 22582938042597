import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Markdown from "markdown-to-jsx";
import DOMPurify from "dompurify";

class Radio extends Component {
	constructor(props) {
		super(props);
		this.onRadioChange = this.onRadioChange.bind(this);
		this.onRadioClick = this.onRadioClick.bind(this);
	};

	/* onClick is introduced for Internet Explorer compatibility as onChange is not triggered on IE.
    onChange has been left to avoid react warning */
	onRadioClick(e) {
		const p = this.props;
		const action = {target: null, value: null};
		const json = {id: this.props.id, domevent: {target: {value: e.currentTarget.value, id: this.props.id}}};

		this.props.appendValue(json, action);

		if (p.settings && p.settings.computeStaticConditions === true) {
			this.props.computeStaticConditions(p.data);
		}

		this.executeChangeListeners(e, null);
	}

	executeChangeListeners(e, isFirstInitialization) {
		const p = this.props;
		const changeEvent = p.events && p.events.find((ev) => {
			return (ev.listener === "change");
		});

		if (changeEvent) {
			const domevent = {target: {value: e.currentTarget.value, id: e.currentTarget.id}};

			this.props.assignEvents(domevent, changeEvent, p, isFirstInitialization);
		}
	}

	onRadioChange(e) {
		return;
	}

	componentDidMount() {
		// Loop through the options and when found the one that is checked (if any), than trigger the change event for that one
		this.props.options.forEach( (opt) => {
			if ((this.props.value.length > 0) && this.props.value[0] === opt.value) {
				const el = {currentTarget: {value: opt.value, id: opt.id}};

				this.executeChangeListeners(el, true);
			}
		});

		const mountedEvent = this.props.events && this.props.events.find((ev) => {
			return (ev.listener === "mount");
		});

		if (mountedEvent) {
			const domevent = {target: {value: "", id: this.props.id}, showMessages: false};

			this.props.assignEvents(domevent, mountedEvent, this.props);
		}
	}

	isRadioChecked(_props, _elValue) {
		return _props.value.includes(_elValue);
	}

	render() {
		const p = this.props;
		const result = p.options.map((elem, i) => (
			<div key={i} className={"hrms-radio " + (this.isRadioChecked(this.props, elem.value) ? "is-checked" : "")}>
				{(
					p.iconCheckedPresent &&
          this.isRadioChecked(this.props, elem.value) ?
						<img className="checked-icon" src={p.propagatedAppState.images["checked-icon"]}></img> :
						""
				)}
				<label htmlFor={elem.id} className="int-label">
					<input
						disabled={elem.disabled}
						onClick={this.onRadioClick}
						onChange={this.onRadioChange}
						id={elem.id}
						type="radio"
						defaultValue={elem.value}
						checked={this.props.value.includes(elem.value)}
					/>
					<div disabled={elem.disabled} className="text">{elem.markdown ? <Markdown>{DOMPurify.sanitize(elem.markdown)}</Markdown> : <p>{elem.description}</p>}</div>
				</label>
			</div>
		));

		return <div style={p.style} className={"radio-ext-container " + p.classes}>
			<label className="radio-ext-label" style={p.style && p.style.label}>{p.label}</label>
			<div className="radio-int-container" style={p.style && p.style["container"]}>{result}</div>
		</div >;
	};
}

export default withRouter(Radio);
