import React, {useContext, useEffect, useState} from "react";
import apiService from "../services/api";
import functions from "../utils/custom_actions";
import utilsFunctions from "../utils/utils_actions";
import {Redirect} from "react-router-dom";
import SecureRoute from "../utils/secured-route";
import {
	Layout, Page, Btn, Card, Text, Radio, Checkbox, Uploader, Input, Textarea,
	MapAddressFinder, GridImageUploader, GridImageUploaderIP, Image, IncrementalInput, Link, Autocomplete,
	Dropdown, Icon, SmsPreview, VideoPlayer, ShareBtn, GridImageUploaderUrto,
} from "../utils/imports";
import ChallengeCaptcha from "./../components/captcha";
import Scrolldown from "./../components/scrolldown";
import Footer from "./../components/footer";
import Popup from "./../components/popup";
import ErrorMessage from "./../components/errorMessage";
import {
	GetRouteFromPath, GetRedirectTarget, getTimeStampFormatted, isOperatorSupportUser,
	getNextStepIfDisabled, getPreviousStepIfDisabled,
} from "../utils/utils";
import images from "../theme/images/images";
import "../theme/styles/base.scss";
import {AppContext} from "./../context/AppContext";
import Pwa from "./pwa";
import Modal from "react-modal";
import getBrowserFingerprint from "../utils/browser-fingerprint";

let fingerprint;

export const unmountStepListeners = (step) => {
	if (step.events) {
		step.events.forEach((event) => {
			document.removeEventListener(event.listener, null);
		});
	}
};

export const executeGoBack = (appState, appDispatcher) => {
	const _h = [...appState.appHistory];

	if (_h && _h.length > 1) {
		// Remove las two elements
		const routeId = GetRouteFromPath(_h[1]);

		if (routeId && routeId !== "home") {
			_h.shift();
			_h.shift();

			appDispatcher({type: "UPDATE_STATE", payload: {actualRoute: routeId, appHistory: _h}});
			appState.history.push("/" + window._TOKEN_ + "/" + routeId);
		}
	}
};

export const checkScrollDown = (appDispatcher) => {
	let scrollDown = true;
	const html = document.getElementsByTagName("html")[0];

	if (html.offsetHeight <= window.innerHeight) {
		scrollDown = false;
	}

	appDispatcher({type: "UPDATE_STATE", payload: {showScrollDown: scrollDown}});
};

export const executeTealiumAnalytics = (settings) => {
	if (!window.utag || !window.utag[settings.analyticsType]) {
		return;
	}

	const params = Object.assign({}, settings.parameters, {action_timestamp: getTimeStampFormatted("YYYY-MM-DD HH:mm:ss")});

	window.utag[settings.analyticsType](params);
};

export const executeAnalyticsAction = (settings) => {
	if (!settings || !settings.analyticsPlatform) {
		return;
	}

	switch (settings.analyticsPlatform) {
	case "Tealium":
		executeTealiumAnalytics(settings);
		break;
	}
};

export const manageStyleError = (e, appDispatcher) => {
	let message = "";
	const errorStatus = e.response.status;

	if (e.response.status === 404) {
		message = "Spiacente, risorsa non disponibile";
	} else {
		message = "Servizio momentaneamente non disponibile, riprova più tardi";
	}

	appDispatcher({type: "UPDATE_STATE", payload: {loading: false, errorMessage: message, errorStatus}});
};

const optionallyAddMetadata = (metadataIn) => {
	if (fingerprint === undefined) {
		fingerprint = {
			fingerprint: getBrowserFingerprint({
				hardwareOnly: false,
				enableWebgl: true,
				debug: false},
			),
		};
	}

	let metadataSupport = {};

	if (isOperatorSupportUser(window._SUPPORT_OG_PARAM)) {
		metadataSupport = {
			isSupport: true,
		};
	}

	return {
		...metadataIn,
		...metadataSupport,
		...fingerprint,
	};
};

export const addEvents = (eventName, metaInfo) => {
	const apiUrl = "/api/" + window._TOKEN_ + "/post-events";
	const evBody = {ev: eventName, metadata: optionallyAddMetadata(metaInfo)};

	apiService.post(apiUrl, evBody);
};

export const navigateIfNeededHandler = (props, needsToRoute, routeToGo, appStateParam,
	appState, appDispatcher) => {
	const _appState = appStateParam || appState;
	// If route is already the targeted one, then return
	const actualTarget = GetRedirectTarget(window.location.href);
	const actualTargetInt = parseInt(actualTarget);

	if (actualTarget === "home") {
		return;
	}

	if (!isNaN(actualTargetInt) && actualTargetInt === routeToGo) {
		return;
	}

	if (needsToRoute) {
		_appState.history.push("/" + window._TOKEN_ + "/" + routeToGo);
		appDispatcher({type: "UPDATE_STATE", payload: {history: _appState.history}});
	}
};

export const executePostListener = (tree, details, action) => {
	const confirmListener = utilsFunctions["postListener"](tree, details, action);
	const apiConfirmListener = "/api/" + window._TOKEN_ + "/listener";
	const plbody = {data: confirmListener, metadata: optionallyAddMetadata()};

	apiService.post(apiConfirmListener, plbody);
};

export const executePostEvents = (tree, details, action) => {
	const option = utilsFunctions.gotoElement(tree, details.domevent.target.id);

	if ((option.id == action.option || !action.option) && functions.conditionsMet(tree, details, action)) {
		const apiUrl = "/api/" + window._TOKEN_ + "/post-events";
		const evBody = {ev: action.value};

		evBody.metadata = optionallyAddMetadata();
		apiService.post(apiUrl, evBody);
	}
};

export const executePostConfirm = (tree, details, action, _appState) => {
	const confirm = utilsFunctions[action.name](tree, details, action, _appState.actualRoute);
	const apiConfirm = "/api/" + window._TOKEN_ + "/confirm";
	const pcbody = {data: confirm, metadata: optionallyAddMetadata()};
	const promPostConfirm = apiService.post(apiConfirm, pcbody);

	promPostConfirm.then((postConfirmResult) => {
		if (postConfirmResult && postConfirmResult.data) {
			if (!postConfirmResult.data.isValid && postConfirmResult.data.errors && postConfirmResult.data.errors.length > 0) {
				const firstError = postConfirmResult.data.errors[0];
				const stepToGo = firstError.rules[0].step;
				const apiUrl = "/api/" + window._TOKEN_ + "/post-events";
				const evBody = {ev: "last_step_visited", metadata: optionallyAddMetadata({ev_value: stepToGo, type: "on-load-variable"})};

				apiService.post(apiUrl, evBody).finally(() => {
					_appState.history.push("/" + window._TOKEN_ + "/" + stepToGo);
					// appDispatcher({ type: 'UPDATE_STATE', payload: { actualRoute: firstError.step } });
				});
			}
		}
	});
};

export const analyticsActionHandler = (tree, details, action) => {
	const option = utilsFunctions.gotoElement(tree, details.domevent.target.id);

	if ((option.id == action.option || !action.option) && functions.conditionsMet(tree, details, action)) {
		executeAnalyticsAction(details.action && details.action.settings);
	}
};

const App = (props) => {
	const initialActionsNotAutoTrigger = ["setValue", "postValue", "route", "postConfirm", "postListener", "copyValue"];

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalMessage, setModalMessage] = useState("");
	const [routing, setRouting] = useState([]);
	const [fullRouteMounted, setFullRouteMounted] = useState(false);
	const [supportLockInterval, setSupportLockInterval] = useState(null);

	const {appState, appDispatcher} = useContext(AppContext);

	const composeCaptchaRoute = (globalStyle, configValues) => {
		const captchaRoute = <SecureRoute
			key={"captcha"}
			exact path={"/" + window._TOKEN_ + "/home"}
			appState={appState}
			appDispatcher={appDispatcher}
			component={() => {
				return <ChallengeCaptcha
					globalStyle={globalStyle}
					executeCallData={executeCallData}
					configValues={configValues}
				/>;
			}}
		/>;

		return captchaRoute;
	};

	const addCaptchaToRoute = (globalStyle, configValues) => {
		const captchaRoute = composeCaptchaRoute(globalStyle, configValues);
		const redirect = <Redirect key="captchaToRoute" from={"/" + window._TOKEN_} to={"/" + window._TOKEN_ + "/home"} />;

		setRouting([...routing, captchaRoute, redirect]);
	};

	const executeCallData = (value, data, _history) => {
		appDispatcher({type: "UPDATE_STATE", payload: {challengeId: data != null ? data.challengeId : "", recaptchaValue: value, captchaDone: true, history: _history}});
	};

	const optionallyActivateSupportCheck = (stateUpd, configValues) => {
		if (configValues.templateOptions && configValues.templateOptions.operatorSupportActive === true &&
            !isOperatorSupportUser(window._SUPPORT_OG_PARAM)) {
			const createdSupportLockInterval = setInterval(() => {
				checkSupportLock(stateUpd);
			}, 3000);

			setSupportLockInterval(createdSupportLockInterval);
			checkSupportLock(stateUpd);
		}
	};

	const addCustomStyle = (companyCode, templateName) => {
		if (!companyCode || !templateName) {
			return;
		}

		// create custom css
		const cssId = "customCss"; // you could encode the css path itself to generate id..

		if (!document.getElementById(cssId)) {
			const head = document.getElementsByTagName("head")[0];
			const link = document.createElement("link");

			link.id = cssId;
			link.rel = "stylesheet";
			link.type = "text/css";
			link.href = process.env.REACT_APP_BACKEND_BASEURL + "/api/" + window._TOKEN_ + "/custom-css/" + companyCode + "/" + templateName;
			link.media = "all";
			head.appendChild(link);
		}
	};

	const callData = (value, _props, data) => {
		let tokenUrl = "/api/" + window._TOKEN_ + "?recaptcha=" + value + "&challengeId=" + (data != null ? data.challengeId : "");

		if (appState.configValues.templateOptions.skip_captcha) {
			tokenUrl += "&templateSkipCaptcha=" + appState.configValues.templateOptions.skip_captcha;
		}

		apiService.get(tokenUrl)
			.then((res) => {
				const stateUpd = {};

				stateUpd.tk_download = res.data.auth_token;

				if (appState.firstFlag) {
					stateUpd.data = res.data;
					stateUpd.firstFlag = false;
				}

				if (res.data.auth_token) {
					sessionStorage.setItem("Vitr-Session", res.data.auth_token);
				}

				res.data = applyListeners(res.data);

				stateUpd.data.land_on_last_step = res.data.land_on_last_step;
				stateUpd.data.last_step_visited = res.data.last_step_visited;
				stateUpd.data.force_step_name = res.data.force_step_name;
				stateUpd.data.exludeStepsFromLastStepLog = res.data.exludeStepsFromLastStepLog;

				optionallyActivateSupportCheck(stateUpd, appState.configValues);

				stateUpd.backandDataLoaded = true;
				appDispatcher({type: "UPDATE_STATE", payload: stateUpd});
			})
			.catch((err) => {
				const idNextPage = 0;

				const backendErrorMsg = err && err.response && err.response.data && err.response.data.error;
				const showBackendErrorMsg = err && err.response && err.response.data && err.response.data.showToFrontend;

				const _LOADING_ERROR_ = appState._LOADING_ERROR_;

				if (showBackendErrorMsg &&
                    backendErrorMsg &&
                    _LOADING_ERROR_.steps[0].widgets &&
                    _LOADING_ERROR_.steps[0].widgets.length &&
                    _LOADING_ERROR_.steps[0].widgets[0].widgets &&
                    _LOADING_ERROR_.steps[0].widgets[0].widgets.length) {
					_LOADING_ERROR_.steps[0].widgets[0].widgets[0].value = backendErrorMsg;
				}

				const singleRoute = composeSingleRoute(_LOADING_ERROR_.steps[0]);
				const component = () => singleRoute;

				setRouting(<SecureRoute
					key={_LOADING_ERROR_.steps[0].id}
					exact path={"/" + window._TOKEN_ + "/" + idNextPage}
					component={component}
				/>);

				appState.history.push("/" + window._TOKEN_ + "/" + idNextPage);
				appDispatcher({type: "UPDATE_STATE", payload: {data: _LOADING_ERROR_, firstFlag: false, actualRoute: idNextPage}});

				return;
			})
			.finally(() => {
				setBodyPointerEvent("auto");
			});
	};

	useEffect(() => {
		if (!appState.backandDataLoaded) {
			return;
		}

		const tmpRouting = [];

		appState.data.steps.forEach((step, stepIndex) => {
			const component = () => composeSingleRoute(appState.data.steps[stepIndex]);
			const newRoute = <SecureRoute
				key={step.id}
				exact path={"/" + window._TOKEN_ + "/" + step.id}
				appState={appState}
				appDispatcher={appDispatcher}
				component={component}
				hasRouteGuard={true}
				redirectToPathWhenFail={"/" + window._TOKEN_}
			/>;

			tmpRouting.push(newRoute);
		});

		const redirect1 = <Redirect key="r1" from={"/" + window._TOKEN_} to={"/" + window._TOKEN_ + "/0"} />;
		const redirect2 = <Redirect key="r2" from={"/" + window._TOKEN_ + "/home"} to={"/" + window._TOKEN_ + "/0"} />;

		tmpRouting.push(redirect1, redirect2);

		setRouting(tmpRouting);
		setFullRouteMounted(true);
	}, [appState.backandDataLoaded]);

	useEffect(() => {
		if (appState.forceRedirect === 0) {
			return;
		}

		navigateIfNeeded(null, true, appState.actualRoute);
	},
	[appState.forceRedirect]);

	useEffect(() => {
		if (!fullRouteMounted) {
			return;
		}

		let n = 0;

		if (appState.data.land_on_last_step) {
			n = parseInt(appState.data.last_step_visited || 0);

			const _force_step_name = appState.data.force_step_name;

			if (_force_step_name) {
				const _force_step_id_by_name = getTargetValueByName(_force_step_name, "id");

				if (_force_step_id_by_name) {
					n = parseInt(_force_step_id_by_name || 0);
				}
			}
		} else {
			if (window._STEP_ && window._STEP_.indexOf("home") >= 0) {
				window._STEP_ = 0;
			}

			n = window._STEP_ ? window._STEP_ : 0;
			n = parseInt(n);
		}

		for (let i = 0; i < appState.data.steps.length; i++) {
			if (typeof appState.data.steps[n] !== "undefined" && typeof appState.data.steps[n].disabled !== "undefined" && appState.data.steps[n].disabled) {
				const nextDefaultStep = getNextStepIfDisabled(appState.data.steps, n);

				if (nextDefaultStep !== undefined) {
					n = nextDefaultStep;
				} else {
					n = n + 1;

					if (n >= appState.data.steps.length) {
						n = 0;
					}
				}
			} else {
				break;
			}
		}

		updateLastVisitedPage(n).finally(() => {
			for (let k = 0; k < appState.data.steps.length; k++) {
				mountStepListeners(appState.data.steps[k]);
			}

			appState.history.push("/" + window._TOKEN_ + "/" + n);
			appDispatcher({type: "UPDATE_STATE", payload: {actualRoute: n}});

			// Listen to route change
			if (!appState.historyListened) {
				addPathToAppHistory(appState.history && appState.history.location && appState.history.location.pathname);
				appDispatcher({type: "UPDATE_STATE", payload: {historyListened: true}});
				appState.history.listen(() => {
					appDispatcher({type: "UPDATE_STATE", payload: {routeNavigated: true}});

					if (history && appState.history.location && appState.history.location.pathname) {
						const pathname = appState.history.location.pathname;
						const routeId = GetRouteFromPath(pathname);

						if (routeId && routeId !== "home") {
							updateLastVisitedPage(routeId);
						}

						// Update app history
						addPathToAppHistory(pathname);
					}
				});
			}
		});
	}, [fullRouteMounted]);

	const checkSupportLock = (stateUpd) => {
		apiService.get("/api/" + window._TOKEN_ + "/is-support-locked")
			.then((res) => {
				/* If communication was locked and now is unlocked, then refresh the page */
				const lockResult = res && res.data && res.data.locked === true;

				if (appState.communicationSupportLocked === true && !lockResult) {
					// refresh page
					appDispatcher({type: "UPDATE_STATE", payload: {communicationSupportMessage: "App refresh"}});
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					appDispatcher({type: "UPDATE_STATE", payload: {communicationSupportLocked: (res && res.data && res.data.locked === true)}});
				}

				/* If the communication is in state "force step" then rout it there */
				const force_step_name = res && res.data && res.data.force_step_name;

				if (force_step_name) {
					const _force_step_id_by_name = functions.getTargetValueByName(stateUpd.data, force_step_name, "id");

					if (_force_step_id_by_name) {
						const idNextPage = parseInt(_force_step_id_by_name || 0);

						appState.history.push("/" + window._TOKEN_ + "/" + idNextPage);
						appDispatcher({type: "UPDATE_STATE", payload: {actualRoute: idNextPage}});
					}
				}
			}).catch((e) => {
				console.log("api call to check support lock, produced an error");
			});
	};

	const applyListeners = (resData) => {
		const listeners = resData.listeners;

		if (!listeners || !listeners.length) {
			return resData;
		}

		;
		listeners.forEach((listener) => {
			const widgetId = listener.widget_id;
			let flatValue = listener.value;

			if (listener.value instanceof Array) {
				flatValue = (listener.value.length > 0) ? listener.value[0] : undefined;
			}

			if (!flatValue) {
				return;
			}

			const eventName = flatValue;

			// get event for that listener
			const element = utilsFunctions.gotoElement(resData, widgetId);

			if (!element || !element.events) {
				return;
			}

			;

			const eventData = element.events.find((ev) => {
				return (ev && ev.listener === eventName);
			});

			if (!eventData) {
				return;
			}

			// apply actions of the found event
			eventData.actions.forEach((action) => {
				// is action existing?
				if (!functions[action.name] || initialActionsNotAutoTrigger.indexOf(action.name) >= 0) {
					return;
				}

				const json = {id: widgetId, domevent: {target: {value: null, id: widgetId}}};

				resData = functions[action.name](resData, json, action);
			});
		});

		return resData;
	};

	const addPathToAppHistory = (pathname) => {
		const _h = [...appState.appHistory];

		_h.unshift(pathname);
		appDispatcher({type: "UPDATE_STATE", payload: {appHistory: _h}});
	};

	const handleGoBack = (props) => {
		executeGoBack(appState, appDispatcher);
	};

	const activateAnalytics = (config) => {
		if (config && config.templateOptions) {
			if (config.templateOptions.adobeAnalyticsEnabled) {
				window.activateAdobeAnalytics(config.env_name, config.templateOptions.analyticsDomainUrl);
			}

			window.activateHotjar(config.hjid);
		}
	};

	const setStyle = () => {
		appDispatcher({type: "UPDATE_STATE", payload: {loading: true}});

		const promises = [apiService.get("/api/" + window._TOKEN_ + "/style"), apiService.get("/api/" + window._TOKEN_ + "/config")];

		Promise.all(promises).then(
			(results) => {
				if (!results) {
					// eslint-disable-next-line
            throw {response: {}};
				}

				const globalStyle = results[0].data && results[0].data["classStyle"];
				const templateName = results[0].data && results[0].data["template_name"];
				const companyCode = results[0].data && results[0].data["company_code"];
				const configValues = results[1].data;

				activateAnalytics(configValues);
				addCustomStyle(companyCode, templateName);
				setRouting([]);
				addCaptchaToRoute(globalStyle, configValues);

				const setStateBody = {globalStyle: globalStyle, loading: false, theme: globalStyle, configValues: configValues};

				if (typeof images[globalStyle] !== "undefined") {
					setStateBody.images = images[globalStyle];
				}

				appDispatcher({type: "UPDATE_STATE", payload: setStateBody});

				// Add global style if any and if not already added
				addGlobalStyleToBody(globalStyle);
			},
		)
			.catch((e) => {
				manageStyleError(e, appDispatcher);
			});
	};

	useEffect(() => {
		if (!appState.globalStyle) {
			setStyle();
		}

		setBodyPointerEvent("none");

		// returned function will be called on component unmount
		return () => {
			if (routing.length && appState.data && typeof appState.data.steps !== "undefined" && appState.data.steps) {
				appState.data.steps.forEach((step) => unmountStepListeners(step));
			}

			supportLockInterval && clearInterval(supportLockInterval);
		};
	}, []);

	useEffect(() => {
		if (appState.captchaDone) {
			callData(appState.recaptchaValue, appState.challengeId);
		}
	}, [appState.captchaDone]);

	useEffect(() => {
		const handleAnchorClick = (event) => {
			event.preventDefault();

			const anchor = event.currentTarget;
			let newHref = anchor.href;

			if (anchor.href.startsWith(`${window.location.origin}/api/`)) {
				newHref = anchor.href.replace(`${window.location.origin}/api/`, `${process.env.REACT_APP_BACKEND_BASEURL}/api/`);
			}

			window.location.href = newHref;
		};
		const handleImageError = (event) => {
			const img = event.currentTarget;

			if (img.src.startsWith(`${window.location.origin}/api/`)) {
				img.src = img.src.replace(`${window.location.origin}/api/`, `${process.env.REACT_APP_BACKEND_BASEURL}/api/`);
			}
		};
		const imageTags = document.querySelectorAll("img");

		imageTags.forEach((image) => {
			image.addEventListener("error", handleImageError);
		});

		const anchorTags = document.querySelectorAll("a");

		anchorTags.forEach((anchor) => {
			anchor.addEventListener("click", handleAnchorClick);
		});
	});

	const appendValue = (json, action) => {
		appDispatcher({type: "UPDATE_STATE", paload: {data: functions.setValue(appState.data, json, action)}});
	};

	const appendValues = (json, action) => {
		appDispatcher({type: "UPDATE_STATE", payload: {data: functions.appendValues(appState.data, json, action)}});
	};

	const removeValues = (json, action) => {
		appDispatcher({type: "UPDATE_STATE", payload: {data: functions.removeValues(appState.data, json, action)}});
	};

	const computeStaticConditions = (data) => {
		appDispatcher({type: "UPDATE_STATE", payload: {data: functions.computeStaticConditions(data)}});
	};

	const getTargetValue = (id, valueAttributePropertyName) => {
		return functions.getTargetValue(appState.data, id, valueAttributePropertyName);
	};

	const getTargetValueByName = (name, valueAttributePropertyName) => {
		return functions.getTargetValueByName(appState.data, name, valueAttributePropertyName);
	};

	const gotoElement = (propValue, propName) => {
		return utilsFunctions.gotoElement(appState.data, propValue, propName);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	const assignEvents = (domevent, event, props, isFirstInitialization = null, appStateParam) => {
		const _appState = appStateParam || appState;

		let tree = JSON.parse(JSON.stringify(_appState.data));
		let needsToRoute = false;
		let routeToGo;
		const postPromises = [];

		event.actions.forEach((action) => {
			// If is the initialization phase variable is not specified, then assign all types of events
			if (isFirstInitialization !== null) {
				// If it is the initialization phase, then consider only events that change page display
				if (isFirstInitialization && initialActionsNotAutoTrigger.indexOf(action.name) >= 0) {
					return;
				} else if (!isFirstInitialization && initialActionsNotAutoTrigger.indexOf(action.name) < 0) {
					return;
				}
			}

			const id = props.id;
			const details = {domevent, action, id};

			switch (action.name) {
			case "route":
				let n = action.redirect;
				const lastStep = tree.steps.length;
				let searchRoute = true;

				while (searchRoute) {
					if ((n < 0) || (n > lastStep)) {
						searchRoute = false;
					} else {
						const disabled = tree.steps[n].disabled;

						if (disabled) {
							const nextDefaultStep = _appState.actualRoute > action.redirect ? getPreviousStepIfDisabled(tree.steps, n) : getNextStepIfDisabled(tree.steps, n);

							if (nextDefaultStep !== undefined) {
								n = nextDefaultStep;
							} else {
								n = _appState.actualRoute > action.redirect ? (n - 1) : (n + 1);
							}
						} else {
							needsToRoute = true;
							routeToGo = n;
							appDispatcher({type: "UPDATE_STATE", payload: {actualRoute: n}});

							return;
						}
					}
				}

				break;
			case "routeIfValid":

				const promises = [];

				action.target.forEach((el) => {
					const prom = functions["validate"](tree, {id: el, showMessages: true, doNotResolve: true}, {target: el});

					promises.push(prom);
				});

				const proms = Promise.all(promises);

				postPromises.push(proms);
				proms.then((res) => {
					let n = action.redirect;
					const lastStep = tree.steps.length;
					let searchRoute = true;

					while (searchRoute) {
						if ((n < 0) || (n > lastStep)) {
							searchRoute = false;
						} else {
							const disabled = tree.steps[n].disabled;

							if (disabled) {
								const nextDefaultStep = _appState.actualRoute > action.redirect ? getPreviousStepIfDisabled(tree.steps, n) : getNextStepIfDisabled(tree.steps, n);

								if (nextDefaultStep !== undefined) {
									n = nextDefaultStep;
								} else {
									n = _appState.actualRoute > action.redirect ? (n - 1) : (n + 1);
								}
							} else {
								needsToRoute = true;
								routeToGo = n;
								appDispatcher({type: "UPDATE_STATE", payload: {actualRoute: n}});

								return;
							}
						}
					}
				})
					.catch((err) => {
						appDispatcher({type: "UPDATE_STATE", payload: {isLoading: false}});
					});

				break;
			case "postValue":
				const value = utilsFunctions[action.name](tree, details, action);
				const apiValue = "/api/" + window._TOKEN_;
				const body = {data: value, metadata: optionallyAddMetadata()};

				postPromises.push(apiService.post(apiValue, body));

				if (!action.settings || !action.settings.nonBlocking) {
					appDispatcher({type: "UPDATE_STATE", payload: {isLoading: true}});
				}

				break;
			case "remoteInteraction":
				const promise = new Promise((resolve, reject) => {
					const operationOut = functions.urlReplacer(tree, action);

					apiService.get("/api/" + window._TOKEN_ + operationOut).then((res) => {
						const tmp = functions[action.name](tree, res.data[0], action, action.mapper);

						if (tmp) {
							tree = tmp;
						}

						appDispatcher({type: "UPDATE_STATE", payload: {data: functions.computeStaticConditions(tree)}});
						resolve({stateUpdated: true, tree, computeStaticConditions: action.settings && action.settings.computeStaticConditions});
						// appDispatcher({ type: 'UPDATE_STATE', payload: { isLoading: true } });
					}).catch((err) => {
						reject(err);
					});
				});

				postPromises.push(promise);
				break;
			case "postEvents":
				executePostEvents(tree, details, action);
				break;
			case "postEventsAndRouteIsSuccess":
				const postEventPromise = new Promise((resolve, reject) => {
					appDispatcher({type: "UPDATE_STATE", payload: {isLoading: true}});

					const apiUrl = "/api/" + window._TOKEN_ + "/post-events";
					const evBody = {ev: action.postEventValue};

					evBody.metadata = optionallyAddMetadata();
					apiService.post(apiUrl, evBody).then((res) => {
						needsToRoute = true;
						routeToGo = action.successRoute;
						resolve({stateUpdated: true, tree: {...tree, actualRoute: n, isLoading: false}});
					}).catch((err) => {
						resolve({stateUpdated: true, tree: {...tree, isLoading: false}});
						setModalIsOpen(true);
						setModalMessage("Si è verificato un errore nell'operazione corrente. Si prega di riprovare o di contattare il servizio clienti, se il problema persiste.");
					});
				});

				postPromises.push(postEventPromise);
				break;
			case "analyticsAction":
				analyticsActionHandler(tree, details, action);
				break;
			case "postConfirm":
				executePostConfirm(tree, details, action, _appState);
				break;
			case "postListener":
				executePostListener(tree, details, action);
				break;
			case "storeAndValidateDataOnDemand":
				// Validate every widget - onClick event
				const validationPromises = action.targets.map((el) => {
					return functions["validate"](tree, {id: el, showMessages: true, doNotResolve: true}, {targets: el});
				});

				const validationProms = Promise.all(validationPromises);

				postPromises.push(validationProms);

				validationProms.then((res) => {
					const goToStep = action.redirect;
					const lastStep = tree.steps.length;
					let searchRoute = true;

					while (searchRoute) {
						if (goToStep < 0 || goToStep > lastStep) {
							searchRoute = false;
						} else {
							needsToRoute = true;
							routeToGo = goToStep;

							const payloadData = utilsFunctions[action.name](tree, details, action);
							const apiRoutePath = "/api/" + window._TOKEN_;
							const payload = {data: payloadData, metadata: optionallyAddMetadata()};

							postPromises.push(apiService.post(apiRoutePath, payload));

							// Iterate though every element and update it in the tree
							payloadData.forEach((widget) => {
								const target = utilsFunctions.gotoElement(tree, widget.widget_id);

								target.readOnlyValues = [...target.value];
							});

							if (!action.settings || !action.settings.nonBlocking) {
								appDispatcher({type: "UPDATE_STATE", payload: {isLoading: true, actualRoute: goToStep, data: tree}});
							}

							return;
						}
					}
				}).catch((err) => {
					appDispatcher({type: "UPDATE_STATE", payload: {isLoading: false}});
				});
				break;
			default:
				details.showMessages = typeof domevent.showMessages != "undefined" ? domevent.showMessages : true;

				if (action.name === "validate" || action.name === "validateStep") {
					const func = functions[action.name](tree, details, action);

					postPromises.push(func);
					func.then((tmp) => {
						if (tmp) {
							tree = Object.assign(tree, tmp);
						}

						const redirectTarget = GetRedirectTarget(window.location.href);
						const evt = new CustomEvent(`${event.listener}-${redirectTarget}`, {bubbles: true, detail: {details, tree}});

						document.dispatchEvent(evt);
						appDispatcher({type: "UPDATE_STATE", payload: {isLoading: false}});
					});
				} else if (action.name === "validateStep") {
					const func = functions[action.name](tree, details, action);

					postPromises.push(func);
					func.then((tmp) => {
						if (tmp) {
							tree = Object.assign(tree, tmp);
						}

						const redirectTarget = GetRedirectTarget(window.location.href);
						const evt = new CustomEvent(`${event.listener}-${redirectTarget}`, {bubbles: true, detail: {details, tree}});

						document.dispatchEvent(evt);
						appDispatcher({type: "UPDATE_STATE", payload: {isLoading: false}});
					});
				} else {
					const tmp = functions[action.name](tree, details, action);

					if (tmp) {
						tree = Object.assign(tree, tmp);
					}
				}

				break;
			}
		});
		appDispatcher({type: "UPDATE_STATE", payload: {data: Object.assign(_appState.data, tree)}});

		// if safely promise backend interactions is enabled, then wait for
		// the promise to succesfully resolve before navigate
		if (_appState.data && _appState.data.safelyPromiseBackendInteraction) {
			Promise.all(postPromises).then((res) => {
				res && res.length && res.forEach((r) => {
					if (r.stateUpdated) {
						let payloadData = {data: Object.assign(_appState.data, r.tree)};

						if (r.computeStaticConditions) {
							payloadData = functions.computeStaticConditions(payloadData);
						}

						appDispatcher({type: "UPDATE_STATE", payload: payloadData});
					}
				});

				if (_appState.isLoading) {
					setTimeout(() => {
						appDispatcher({type: "UPDATE_STATE", payload: {isLoading: false}});
						navigateIfNeeded(props, needsToRoute, routeToGo, _appState);
					}, 400);
				} else {
					appDispatcher({type: "UPDATE_STATE", payload: {isLoading: false}});
					navigateIfNeeded(props, needsToRoute, routeToGo, _appState);
				}
			}).catch((err) => {
				console.error(err);
			});
		} else {
			appDispatcher({type: "UPDATE_STATE", payload: {isLoading: false}});
			navigateIfNeeded(props, needsToRoute, routeToGo, _appState);
		}
	};

	const navigateIfNeeded = (props, needsToRoute, routeToGo, appStateParam) => {
		navigateIfNeededHandler(props, needsToRoute, routeToGo, appStateParam, appState, appDispatcher);
	};

	const mountStepListeners = (step) => {
		if (step.events) {
			step.events.forEach((event) => {
				document.addEventListener(`${event.listener}-${step.id}`, (domevent) => {
					let data = domevent.detail.tree;

					event.actions.forEach((action) => {
						if (typeof action.where !== "undefined" && typeof functions[action.where] !== "undefined" && typeof functions[action.name] !== "undefined") {
							const currentStep = data.steps.filter((ste) => step.id === ste.id)[0];
							const whereCondition = functions[action.where](data, currentStep, action);

							if (whereCondition) {
								const tmp = functions[action.name](data, action);

								if (tmp) data = tmp;
							}
						} else if (typeof functions[action.name] !== "undefined") {
							const tmp = functions[action.name](data, domevent.detail.details, action);

							if (tmp) data = tmp;
						}
					});

					const _as = typeof appState !== "undefined" ? appState.data : {};

					appDispatcher({type: "UPDATE_STATE", payload: {data: Object.assign(_as, data)}});
				});
			});
		}
	};

	const updateLastVisitedPage = (idNextPage) => {
		if ((appState.data && !appState.data.land_on_last_step) ||
            (appState.data.exludeStepsFromLastStepLog && appState.data.exludeStepsFromLastStepLog.length &&
                appState.data.exludeStepsFromLastStepLog.indexOf(`${idNextPage}`) >= 0)
		) {
			return Promise.resolve();
		}

		// execute post event to update last step
		const apiUrl = "/api/" + window._TOKEN_ + "/post-events";
		const evBody = {ev: "last_step_visited", metadata: optionallyAddMetadata({ev_value: idNextPage, type: "on-load-variable"})};

		return apiService.post(apiUrl, evBody);
	};

	const triggerCheckScrolldown = (props) => {
		if (appState.data && appState.data.options && appState.data.options.showScroll) {
			checkScrollDown(appDispatcher);
		}
	};

	const composeSingleRoute = (step) => {
		const page = [];

		step.widgets.forEach((wid) => page.push(composeWidget(wid)));

		const footer = <Footer globalStyle={appState.globalStyle} data={appState.data} />;
		let scrolldown = null;

		if (appState.data && appState.data.options && appState.data.options.showScroll) {
			scrolldown = <Scrolldown globalStyle={appState.globalStyle} triggerCheckScrolldown={triggerCheckScrolldown} />;
		}

		return (<Page page={page} footer={footer} scrolldown={scrolldown}
			assignEvents={assignEvents} {...step} />);
	};

	const composeWidget = (w) => {
		w.classes = w.classes || "";

		if (w.visible !== false) {
			switch (w.type) {
			case ("hrms-card"):
				return <Card
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget} propagatedAppState={appState}
					assignEvents={assignEvents}
				/>;
			case ("hrms-title"):
			case ("hrms-subtitle"):
			case ("hrms-section"):
				return <Text
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget} configValues={appState.configValues}
				/>;
			case ("hrms-link"):
				return <Link
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
				/>;
			case ("hrms-radio"):
				return <Radio
					appendValue={appendValue} data={appState.data}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget} propagatedAppState={appState}
					computeStaticConditions={computeStaticConditions} assignEvents={assignEvents} />;
			case ("hrms-checkbox"):
				return <Checkbox
					appendValue={appendValue} data={appState.data}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget} propagatedAppState={appState}
					computeStaticConditions={computeStaticConditions} assignEvents={assignEvents} />;
			case ("hrms-btn"):
				return <Btn
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					tk_download={appState.tk_download}
					key={w.id} {...w} composeWidget={composeWidget} propagatedAppState={appState}
					assignEvents={assignEvents} computeStaticConditions={computeStaticConditions} />;
			case ("hrms-autocomplete"):
				return <Autocomplete
					appendValue={appendValue} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					assignEvents={assignEvents}
				/>;
			case ("hrms-dropdown"):
				return <Dropdown
					appendValue={appendValue} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					assignEvents={assignEvents}
				/>;
			case ("hrms-popup"):
				return <Popup
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
				/>;
			case ("hrms-input"):
				return <Input
					appendValue={appendValue}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					assignEvents={assignEvents} configValues={appState.configValues}
				/>;
			case ("hrms-textarea"):
				return <Textarea
					appendValue={appendValue}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					assignEvents={assignEvents}
				/>;
			case ("hrms-incremental-input"):
				return <IncrementalInput
					appendValue={appendValue}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					assignEvents={assignEvents}
				/>;
			case ("hrms-uploader"):
				return <Uploader
					appendValues={appendValues} removeValues={removeValues}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					data={appState.data} propagatedAppState={appState} addEvents={addEvents}
					assignEvents={assignEvents} />;
			case ("hrms-map"):
				return <MapAddressFinder
					appendValues={appendValues} appendValue={appendValue} removeValues={removeValues}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					data={appState.data} propagatedAppState={appState}
					configValues={appState.configValues} />;
			case ("hrms-grid-image-uploader"):
				return <GridImageUploader
					appendValues={appendValues} removeValues={removeValues}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					data={appState.data} propagatedAppState={appState}
					getTargetValue={getTargetValue} getTargetValueByName={getTargetValueByName}
					gotoElement={gotoElement}
					assignEvents={assignEvents} addEvents={addEvents} />;
				break;
			case ("hrms-grid-image-uploader-ip"):
				return <GridImageUploaderIP
					appendValue={appendValue}
					appendValues={appendValues} removeValues={removeValues}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					data={appState.data} propagatedAppState={appState}
					getTargetValue={getTargetValue} getTargetValueByName={getTargetValueByName}
					gotoElement={gotoElement}
					assignEvents={assignEvents} addEvents={addEvents} />;
				break;
			case ("hrms-grid-image-uploader-urto"):
				return <GridImageUploaderUrto
					appendValue={appendValue}
					appendValues={appendValues} removeValues={removeValues}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					data={appState.data} propagatedAppState={appState}
					getTargetValue={getTargetValue} getTargetValueByName={getTargetValueByName}
					gotoElement={gotoElement}
					assignEvents={assignEvents} addEvents={addEvents} />;
			case ("hrms-image"):
				return <Image
					appendValues={appendValues} removeValues={removeValues}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					data={appState.data} assignEvents={assignEvents} />;
			case ("hrms-icon"):
				return <Icon
					appendValues={appendValues} removeValues={removeValues}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					data={appState.data} assignEvents={assignEvents} />;
			case ("hrms-sms-preview"):
				return <SmsPreview
					appendValues={appendValues} removeValues={removeValues}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					data={appState.data} assignEvents={assignEvents} />;
			case ("hrms-video"):
				return <VideoPlayer
					appendValue={appendValue} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					assignEvents={assignEvents} addEvents={addEvents}
				/>;
				break;
			case ("hrms-share-btn"):
				return <ShareBtn
					appendValue={appendValue}
					triggerCheckScrolldown={triggerCheckScrolldown} globalStyle={appState.globalStyle}
					key={w.id} {...w} composeWidget={composeWidget}
					assignEvents={assignEvents} configValues={appState.configValues}
					closeModal={closeModal} setModalIsOpen={setModalIsOpen}
					setModalMessage={setModalMessage}
				/>;
				break;
			}
		}

		;
	};

	const addGlobalStyleToBody = (globalStyle) => {
		const body = document.getElementsByTagName("body")[0];

		if (!body.classList.contains(globalStyle)) {
			body.classList.add(globalStyle);
		}
	};

	const setBodyPointerEvent = (pointerEventValue) => {
		const body = document.getElementsByTagName("body")[0];

		body.style.pointerEvents = pointerEventValue;
	};

	const {data, globalStyle, actualRoute, configValues} = appState;

	return (
		globalStyle ?
			<Pwa data={data} configValues={configValues}>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
				>
					<div className="default-modal">
						<div className="modal-header">
							<button className="button close-modal-btn" onClick={closeModal}><i className="fas fa-times"></i></button>
						</div>
						<div className="modal-content">
							<p>{modalMessage}</p>
						</div>
					</div>
				</Modal>
				<Layout
					data={data}
					actualRoute={actualRoute}
					goBack={handleGoBack}
					routes={routing}
				/>
			</Pwa> : <ErrorMessage message={appState.errorMessage} />
	);
};

export default App;
