import React, {Component} from "react";
import numbro from "numbro";

class Textarea extends Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
	};

	componentDidMount() {
		// Trigger actions (if any) on load
		const p = this.props;
		let _value = (p.value instanceof Array && p.value.length > 0) ? p.value[0] : p.value;

		if (p && p.settings && p.settings.formatSettings && p.settings.formatMethod) {
			_value = numbro.unformat(_value);
		}

		const _e = {target: {value: _value, id: p.id}};

		this.executeChangeListeners(_e, true, false);

		const mountedEvent = p.events && p.events.find((ev) => {
			return (ev.listener === "mount");
		});

		if (mountedEvent) {
			const domevent = {target: {value: "", id: p.id}, showMessages: false};

			this.props.assignEvents(domevent, mountedEvent, p);
		}
	}

	onChange(e) {
		const action = {target: null, value: null};
		let _value = e.target.value;

		if (this.props.settings && this.props.settings.formatSettings && this.props.settings.formatMethod) {
			_value = numbro.unformat(_value);
		}

		const json = {id: this.props.id, domevent: {target: {value: [_value]}}};

		this.props.appendValue(json, action);
		this.executeChangeListeners(e, null, true);
	}

	executeChangeListeners(e, isFirstInitialization, showMessages) {
		const p = this.props;
		const changeEvent = p.events && p.events.find((ev) => {
			return (ev.listener === "change");
		});

		if (changeEvent) {
			let _value = e.target.value;

			if (p && p.settings && p.settings.formatSettings && p.settings.formatMethod) {
				_value = numbro.unformat(_value);
			}

			const domevent = {target: {value: [_value], id: e.target.id}, showMessages: showMessages};

			this.props.assignEvents(domevent, changeEvent, p, isFirstInitialization);
		}
	}

	render() {
		const p = this.props;
		let _type = (p.settings && p.settings.type) ? p.settings.type : "text";
		const nativeValidation = typeof p.nativeValidation != "undefined" ? true : false;
		const rawValue = typeof this.props.value[0] !== "undefined" ? this.props.value[0] : "";
		let _value = rawValue;

		if (p && p.settings && p.settings.formatSettings && p.settings.formatMethod && rawValue !== "") {
			_value = numbro(rawValue)[p.settings.formatMethod](p.settings.formatSettings);
			_type = "text";
		}

		let Input = <textarea id={p.id} name={p.id} type={_type} placeholder={p.placeholder}
			onChange={this.onChange}
			className={p.type + " " + p.classes}
			value={_value}
		/>;

		const errors = this.props.validation && this.props.validation.result && Object.keys(this.props.validation.result).map((errorKey) => {
			if (!this.props.validation.result[errorKey].isValid && this.props.validation.result[errorKey].message) {
				return (<li key={errorKey}>{this.props.validation.result[errorKey].message}</li>);
			}
		});

		if (nativeValidation) {
			Input = <textarea id={p.id} name={p.id} type={_type} placeholder={p.placeholder}
				{...p.nativeValidation}
				onChange={this.onChange}
				className={p.type + " " + p.classes}
				value={_value}
			/>;
		}

		const errorClass = errors && errors.find((el) => el !== undefined) ? "error-inside-textarea" : "";

		return (
			<>
				<div className={p.type + " " + p.classes + " " + errorClass} style={p.style}>
					<div className="up-label-errors">
						<ul>
							{errors}
						</ul>
					</div>
					{p.label && (<label htmlFor={p.id}>{p.label}</label>)}
					{Input}
				</div>

			</>
		);
	};
}

;

export default Textarea;
