const conversionFunctionsName = ["localTimeHour", "localTimeDate", "caiExpireLocalTimeDate", "caiSignatureFeedbackExpireDate"];

import moment from "moment";

const getUtcOffset = (date, offset) => {
	return moment(date)
		.add(
			offset,
			"minutes")
		.utc();
};

const toUtcOffset = (textIn, format) => {
	let textOut = textIn;

	try {
		// Date with textInOur with respect to the format
		const timestamp = moment.utc(textIn, format);
		const inverseOffset = moment().utcOffset() * -1;
		const utcTime = getUtcOffset(timestamp, inverseOffset);

		if (utcTime.isValid()) {
			textOut = utcTime.format(format);
		}
	} catch (err) {
		textOut = textIn;
	}

	return textOut;
};


const localTimeHour = (textIn, configValues) => {
	let textOut = textIn;
	const resRE = textIn.match("\\$localTimeHour\\((.*?)(\\) |\\)$)");

	const foundText = resRE && (resRE.length > 0) && resRE[1];
	let localTimeOutput = foundText;

	try {
		if (foundText) {
			// let _utc = moment.utc(foundText);
			// let localTime = _utc.local();
			const utcoffset = moment().utcOffset();
			const localTime = getUtcOffset(foundText, utcoffset);

			if (localTime.isValid()) {
				localTimeOutput = localTime.format("HH:mm");
			}
		}
	} catch (err) { }

	textOut = textIn.replace("$localTimeHour(" + foundText + ")", localTimeOutput);

	return textOut;
};

const executeLocalTimeDate = (textIn, functionName, additionalHours, setNowIfEmpty) => {
	let textOut = textIn;
	const resRE = textIn.match(`\\$${functionName}\\((.*?)(\\) |\\)$)`);

	const foundText = resRE && (resRE.length > 0) && resRE[1];
	let localTimeOutput = foundText;

	if (!localTimeOutput && setNowIfEmpty && resRE.length > 1) {
		localTimeOutput = moment().toString();
	}

	try {
		if (localTimeOutput) {
			const utcoffset = moment().utcOffset();
			const localTime = getUtcOffset(localTimeOutput, utcoffset);

			if (localTime.isValid()) {
				if (additionalHours) {
					localTimeOutput = localTime.add(additionalHours, "hours").format("DD/MM/YYYY");
				} else {
					localTimeOutput = localTime.format("DD/MM/YYYY");
				}
			}
		}
	} catch (err) { }

	textOut = textIn.replace(`$${functionName}(` + foundText + ")", localTimeOutput);

	return textOut;
};

const localTimeDate = (textIn, configValues) => {
	return executeLocalTimeDate(textIn, "localTimeDate");
};

const caiExpireLocalTimeDate = (textIn, configValues) => {
	const additionalHours = (configValues && configValues.realtime_cai_communication_expiration_in) || 48;

	return executeLocalTimeDate(textIn, "caiExpireLocalTimeDate", additionalHours);
};

const caiSignatureFeedbackExpireDate = (textIn, configValues) => {
	const additionalHours = (configValues && configValues.realtime_cai_signature_expiration_in) || 48;

	return executeLocalTimeDate(textIn, "caiSignatureFeedbackExpireDate", additionalHours, true);
};

export default {conversionFunctionsName, localTimeHour, localTimeDate, toUtcOffset, caiExpireLocalTimeDate, caiSignatureFeedbackExpireDate};
