const images = {
	"logo": require("./logo.png"),
	"pdf": require("./pdf.png"),
	"info": require("./info.png"),
	"marker": require("./marker.png"),
	"carDesignImg": require("./car-design-img.png"),
	"check-map": require("./check-map.png"),
	// "data-e-luogo": require('./data-e-luogo.png'),
	// "dati-assicurato": require('./dati-assicurato.png'),
	// "dati-veicolo": require('./dati-veicolo.png'),
	// "dati-compagnia-assicurativa": require('./dati-compagnia-assicurativa.png'),
	// "dati-punti-urto": require('./dati-punti-urto.png'),
	// "dati-circostanze": require('./dati-circostanze.png'),
	"dinamica-hilighted": require("./dinamica-hilighted.png"),
	"firme-hilighted": require("./firme-hilighted.png"),
	"ic_done_all_24px": require("./ic_done_all_24px.png"),
	"checked-icon": require("./checked-icon.png"),
	// "dinamica-incidente": require('./dinamica-incidente.png'),
	// "A-6-cai": require('./A-6-cai.png'),
	// "A-7-cai": require('./A-7-cai.png'),
	// "A-8-cai": require('./A-8-cai.png'),
	// "A-9-cai": require('./A-9-cai.png'),
	// "B-6-cai": require('./B-6-cai.png'),
	// "B-7-cai": require('./B-7-cai.png'),
	// "B-8-cai": require('./B-8-cai.png'),
	// "B-9-cai": require('./B-9-cai.png'),
	"applestore": require("./applestore.png"),
	"googleplay": require("./googleplay.png"),
	"A-tre-no-polizza": require("./A-tre-no-polizza.png"),
	"B-tre-no-polizza": require("./B-tre-no-polizza.png"),
	/*
    "A-ctp_8": require('./8.png'),
    "A-ass_8": require('./_8.png'),
    "A-id-15-dinamica-incidente": require('./_15.png'),
    "A-id-1-dinamica-incidente":  require('./_1.png'),
    "A-id-8-dinamica-incidente":  require('./_8.png'),
    "A-id-16-dinamica-incidente": require('./_16.png'),
    "A-id-17-dinamica-incidente": require('./17.png'),
    "A-id-14-dinamica-incidente": require('./_14.png'),
    "A-id-2-dinamica-incidente":  require('./_2.png'),
    "B-ctp_8": require('./_8.png'),
    "B-ass_8": require('./8.png'),
    "B-id-15-dinamica-incidente": require('./15.png'),
    "B-id-1-dinamica-incidente":  require('./1.png'),
    "B-id-8-dinamica-incidente":  require('./8.png'),
    "B-id-16-dinamica-incidente": require('./16.png'),
    "B-id-17-dinamica-incidente": require('./_17.png'),
    "B-id-14-dinamica-incidente": require('./14.png'),
    "B-id-2-dinamica-incidente":  require('./2.png'),
    "A-B-id-15-dinamica-incidente": require('./2.png'),
    "A-B-id-2-dinamica-incidente": require('./2.png'),
    "B-ctp_8": require('./_8.png'),
    //
    "A-id-1-C-dinamica-incidente":  require('./1.png'),
    "A-id-2-C-dinamica-incidente":  require('./2.png'),
    "A-id-8-C-dinamica-incidente":  require('./8.png'),
    "A-id-14-C-dinamica-incidente": require('./14.png'),
    "A-id-15-C-dinamica-incidente": require('./15.png'),
    "A-id-16-C-dinamica-incidente": require('./16.png'),
    "A-id-17-C-dinamica-incidente": require('./_17.png'),
    //
    "B-id-1-C-dinamica-incidente":  require('./_1.png'),
    "B-id-2-C-dinamica-incidente":  require('./_2.png'),
    "B-id-8-C-dinamica-incidente":  require('./_8.png'),
    "B-id-14-C-dinamica-incidente": require('./_14.png'),
    "B-id-15-C-dinamica-incidente": require('./_15.png'),
    "B-id-16-C-dinamica-incidente": require('./_16.png'),
    "B-id-17-C-dinamica-incidente": require('./17.png'),
    //
    "A-B-id-2-dinamica-incidente":  require('./_2.png'),
    "A-B-id-2-C-dinamica-incidente":  require('./2.png'),
    "A-B-id-15-dinamica-incidente": require('./_15.png'),
    "A-B-id-15-C-dinamica-incidente": require('./15.png'),
    "A-B-id-14-dinamica-incidente": require('./_14.png'),
    "A-B-id-14-C-dinamica-incidente": require('./14.png')*/
};

export default images;
