import React, {useState, useEffect, useContext} from "react";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import apiService from "../services/api";
import {AppContext} from "./../context/AppContext";
import numbro from "numbro";
import numbroLang from "numbro/dist/languages.min.js";

numbro.registerLanguage(numbroLang["it-IT"]);
numbro.setLanguage("it-IT");

export const filterOptions = (candidate, input) => {
	if (input) {
		return candidate.label.toLowerCase().includes(input.toLowerCase());
	}

	return true;
};

export const getOptionLabel = (option, settings) => {
	if (settings && settings.formatSettings && settings.formatMethod) {
		return numbro(option.label)[settings.formatMethod](settings.formatSettings);
	} else {
		return option.label;
	}
};

const Dropdown = (props) => {
	const [loadedOptions, setLoadedOptions] = useState([]);
	const [isRemoteSource, setIsRemoteSource] = useState(false);
	const {appState} = useContext(AppContext);

	useEffect(() => {
		setIsRemoteSource(!!(props.settings && props.settings.apiSrc));

		if (!isRemoteSource && props.settings && props.settings.options) {
			setLoadedOptions(props.settings.options);
		}

		let _value = (props.value instanceof Array && props.value.length > 0) ? props.value[0] : props.value;

		if (props.settings && props.settings.formatSettings && props.settings.formatMethod) {
			_value = numbro.unformat(_value);
		}

		const _e = {target: {value: _value, id: props.id}};

		executeChangeListeners(_e, true, false);
	}, []);

	const executeChangeListeners = (_selectedOption, isFirstInitialization, showMessages) => {
		const changeEvent = props.events && props.events.find((ev) => {
			return (ev.listener === "change");
		});

		if (changeEvent) {
			if (props.settings && props.settings.formatSettings && props.settings.formatMethod) {
				_selectedOption.value = numbro.unformat(_selectedOption.value);
			}

			const domevent = {target: {value: _selectedOption && [_selectedOption.value], id: props.id}, showMessages: showMessages};

			props.assignEvents(domevent, changeEvent, props, isFirstInitialization, appState);
		}
	};

	const loadOptions = (inputValue, callback) => {
		if (!props.settings.apiSrc.includes("$FE(")) {
			apiService.get("api/" + window._TOKEN_ + props.settings.apiSrc)
				.then((res) => {
					const loadedOpts = res && res.data && res.data.map((resServer) => ({
						value: resServer.id,
						label: resServer.label,
					}));

					setLoadedOptions(loadedOpts);
					callback(loadedOpts);
				});
		}
	};

	const handleChange = (_selectedOption) => {
		const action = {target: null, value: null};
		const json = {id: props.id, domevent: {target: {value: [_selectedOption.value], label: [_selectedOption.label]}}};

		props.appendValue(json, action);
		executeChangeListeners(_selectedOption, null, true);

		return _selectedOption;
	};

	const getItemFromLoadedOptions = (_arrValue) => {
		const _value = _arrValue && _arrValue.length && _arrValue[0];

		if (!loadedOptions || !loadedOptions.length || !_value) {
			return [];
		}

		const foundItem = loadedOptions.find((lo) => lo.value === _value);

		return foundItem;
	};

	const colourStyles = {
		control: (styles) => ({...styles, backgroundColor: "white", borderRadius: 0}),
		/* option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            //const color = chroma(data.color);
            return {
                ...styles
            };
        },*/
		input: (styles) => ({...styles, backgroundColor: "white", borderColor: "transparent", borderRadius: 0}),
		placeholder: (styles) => ({...styles}),
		singleValue: (styles, {data}) => ({...styles}),
		menu: (styles, {selectProps}) => {
			return {
				...styles,
				zIndex: selectProps.zIndexMenu ? 9999 : styles.zIndex,
				position: selectProps.zIndexMenu ? "absolute" : styles.position,
			};
		},
	};

	const disabled = props.disabled ? props.disabled : false;

	return (
		<React.Fragment>
			<div className={"hrms-dropdown" + " " + props.classes} style={props.style}>
				<div className="up-label-errors">
					<ul>
						{props.validation && props.validation.result && Object.keys(props.validation.result).map((errorKey) => {
							if (!props.validation.result[errorKey].isValid && props.validation.result[errorKey].message) {
								return (<li key={errorKey}>{props.validation.result[errorKey].message}</li>);
							}
						})}
					</ul>
				</div>
				{props.label && (<label htmlFor={"dropdown-"+props.id}>{props.label}</label>)}
				{isRemoteSource ?
					<AsyncSelect
						inputId={"dropdown-"+props.id}
						styles={colourStyles}
						placeholder={props.settings.placeholder}
						key={props.settings.apiSrc}
						cacheOptions
						loadOptions={loadOptions}
						defaultOptions
						onChange={handleChange}
						getOptionLabel={(label) => getOptionLabel(label, props.settings)}
						value={getItemFromLoadedOptions(props.value)}
						isSearchable
						filterOption={filterOptions}
						disabled={disabled}
						zIndexMenu={props.zIndexMenu}
					/> :
					<Select
						inputId={"dropdown-"+props.id}
						styles={colourStyles}
						placeholder={props.settings.placeholder}
						getOptionLabel={(label) => getOptionLabel(label, props.settings)}
						value={getItemFromLoadedOptions(props.value)}
						onChange={handleChange}
						disabled={disabled}
						zIndexMenu={props.zIndexMenu}
						{...props.settings}
					/>
				}
			</div>
		</React.Fragment>
	);
};

export default Dropdown;
