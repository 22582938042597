import * as firebase from "firebase/app";
import {getMessaging} from "firebase/messaging";
import apiService from "../services/api";

export const initializeApp = (template, config) => {
	const app = firebase.initializeApp(config);

	navigator.serviceWorker.register(window.location.origin + "/sw/firebase-messaging-sw.js")
		.then(/* istanbul ignore next */(registration) => {
			const messaging = getMessaging(app);

			messaging.useServiceWorker(registration);
			messaging
				.requestPermission()
				.then(() => {
					return messaging.getToken();
				})
				.then((token) => {
					apiService.post("api/" + window._TOKEN_ + "/notification-token", {token: token}).then((res) => {
						console.log("", res);
					}).catch((err) => {
						console.info("Unable to get permission to notify", err);
					});
				});
		});
};
