import React, {useEffect, useContext} from "react";
import {withRouter} from "react-router-dom";
import {AppContext} from "./../context/AppContext";

const Card = (props) => {
	let loaderCmp = null;
	let loadingVisibleClass = "";
	const {appState} = useContext(AppContext);

	useEffect(() => {
		props.triggerCheckScrolldown(props);

		const mountedEvent = props.events && props.events.find((ev) => {
			return (ev.listener === "mount");
		});

		if (mountedEvent) {
			const domevent = {target: {value: "", id: props.id}, showMessages: false};

			props.assignEvents(domevent, mountedEvent, props);
		}
	}, []);

	if (props.settings && props.settings.isLoadingEnabled && (props.propagatedAppState.isLoading || appState.isLoading)) {
		loaderCmp = <div className="circle-loader-container">
			<div className="circle-loader">
				<div className="checkmark draw"></div>
			</div>
		</div>;

		if (props.settings && props.settings.fullPageLoader) {
			loaderCmp = <div className="full-page-loader-container">{loaderCmp}</div>;
		}

		loadingVisibleClass = "loading-visible";
	}

	return (
		<>
			<div className={props.type + " " + props.classes + " " + loadingVisibleClass} style={props.style}>
				{props.widgets ? props.widgets.map((widget) => props.composeWidget(widget)) : null}
			</div>
			{loaderCmp}
		</>
	);
};

export default withRouter(Card);
