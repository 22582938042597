import {useEffect} from "react";
import {initializeApp} from "../pwa-notifications/pwa-notifications";

export const executeServiceWorkerRegistration = (_navigator, props) => {
	if ("serviceWorker" in _navigator) {
		_navigator.serviceWorker.register(window.location.origin+"/sw/worker.js").then(function(registration) {
			console.log("Worker registration successful", registration.scope);
			initializeApp(props.data, props.configValues);
		}, function(err) {
			console.log("Worker registration failed", err);
		}).catch(function(err) {
			console.log(err);
		});
	} else {
		console.log("Service Worker is not supported by browser.");
	}
};
const Pwa = (props) => {
	// Firebase App (the core Firebase SDK) is always required and must be listed first
	// Import PWA Service Worker
	const registerServiceWorker = () => {
		executeServiceWorkerRegistration(navigator, props);
	};

	// const unRegisterServiceWorker = () => {
	//     // serviceWorker.register();
	//     if ('serviceWorker' in navigator) {//TODO
	//         window.removeEventListener('load', function () {
	//             navigator.serviceWorker.register('./worker.js').then(function (registration) {
	//                 console.log('Worker registration successful', registration.scope);
	//             }, function (err) {
	//                 console.log('Worker registration failed', err);
	//             }).catch(function (err) {
	//                 console.log(err);
	//             });
	//         });
	//     } else {
	//         console.log('Service Worker is not supported by browser.');
	//     }
	// }

	const ifNotificationEnabled =() => {
		return (typeof props.data!=="undefined" && props.data && typeof props.data.options!=="undefined" &&
            typeof props.data.options.enableNotification !=="undefined" && props.data.options.enableNotification &&
            props.configValues.enableNotification);
	};

	useEffect(() => {
		if (ifNotificationEnabled()) {
			registerServiceWorker();
		}
	}, [props.data]);

	return props.children;
};
export default Pwa;
