import moment from "moment";

export const activateAdobeAnalyticsHandler = function(env_name, analyticsDomainUrl) {
	let _env_name;

	switch (env_name) {
	case "development":
	case "test":
		_env_name = "dev";
		break;
	case "collaudo":
		_env_name = "qa";
		break;
	case "prod":
		_env_name = "prod";
		break;
	default:
		return;
	}

	if (_env_name) {
		let a = `//tags.tiqcdn.com/utag/${analyticsDomainUrl}/${_env_name}/utag.js`;
		const b = document;
		const c = "script";
		const d = b.createElement(c);

		d.src = a;
		d.type = "text/java" + c;
		d.async = true;
		a = b.getElementsByTagName(c)[0];
		a && a.parentNode && a.parentNode.insertBefore(d, a);
	}
};

export const activateHotjarHandler = function activateHotjar(_hjid) {
	(function(h, o, t, j, a, r) {
		h.hj = h.hj || function() {
			// eslint-disable-next-line
      (h.hj.q = h.hj.q || []).push(arguments);
		};
		h._hjSettings = {hjid: _hjid, hjsv: 6};
		a = o.getElementsByTagName("head")[0];
		r = o.createElement("script"); r.async = 1;
		r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
		a.appendChild(r);
	})(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
};

export function GetRouteFromPath(_pathname) {
	const splittedPath = _pathname && _pathname.split("/");

	if (splittedPath && splittedPath.length > 2) {
		return splittedPath[splittedPath.length - 1];
	}
}

export function getQueryParameterByName(name, url) {
	if (!url) url = window.location.href;

	name = name.replace(/[\[\]]/g, "\\$&");

	const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
	const results = regex.exec(url);

	if (!results) return null;

	if (!results[2]) return "";

	return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function keepValueWithoutQueryParams(valueIn) {
	let retVal = valueIn;
	const indexQuestionMark = valueIn && valueIn.indexOf("?");

	if (indexQuestionMark >= 0) {
		retVal = valueIn.substring(0, indexQuestionMark);
	}

	// Remove ended slash, if any
	if (retVal && retVal.endsWith("/")) {
		retVal = retVal.substring(0, retVal.length - 1);
	}

	return retVal;
}

export function isOperatorSupportUser(support_op_param) {
	return support_op_param && (support_op_param === true || support_op_param === "true");
}

export function getNextStepIfDisabled(steps, n) {
	return steps && steps[n] && steps[n].nextStepIfDisabled;
}

export function getPreviousStepIfDisabled(steps, n) {
	return steps && steps[n] && steps[n].previousStepIfDisabled;
}

export function GetUrlTokenComposition(_pathname) {
	const _split_url = _pathname.split("/");
	let _token = _split_url[3];

	if (_split_url.length > 4 && _split_url[4] !== "" && _split_url[4] !== "home" && isNaN(_split_url[4])) {
		_token += "/" + _split_url[4];
	}

	return _token;
}

export function GetUrlStepComposition(_pathname) {
	const _split_url = _pathname.split("/");
	let _step = undefined;

	if (!isNaN(_split_url[_split_url.length - 1]) || _split_url[_split_url.length - 1] === "home") {
		_step = _split_url[_split_url.length - 1];
	}

	return _step;
}

export function GetRedirectTarget(_pathname) {
	const _split_url = _pathname.split("/");

	return _pathname.split("/")[_split_url.length - 1];
}

export function getTimeStamp() {
	return moment().toDate();
}

export function getTimeStampFormatted(_format) {
	return moment().format(_format || "YYYY-MM-DD HH:mm:ss");
}

const TABLET_REGEX = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
const MOBILE_REGEX = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;

export const isMobileOrTablet = () => {
	const ua = navigator.userAgent;

	if (TABLET_REGEX.test(ua) || MOBILE_REGEX.test(ua)) {
		return true;
	}

	return false;
};

export const validateYouTubeUrl = (url) => {
	if (url !== undefined || url !== "") {
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
		const match = url.match(regExp);

		if (match && match[2].length == 11) {
			return true;
		}
	}
};

// Polyfill to support CustomEvent on IE
(function() {
	if (typeof window.CustomEvent === "function") return false; // If not IE

	function CustomEvent(event, params) {
		params = params || {bubbles: false, cancelable: false, detail: undefined};

		const evt = document.createEvent("CustomEvent");

		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

		return evt;
	}

	CustomEvent.prototype = window.Event.prototype;
	window.CustomEvent = CustomEvent;
})();
