import React, {useRef, useState, useEffect} from "react";
import ReactPlayer from "react-player";
import {isMobileOrTablet, validateYouTubeUrl} from "./../utils/utils";


const VideoPlayer = (props) => {
	const refPlayer = useRef(null);
	const [showCustomMuteBtn, setShowCustomMuteBtn] = useState(true);
	const [videoPlayerHeight, setVideoPlayerHeight] = useState("100%");
	const classes = props.classes ? props.classes : "";
	const values = props.value ? props.value : [];
	const controls = props.controls ? props.controls : true;

	const addEvent = (eventName, media_url, error = null) => {
		if (error) {
			props.addEvents(eventName, {media_url: media_url, error: error});
		} else {
			props.addEvents(eventName, {media_url: media_url});
		}
	};

	// Remove custom unmute if not on mobile or tablet or if video is not from Youtube
	useEffect(() => {
		const videoLink = props.value && props.value[0];

		if (!isMobileOrTablet() ||
            !(videoLink && validateYouTubeUrl(videoLink))) {
			setShowCustomMuteBtn(false);
		}

		setTimeout(()=>{
			const videoContainer = document.getElementsByClassName("hrms-video-item")[0];
			const videoElement = document.getElementsByClassName("fb-video")[0].children[0];

			if (videoElement && videoContainer) {
				const videoContainerHeight = videoContainer.offsetHeight;
				const videoElementHeight = videoElement.offsetHeight;

				if (videoContainerHeight<videoElementHeight) {
					const newHeight = videoElementHeight.toString() + "px";

					setVideoPlayerHeight(newHeight);
				}
			}
		}, 1000);
	}, []);


	const onChangeHandler = (evName, value, event) => {
		() => addEvent(evName, value);

		if (showCustomMuteBtn && evName === "VIDEO_PAUSE") {
			const _player = refPlayer.current && refPlayer.current.player && refPlayer.current.player.player;

			if (_player) {
				_player.unmute();
				_player.player && _player.player.playVideo();
			}

			setShowCustomMuteBtn(false);
		}
	};
	const customMuteRemoveClass = showCustomMuteBtn ? "" : "remove-btn";

	return (
		<div className={"hrms-video " + classes} style={{height: videoPlayerHeight}}>
			{values.map((value, i) => (
				<div key={i} className={value.length > 0 ? "hrms-video-item" : null}>
					<ReactPlayer
						ref={refPlayer}
						url={value}
						id={props.id}
						label={props.label}
						muted={props.muted}
						playing={props.playing}
						type={props.type}
						value={props.value}
						controls={controls}
						className="react-player"
						width="100%"
						height="100%"
						onPlay={(e) => onChangeHandler("VIDEO_PLAY", value, e)}
						onStart={(e) => onChangeHandler("VIDEO_START", value, e)}
						onPause={(e) => onChangeHandler("VIDEO_PAUSE", value, e)}
						onSeek={(e) => onChangeHandler("VIDEO_SEEK", value, e)}
						onEnded={(e) => onChangeHandler("VIDEO_END", value, e)}
						onError={(e) => onChangeHandler("VIDEO_ERROR", value, e)}
					>
					</ReactPlayer>

					<button className={`custom-unmute ${customMuteRemoveClass}`}>
						<i className="fas fa-volume-mute"></i>
					</button>
				</div>),
			)}
		</div>
	);
};

export default VideoPlayer;
