import React from "react";
import App from "./app";
import AppContextProvider from "./../context/AppContext";

function AppContainer() {
	return (
		<AppContextProvider>
			<App />
		</AppContextProvider>
	);
}

export default AppContainer;
