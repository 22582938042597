import IBAN from "iban";
import apiService from "./../services/api";
import utilsFunctions from "./utils_actions";
import CodiceFiscale from "codice-fiscale-js";
import moment from "moment";
import {isArray} from "lodash";

const gotoElement = utilsFunctions.gotoElement;

class RemoteValidation {
	/* istanbul ignore next */
	remoteIbanValidation = async (abi, cab) => {
		return await apiService.get("api/" + window._TOKEN_ + `/finitalia-validate-bank/${abi}/${cab}`);
	};
	/* istanbul ignore next */
	remoteCapValidation = async (cap, codComune) => {
		return await apiService.get("api/" + window._TOKEN_ + `/finitalia-validate-cap/${cap}/${codComune}`);
	};
	/* istanbul ignore next */
	getComuneFromCode = async (code) => {
		return await apiService.get("api/" + window._TOKEN_ + `/finitalia-options/comuneDenominazione?comune=${code}`);
	};
}

class Validator {
	constructor(widget, data) {
		this.widget = widget;
		this.data = data;

		const defaultMessages = {
			required: "Questo campo è obbligatorio",
			requiredIf: "Questo campo è obbligatorio",
			minLengthString: "Il campo deve essere lungo almeno %s",
			maxLengthString: "Il campo deve essere lungo al massimo %s",
			minLength: "I documenti devono essere almeno %s",
			maxLength: "Puoi caricare un massimo di %s file",
			isNumber: "Questo campo è obbligatorio e deve contenere un numero",
			isCap: "Il CAP inserito sembra non essere valido",
			isIban: "L'iban inserito non è valido",
			isBank: "Spiacenti ma l'iban inserito non corrisponde a nessuna banca nota",
			isAlphanumeric: "Sono concessi solo caratteri alfanumerici",
			gt: "Il numero deve essere maggiore di %s",
			lt: "Il numero deve essere minore di %s",
			isTrue: "Questa spunta è obbligatoria",
			isFalse: "Questa spunta è obbligatoria",
			isEqualTo: "Il valore non sembra essere %s",
			isCf: "Il codice fiscale inserito sembra non essere valido",
			isCfEqualTo: "Il codice fiscale inserito non coincide con gli altri dati inseriti",
			isCaiStepOneValid: "E' necessario inserire il campo email e l'informazione relativa all'intestatario",
			caiImpactedPointsPresent: "E' necessario inserire almeno un punto d'urto",
			isCaiStepThreeValid: "Alcuni elementi obbligatori non sono stati compilati",
			isCaiStepFourValid: "Alcuni elementi obbligatori non sono stati compilati",
			isDoubleSelectValid: "Alcuni elementi obbligatori non sono stati compilati",
			isEmail: "L'email inserita sembra non essere valida",
			isDate: "La data non è valida secondo il formato specificato",
			gtDate: "La data deve essere maggiore di %s",
			ltDate: "La data deve essere minore di %s",
			gteDate: "La data deve essere maggiore o uguale a %s",
			lteDate: "La data deve essere minore o uguale a %s",
			isPassport: "Il numero del passaporto non sembra avere un formato valido",
			isID: "Il numero della carta d'identità non sembra avere un formato valido",
			isDriverLicense: "Il numero della patente non sembra avere un formato valido",
			isDocumentValid: "Il numero inserito documento non sembra avere un formato valido",
		};

		if (typeof widget.validation !== "undefined") {
			if (typeof widget.validation.messages !== "undefined") {
				Object.keys(widget.validation.messages).forEach((rule) => {
					defaultMessages[rule] = widget.validation.messages[rule];
				});
			}
		}

		this.defaultMessages = defaultMessages;
	}

	setWidget(widget) {
		this.widget = widget;

		return this;
	}

	isAsync(func) {
		const string = func.toString().trim();

		return !!(string.match(/^async /) || string.match(/return _ref[^\.]*\.apply/));
	}

	async validate(showMessages) {
		const self = this;
		const out = {};
		const promises = [];
		const promisesMessage = [];
		const promisesPrefix = [];

		self.widget.validation.hasAtLeastOneErrorMessage = false;
		self?.widget?.validation?.rules?.forEach((rule) => {
			if (self.widget.disabled !== true) {
				const ruleArray = rule.split(":");
				const rulePrefix = ruleArray[0];
				let ruleValue = typeof ruleArray[1] !== "undefined" ? ruleArray[1] : null;

				if (typeof self[rulePrefix] !== "undefined") {
					let message = "";

					if (ruleValue && ruleValue.indexOf("#") != -1) {
						const idRegex = new RegExp("#{(.*?)}", "g");
						const m = idRegex.exec(ruleValue);
						const widget_value = gotoElement(this.data, m[1]).value;

						ruleValue = typeof widget_value === "undefined" ? 0 : widget_value[0];
					}

					if (ruleValue === "now") ruleValue = moment().format(self.widget.settings.dateFormat); // non mi piace usare dataFormat senza guardia, per ora inseriamola così

					if (showMessages) message = self.defaultMessages[rulePrefix].replace("%s", ruleValue);

					if (message) self.widget.validation.hasAtLeastOneErrorMessage = true;

					promises.push(self[rulePrefix](ruleValue));
					promisesMessage.push(message);
					promisesPrefix.push(rulePrefix);
				}
			}
		});

		const promRes = await Promise.all(promises);

		if (typeof promRes !== "undefined") {
			promRes.forEach((validat, validIndex) => {
				out[promisesPrefix[validIndex]] = {
					isValid: validat ? true : false,
					message: promisesMessage[validIndex],
				};
			});
		}

		return out;
	}

	async isValid(validationRes) {
		const validationResult = validationRes ? validationRes : await this.validate();
		const onlyFalse = [];

		for (const key in validationResult) {
			/* istanbul ignore next */
			if (validationResult.hasOwnProperty(key) && validationResult[key].isValid === false) {
				onlyFalse.push(validationResult[key].isValid);
			}
		}

		return onlyFalse.length ? false : true;
	}

	async required() {
		const value = this.widget.value;

		if (typeof value === "string" || typeof value === "number") {
			return value !== null && value !== "";
		}

		return value.length > 0;
	}

	// funziona ma comunque deve essere triggerata all'onchange della source, quindi tanto vale usare disableWhen? capire se eliminarla
	async requiredIf(payload) {
		const valueToCheck = payload.split("@")[0];
		const sourceId = payload.split("@")[1];

		const element = gotoElement(this.data, sourceId);
		const elementValue = element?.value instanceof Array ? element?.value[0] : element?.value;

		if (elementValue && elementValue === valueToCheck) {
			const resultRequired = await this.required();

			return resultRequired;
		} else {
			return true;
		}
	}

	async minLengthString(minLength) {
		const _value = this.widget.value;
		const value = _value instanceof Array ? _value[0] : _value;

		return String(value).length >= minLength;
	}

	async maxLengthString(maxLength) {
		const _value = this.widget.value;
		const value = _value instanceof Array ? _value[0] : _value;

		return String(value).length <= maxLength;
	}

	async minLength(minLength) {
		const value = this.widget.value;

		return value.length >= minLength;
	}

	async maxLength(maxLength) {
		const value = this.widget.value;

		return value.length <= maxLength;
	}

	async isNumber() {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!flatValue && flatValue !== 0) {
			return false;
		}

		return !isNaN(flatValue);
	}

	async isEqualTo(settingsValue) {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!flatValue) {
			return false;
		}

		return settingsValue === flatValue;
	}

	async isTrue() {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!flatValue) {
			return false;
		}

		return flatValue;
	}

	async isFalse() {
		return !this.isTrue();
	}

	async isCap(settingsValue) {
		const _value = this.widget.value;

		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!flatValue || !settingsValue) {
			return false;
		}

		if (_value) {
			const rv = new RemoteValidation();

			try {
				const remoteValidate = await rv.remoteCapValidation(flatValue, settingsValue);

				if (remoteValidate && remoteValidate.data && remoteValidate.data.isValid) {
					return true;
				} else {
					return false;
				}
			} catch (error) {
				console.info("Error during CAP remote validation");

				return false;
			}
		} else {
			return false;
		}
	}

	async isBank() {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!flatValue) {
			return false;
		}

		if (IBAN.isValid(flatValue)) {
			const splittedIban = IBAN.toBBAN(flatValue, ",").split(",");
			const rv = new RemoteValidation();
			let remoteValidate = false;

			try {
				remoteValidate = await rv.remoteIbanValidation(Number(splittedIban[1]), Number(splittedIban[2]));

				if (remoteValidate && remoteValidate.data && remoteValidate.data.isValid) {
					return true;
				} else {
					return false;
				}
			} catch (error) {
				console.info("Error during IBAN remote validation");

				return false;
			}
		} else {
			return false;
		}
	}

	async isIban() {
		const _value = this.widget.value;
		const flatValue = (_value instanceof Array) ? (_value.length > 0 ? _value[0] : "") : _value;

		if (!flatValue) {
			return true;
		}

		return IBAN.isValid(flatValue);
	}

	isEmailComputation(inValue) {
		// eslint-disable-next-line
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm;

		return inValue && inValue.match && inValue.match(regex);
	}

	async isEmail() {
		const _value = this.widget.value;
		/* istanbul ignore next */
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!flatValue) {
			return false;
		}

		return this.isEmailComputation(flatValue);
	}

	async isCfEqualTo(widgetIds) {
		let out = false;

		try {
			const _value = this.widget.value;
			const flatValue = _value instanceof Array ? _value[0] : _value;
			const _keys = widgetIds.split(",").map((id) => id.split("@")[0]);
			const _values = widgetIds
				.split(",")
				.map((id) => id.split("@")[1])
				.map((id) => gotoElement(this.data, id).value[0]);
			const cfData = {};

			_keys.forEach((_key, index) => {
				if (_key !== "nato") {
					cfData[_key] = _values[index];
				} else {
					const d = _values[index].split("/");

					cfData["day"] = Number(d[0]);
					cfData["month"] = Number(d[1]);
					cfData["year"] = Number(d[2]);
				}
			});

			/* istanbul ignore next */
			const rv = new RemoteValidation();
			const comD = await rv.getComuneFromCode(cfData.birthplace);

			/* istanbul ignore next */
			cfData.birthplace = comD.data[0]._id;

			/* istanbul ignore next */
			const cf = CodiceFiscale.compute(cfData);

			/* istanbul ignore next */
			if (cf.toLowerCase() === flatValue.toLowerCase()) {
				out = true;
			} else {
				out = false;
			}
		} catch (error) {
			console.error("Fail to parse Codice Fiscale");

			return false;
		}

		return out;
	}

	isValidMobilePhoneNumber(number) {
		if (!number || number.length < 9) {
			return false;
		}

		return !isNaN(number);
	}

	async caiImpactedPointsPresent(widgetIds) {
		const out = true;

		try {
			const _values = widgetIds
				.split(",")
				.map((id) => id.split("@")[1])
				.map((id) => gotoElement(this.data, id).value);

			/* istanbul ignore next */
			if (!_values || !_values.length || !_values[0] || _values[0].length <= 0) {
				/* istanbul ignore next */
				return false;
			}
		} catch (error) {
			console.error("Fail to parse impacted points data");

			return false;
		}

		return out;
	}
	/* istanbul ignore next */
	async isCaiStepOneValid(widgetIds) {
		const out = true;

		try {
			const _keys = widgetIds.split(",").map((id) => id.split("@")[0]);
			const _values = widgetIds
				.split(",")
				.map((id) => id.split("@")[1])
				.map((id) => gotoElement(this.data, id).value[0]);

			const caiData = {};

			_keys.forEach((_key, index) => {
				caiData[_key] = _values[index];
			});

			// Check mail present
			if (!caiData["EMAIL_UTENTE_ATTIVO"] || !this.isEmailComputation(caiData["EMAIL_UTENTE_ATTIVO"])) {
				return false;
			}

			// check radio present
			if (!caiData["CAI_Q1_ANCHE_INTESTATARIO"]) {
				return false;
			}

			// check radio equals to Y and with fields not present
			if (caiData["CAI_Q1_ANCHE_INTESTATARIO"] === "Y") {
				if (!caiData["INTESTATARIO_NOME"] || (caiData["INTESTATARIO_NOME"] && !isArray(caiData["INTESTATARIO_NOME"]) && caiData["INTESTATARIO_NOME"].trim().length === 0)) {
					return false;
				}

				if (
					!caiData["INTESTATARIO_COGNOME"] ||
          (caiData["INTESTATARIO_COGNOME"] && !isArray(caiData["INTESTATARIO_COGNOME"]) && caiData["INTESTATARIO_COGNOME"].trim().length === 0)
				) {
					return false;
				}
			}

			// check radio equals to N and with fields not present
			if (caiData["CAI_Q1_ANCHE_INTESTATARIO"] === "N") {
				if (
					!caiData["ALTRO_INTESTATARIO_NOME"] ||
          (caiData["ALTRO_INTESTATARIO_NOME"] && !isArray(caiData["ALTRO_INTESTATARIO_NOME"]) && caiData["ALTRO_INTESTATARIO_NOME"].trim().length === 0)
				) {
					return false;
				}

				if (
					!caiData["ALTRO_INTESTATARIO_COGNOME"] ||
          (caiData["ALTRO_INTESTATARIO_COGNOME"] && !isArray(caiData["ALTRO_INTESTATARIO_COGNOME"]) && caiData["ALTRO_INTESTATARIO_COGNOME"].trim().length === 0)
				) {
					return false;
				}

				if (
					!caiData["ALTRO_INTESTATARIO_NUMERO_TELEFONO"] ||
          (caiData["ALTRO_INTESTATARIO_NUMERO_TELEFONO"] && !this.isValidMobilePhoneNumber(caiData["ALTRO_INTESTATARIO_NUMERO_TELEFONO"]))
				) {
					return false;
				}
			}
		} catch (error) {
			console.error("Fail to parse Cai step one data");

			return false;
		}

		return out;
	}
	/* istanbul ignore next */
	async isCaiStepThreeValid(widgetIds) {
		const out = true;

		try {
			const _keys = widgetIds.split(",").map((id) => id.split("@")[0]);
			const _values = widgetIds
				.split(",")
				.map((id) => id.split("@")[1])
				.map((id) => gotoElement(this.data, id).value[0]);
			const _data = widgetIds
				.split(",")
				.map((id) => id.split("@")[1])
				.map((id) => gotoElement(this.data, id));

			const caiData = {};

			_keys.forEach((_key, index) => {
				caiData[_key] = _data[index];
			});

			const caiDataValues = {};

			_keys.forEach((_key, index) => {
				caiDataValues[_key] = _values[index];
			});

			// check first radio option selected
			if (!caiData["CAI_Q5_CON_ALTRI_VEICOLI"] || !caiDataValues["CAI_Q5_CON_ALTRI_VEICOLI"]) {
				return false;
			}

			// check if "Y" selected mandatory fields are filled

			if (caiDataValues["CAI_Q5_CON_ALTRI_VEICOLI"] === "Y") {
				const isValidTarga = (targa) => {
					if (targa && targa.length > 4) {
						const regex = /^[a-zA-Z0-9_\s]*$/gm;

						return targa.match(regex);
					}
				};

				if (!isValidTarga(caiDataValues["NUMERO_TARGA_ALTRO_PROPRIETARIO_1"])) {
					return false;
				}

				if (caiData["ALTRO_PROPRIETARIO_CARD_2"].visible === true && !isValidTarga(caiDataValues["NUMERO_TARGA_ALTRO_PROPRIETARIO_2"])) {
					return false;
				}

				if (caiData["ALTRO_PROPRIETARIO_CARD_3"].visible === true && !isValidTarga(caiDataValues["NUMERO_TARGA_ALTRO_PROPRIETARIO_3"])) {
					return false;
				}
			}

			// check if bareme select are visible and at least one has a value

			// if (caiData['BAREME_SELECT_1'].visible === true && caiData['BAREME_SELECT_2'].visible === true) {

			//     if (!caiDataValues['CAI_Q7_CIRCOSTANZA'] && !caiDataValues['CAI_Q8_CIRCOSTANZA_CONTROPARTE']) {
			//         return false;
			//     }
			// }
		} catch (error) {
			console.error("Fail to parse Cai step three data");

			return false;
		}

		return out;
	}
	/* istanbul ignore next */
	async isCaiStepFourValid(widgetIds) {
		const out = true;

		try {
			const _keys = widgetIds.split(",").map((id) => id.split("@")[0]);
			const _values = widgetIds
				.split(",")
				.map((id) => id.split("@")[1])
				.map((id) => gotoElement(this.data, id).value[0]);
			const _data = widgetIds
				.split(",")
				.map((id) => id.split("@")[1])
				.map((id) => gotoElement(this.data, id));

			const caiData = {};

			_keys.forEach((_key, index) => {
				caiData[_key] = _data[index];
			});

			const caiDataValues = {};

			_keys.forEach((_key, index) => {
				caiDataValues[_key] = _values[index];
			});

			// check radio option selected
			if (!caiData["CAI_Q9_CONDUCENTE_CPT_ANCHE_INTESTATARIO"]) {
				return false;
			}

			// check if radio option "SI" is selected if all the form fields are filled
			if (caiData["CAI_Q9_CONDUCENTE_CPT_ANCHE_INTESTATARIO"] && caiDataValues["CAI_Q9_CONDUCENTE_CPT_ANCHE_INTESTATARIO"] === "Y") {
				if (!caiDataValues["CONTROPARTE_INTESTATARIO_NOME"] || caiDataValues["CONTROPARTE_INTESTATARIO_NOME"].trim().length === 0) {
					return false;
				}

				if (!caiDataValues["CONTROPARTE_INTESTATARIO_COGNOME"] || caiDataValues["CONTROPARTE_INTESTATARIO_COGNOME"].trim().length === 0) {
					return false;
				}
			}

			// check if radio option "NO" or "NON SO" is selected if all the form fields are filled
			if (
				(caiData["CAI_Q9_CONDUCENTE_CPT_ANCHE_INTESTATARIO"] && caiDataValues["CAI_Q9_CONDUCENTE_CPT_ANCHE_INTESTATARIO"] === "N") ||
        caiDataValues["CAI_Q9_CONDUCENTE_CPT_ANCHE_INTESTATARIO"] === "DN"
			) {
				if (!caiDataValues["NOME_CONDUCENTE_CONTROPARTE"] || caiDataValues["NOME_CONDUCENTE_CONTROPARTE"].trim().length === 0) {
					return false;
				}

				if (!caiDataValues["COGNOME_CONDUCENTE_CONTROPARTE"] || caiDataValues["COGNOME_CONDUCENTE_CONTROPARTE"].trim().length === 0) {
					return false;
				}

				if (!caiDataValues["TELEFONO_CONDUCENTE_CONTROPARTE"] || !this.isValidMobilePhoneNumber(caiDataValues["TELEFONO_CONDUCENTE_CONTROPARTE"])) {
					return false;
				}
			}
		} catch (error) {
			console.error("Fail to parse Cai step four data");

			return false;
		}

		return out;
	}

	/* istanbul ignore next */
	async isDoubleSelectValid(widgetIds) {
		const out = true;

		try {
			const _keys = widgetIds.split(",").map((id) => id.split("@")[0]);
			const _values = widgetIds
				.split(",")
				.map((id) => id.split("@")[1])
				.map((id) => gotoElement(this.data, id).value[0]);
			const _data = widgetIds
				.split(",")
				.map((id) => id.split("@")[1])
				.map((id) => gotoElement(this.data, id));

			const caiData = {};

			_keys.forEach((_key, index) => {
				caiData[_key] = _data[index];
			});

			const caiDataValues = {};

			_keys.forEach((_key, index) => {
				caiDataValues[_key] = _values[index];
			});

			if (!caiDataValues["CAI_Q7_CIRCOSTANZA"] && !caiDataValues["CAI_Q8_CIRCOSTANZA_CONTROPARTE"]) {
				return false;
			}
		} catch (error) {
			console.error("Fail to parse double select data");

			return false;
		}

		return out;
	}

	async isCf() {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!flatValue) {
			return false;
		}

		const regex = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/gm;

		return flatValue.match(regex);
	}

	async isAlphanumeric() {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!flatValue) {
			return false;
		}

		const regex = /^[a-zA-Z0-9_\s]*$/gm;

		return flatValue.match(regex);
	}

	async gt(limitNumber) {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!isNaN(flatValue)) {
			const numValue = +flatValue;

			return numValue > limitNumber;
		}
	}

	async lt(limitNumber) {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!isNaN(flatValue)) {
			const numValue = +flatValue;

			return numValue < limitNumber;
		}
	}

	processDate(dateValue) {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;
		const dateFormat = this.widget.settings.dateFormat;
		const date = dateValue === "now" ? moment(moment().format(dateFormat), dateFormat) : moment(dateValue, dateFormat);
		const dateToValidate = moment(flatValue, dateFormat);

		return [date, dateToValidate];
	}

	async isDate() {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;
		const dateFormat = this.widget.settings.dateFormat;

		return moment(flatValue, dateFormat, true).isValid();
	}

	async gtDate(dateValue) {
		const values = this.processDate(dateValue);
		const date = values[0];
		const dateToValidate = values[1];

		if (!date.isValid() || !dateToValidate.isValid()) {
			return false;
		}

		return dateToValidate.isAfter(date);
	}

	async ltDate(dateValue) {
		const values = this.processDate(dateValue);
		const date = values[0];
		const dateToValidate = values[1];

		if (!date.isValid() || !dateToValidate.isValid()) {
			return false;
		}

		return date.isAfter(dateToValidate);
	}

	async gteDate(dateValue) {
		const values = this.processDate(dateValue);
		const date = values[0];
		const dateToValidate = values[1];

		if (!date.isValid() || !dateToValidate.isValid()) {
			return false;
		}

		return dateToValidate.isSameOrAfter(date);
	}

	async lteDate(dateValue) {
		const values = this.processDate(dateValue);
		const date = values[0];
		const dateToValidate = values[1];

		if (!date.isValid() || !dateToValidate.isValid()) {
			return false;
		}

		return date.isSameOrAfter(dateToValidate);
	}

	async isPassport() {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!flatValue) {
			return false;
		}

		const regex = /^[A-Z]{2}[0-9]{7}$/;

		return flatValue.match(regex);
	}

	async isID() {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!flatValue) {
			return false;
		}

		const regex_paper = /^[A-Z]{2}[0-9]{7}$/;
		const regex_cie = /^[A-Z]{2}[0-9]{5}[A-Z]{2}$/;

		return flatValue.match(regex_paper) || flatValue.match(regex_cie);
	}

	async isDriverLicense() {
		const _value = this.widget.value;
		const flatValue = _value instanceof Array ? _value[0] : _value;

		if (!flatValue) {
			return false;
		}

		const regex_new = /^U1[A-Z0-9]{7}[A-Z]{1}$/;
		const regex_old = /^[A-Z]{2}[A-Z0-9]{7}[A-Z]{1}$/;

		return flatValue.match(regex_new) || flatValue.match(regex_old);
	}

	async isDocumentValid() {
		const _value = this.widget.value;

		if (!_value) {
			return false;
		} else {
			let flatValue = _value instanceof Array && _value[0] ? _value[0].toUpperCase() : _value;

			if (!flatValue) {
				return false;
			} else {
				flatValue = flatValue.toUpperCase();
			}

			const regex_driver_new = /^U1[A-Z0-9]{7}[A-Z]{1}$/;
			const regex_driver_old = /^[A-Z]{2}[A-Z0-9]{7}[A-Z]{1}$/;
			const regex_paper_id = /^[A-Z]{2}[0-9]{7}$/;
			const regex_electronic_id = /^[A-Z]{2}[0-9]{5}[A-Z]{2}$/;
			const regex_passport = /^[A-Z]{2}[0-9]{7}$/;

			return (
				flatValue.match(regex_driver_new) ||
        flatValue.match(regex_driver_old) ||
        flatValue.match(regex_paper_id) ||
        flatValue.match(regex_electronic_id) ||
        flatValue.match(regex_passport)
			);
		}
	}
}

class ValidationCollection {
	constructor(step, data) {
		this.step = step;
		this.data = data;
		this.widget = [];

		if (step.validation) {
			this.widget.push(step.validation.result);
		} else {
			this.iterateWidget(this.step);
		}
	}

	iterateWidget = (widget) => {
		if (typeof widget.widgets !== "undefined") {
			widget.widgets.forEach((widget) => {
				return this.iterateWidget(widget);
			});
		} else {
			if (typeof widget.validation !== "undefined") {
				this.widget.push(widget.validation.result);
			}
		}
	};

	validate() {
		return this.widget;
	}

	isValid(validationRes) {
		const validationResult = validationRes ? validationRes : this.validate();
		const onlyFalse = [];

		for (const key in validationResult) {
			for (const keyDetail in validationResult[key]) {
				if (validationResult[key].hasOwnProperty(keyDetail) && validationResult[key][keyDetail].isValid === false) {
					onlyFalse.push(validationResult[key][keyDetail].isValid);
				}
			}
		}

		return onlyFalse.length ? false : true;
	}
}

export {ValidationCollection};
export default Validator;

