import React, {useState, useEffect} from "react";
import apiService from "../services/api";
import {withRouter} from "react-router-dom";
import {Uploader} from "../utils/imports";
import Modal from "react-modal";

if (document.getElementById("app")) {
	Modal.setAppElement("#app");
}

const modalCustomStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};

const GridImageUploaderIP = (props) => {
	const [impactedPoints, setImpactedPoints] = useState([]);
	const [veichlePrecenceWarningPresent, setVeichlePrecenceWarningPresent] = useState(false);
	const [checkWarningInterval, setCheckWarningInterval] = useState(null);
	const [currentModalOpenedCode, setCurrentModalOpenedCode] = useState(null);
	const [modalIsOpen, setIsOpen] = React.useState(false);

	useEffect(() => {
		props.triggerCheckScrolldown && props.triggerCheckScrolldown(props);

		setImpactedPoints([]);
		computeImpactedPoints();

		const checkWarningIntervalFunction = setInterval(() => {
			const vpWarningList = document.querySelectorAll(".vehicle-presence-warning");

			setVeichlePrecenceWarningPresent((vpWarningList && vpWarningList.length > 0));
		}, 500);

		setCheckWarningInterval(checkWarningIntervalFunction);

		return () => {
			clearInterval(checkWarningInterval);
		};
	}, []);

	const computeImpactedPoints = () => {
		let _impactedPoints = props.value;

		// First see if impacted points have already been selected by user
		if (_impactedPoints && _impactedPoints.length > 0) {
			setImpactedPoints(_impactedPoints);

			return;
		} else {
			_impactedPoints = [];
		}

		if (props.settings && props.settings.hideDefaultSuggestedDamages) {
			saveUpdatedImpactedPoints([], true);

			return;
		}

		// Otherwise, show predicted points

		// In any case (either the user confirmed the algorithm dynamic, answered the questions or gave free description)
		// show the predicted algorithm dynamic
		let mainImpactPointValue = props.getTargetValueByName("main_impact_point_widget", "value");

		if (props.settings && props.settings.isCtp) {
			mainImpactPointValue = props.getTargetValueByName("main_impact_point_widget_ctp", "value");
		}

		if (mainImpactPointValue) {
			_impactedPoints.push(mainImpactPointValue.toLowerCase());
		}
		// // If accident dynamic has been modified (if "id": "35-0-0-11" ("name": "bareme_dynamic_user_confirmed_or_modified") has value ["BAREMES_MODIFIED_CONFIRMED"]),
		// // --> then take first assessment question, "Dove sei stato urtato?" ("id": "11-0-0-0", "name": "bareme_q_1") and give priority to that answer
		// let btnConfirmedModifiedPlainValue = props.getTargetValueByName("bareme_dynamic_user_confirmed_or_modified", "value");
		// let kascoCaseValue = props.getTargetValueByName("is_kasco_case", "value");

		// if (btnConfirmedModifiedPlainValue === 'BAREMES_MODIFIED_CONFIRMED') {
		//     let nsWidgetValue = props.getTargetValueByName("bareme_q_1", "value");
		//     if (props.settings && props.settings.isCtp) {
		//         nsWidgetValue = props.getTargetValueByName("bareme_q_2", "value");
		//     }
		//     _impactedPoints = nsWidgetValue && mapUrtoImpactPoints[nsWidgetValue];
		// } else if ((btnConfirmedModifiedPlainValue === 'BATCH_BAREMES_CONFIRMED') || (kascoCaseValue === "true")) {
		//     // Else, if the user confirmed the proposed dynamic (if "id": "35-0-0-11" ("name": "bareme_dynamic_user_confirmed_or_modified")
		// has value ["BATCH_BAREMES_CONFIRMED"]), or in case of kasco (if "id": "35-0-0-12" has value ["true"])
		//     // -->  than search for the main impact point
		//     // (template "main_impact_point" property, that is inserted as placeholder in "id": "35-0-0-10" ("name": "main_impact_point_widget")
		//     let mainImpactPointValue = props.getTargetValueByName("main_impact_point_widget", "value");
		//     if (props.settings && props.settings.isCtp) {
		//         mainImpactPointValue = props.getTargetValueByName("main_impact_point_widget_ctp", "value");
		//     }
		//     if (mainImpactPointValue) {
		//         _impactedPoints.push(mainImpactPointValue.toLowerCase());
		//     }
		// }

		if (_impactedPoints && _impactedPoints.length >= 0) {
			saveUpdatedImpactedPoints(_impactedPoints);
		}
	};

	const executeChangeListeners = (e, isFirstInitialization, showMessages) => {
		const changeEvent = props.events && props.events.find((ev) => {
			return (ev.listener === "change");
		});

		if (changeEvent) {
			const _value = impactedPoints;
			const domevent = {target: {value: [_value], id: e.target.id}, showMessages: showMessages};

			props.assignEvents(domevent, changeEvent, props, isFirstInitialization);
		}
	};

	const isGridItemSuggested = (gridItem) => {
		const foundImpactedPoint = impactedPoints.find((ip) => gridItem.impact_code && gridItem.impact_code.includes(ip));

		return foundImpactedPoint && foundImpactedPoint.length > 0;
	};

	const renderUpload = (gridItemEl, shouldOpenModal) => {
		const uploadEl = gridItemEl && gridItemEl.widgets && gridItemEl.widgets.length > 0 && gridItemEl.widgets[0];

		if (uploadEl) {
			return <div onClick={() => shouldOpenModal && openModal(gridItemEl)}>
				<div style={{pointerEvents: shouldOpenModal ? "none" : "auto"}}>
					<Uploader
						appendValues={props.appendValues} removeValues={props.removeValues}
						triggerCheckScrolldown={props.triggerCheckScrolldown} globalStyle={props.globalStyle}
						{...uploadEl} composeWidget={props.composeWidget} propagatedAppState={props.propagatedAppState}
						data={props.data} assignEvents={props.assignEvents} addEvents={props.addEvents} />
				</div>
			</div>;
		}

		return null;
	};

	const openModal = (gridItemEl) => {
		setIsOpen(true);
		setCurrentModalOpenedCode(gridItemEl.impact_code);
	};

	const closeModal = () => {
		setIsOpen(false);
		setCurrentModalOpenedCode(null);
	};

	const addPhotoToPoint = () => {
		const currentGridItem = props.gotoElement(currentModalOpenedCode, "impact_code");

		if (!currentGridItem || !currentGridItem.id) {
			return;
		}

		const currentUploadBtnId = `${currentGridItem.id}-0-upload-button`;

		document.getElementById(currentUploadBtnId).click();
		closeModal();
	};

	const removeImpactPoint = () => {
		const updatedImpactedPoints = impactedPoints.filter((ip) => !currentModalOpenedCode.includes(ip));

		saveUpdatedImpactedPoints(updatedImpactedPoints);
		closeModal();
	};

	const renderGridItem = (gridItemEl) => {
		if (hasGridItemPhoto(gridItemEl)) {
			return renderUpload(gridItemEl);
		} else
			if (isGridItemSuggested(gridItemEl)) {
				return <div>
					{renderUpload(gridItemEl, true)}
					<button className="abs-btn btn-open-modal" onClick={() => openModal(gridItemEl)}>O</button>
				</div>;
			} else {
				return <div></div>;
			}
	};

	const hasGridItemPhoto = (gridItemEl) => {
		const uploadEl = gridItemEl && gridItemEl.widgets && gridItemEl.widgets.length > 0 && gridItemEl.widgets[0];

		return !!(uploadEl && uploadEl.value && uploadEl.value.length > 0);
	};

	const suggestedDamagesEnabled = (wProps) => {
		return wProps && wProps.settings && wProps.settings.suggestedDamagesEnabled === true;
	};

	const postValueImpactedPoints = (impactedPoints) => {
		const body = {
			"data": [
				{
					"widget_id": props.id,
					"name": props.name,
					"value": impactedPoints,
				},
			],
		};
		const apiValue = "/api/" + window._TOKEN_;

		apiService.post(apiValue, body);
	};

	const saveUpdatedImpactedPoints = (updatedImpactedPoints, saveOnlyLocally) => {
		if (!saveOnlyLocally) {
			postValueImpactedPoints(updatedImpactedPoints);
		}

		setImpactedPoints(updatedImpactedPoints);

		const action = {target: null, value: null};
		const json = {id: props.id, domevent: {target: {value: updatedImpactedPoints}}};

		props.appendValue(json, action);
		executeChangeListeners({target: {id: props.id}}, null, true);
	};

	const cellClickedHandler = (e, gridItemEl) => {
		/* If the click is on an already impacted point, then open popup to add photo */
		if (impactedPoints && impactedPoints.indexOf(gridItemEl.impact_code) >= 0) {
			setCurrentModalOpenedCode(gridItemEl.impact_code);
		} else {
			/* Else, add the impacted point */
			const updatedImpactedPoints = [...impactedPoints, gridItemEl.impact_code];

			saveUpdatedImpactedPoints(updatedImpactedPoints);
			e.stopPropagation();
		}
	};

	const renderGrid = (wProps) => {
		const gridItems = [];
		let rowElArray = [];

		wProps.widgets.forEach((gridItemEl, index, arr) => {
			const currentElement = <td
				key={index}
				className={gridItemEl.classes + " grid-item " + (suggestedDamagesEnabled(wProps) && isGridItemSuggested(gridItemEl) ?
					"suggested-damaged-zone" : "")}
				onClick={(e) => cellClickedHandler(e, gridItemEl)}>
				{renderGridItem(gridItemEl)}
			</td>;

			rowElArray.push(currentElement);

			// if multiple of three, then create row
			if ((index + 1) % 3 == 0) {
				gridItems.push(<tr key={index}>{rowElArray}</tr>);
				rowElArray = [];
			}
		});

		const gridResult = <table className={wProps.type + " " + wProps.classes + " grid-container"} style={wProps.style}><tbody>{gridItems}</tbody></table>;

		return gridResult;
	};

	// find data for the root widget where the gridImageUploader is inserted
	const stepRootId = props.id.split("-")[0];
	const stepData = props.data && props.data.steps && props.data.steps.filter((el) => el.id === stepRootId);

	// check for validation
	const errors = stepData && stepData.length > 0 && stepData[0].validation && stepData[0].validation.result && Object.keys(stepData[0].validation.result).map((errorKey) => {
		if (!stepData[0].validation.result[errorKey].isValid && stepData[0].validation.result[errorKey].message) {
			return (<li key={errorKey}>{stepData[0].validation.result[errorKey].message}</li>);
		}
	});

	const carDesignImg = props.propagatedAppState.images.carDesignImg;
	const vehicleWarningElement = veichlePrecenceWarningPresent ?
		<div className="grid-vehicle-presence-warning"><span>*</span> Attenzione. Una o più foto non sembrano contenere un veicolo</div> : null;

	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={modalCustomStyles}>
				<div className="modal-header">
					<p className="title">Foto dell&apos;incidente</p>
					<button className="button close-modal-btn" onClick={closeModal}><i className="fas fa-times"></i></button>
				</div>
				<div className="modal-content">
					<button className="button add-phoyto-btn"
						onClick={() => addPhotoToPoint()}><i className="fas fa-camera"></i> Aggiungi foto</button>
					<button className="button link-btn"
						onClick={() => removeImpactPoint()}>Elimina zona d&apos;urto</button>
				</div>
			</Modal>

			<div className={props.type + " " + props.classes} style={props.style}>
				<div className="pure-container">
					<div className="img-container">
						<img src={carDesignImg} alt="Disegno macchina" />
						{renderGrid(props)}
					</div>
					{vehicleWarningElement}
				</div>
				<div className="up-label-errors" style={{display: "none"}}>
					<ul>
						{errors}
					</ul>
				</div>
			</div>
		</>
	);
};

export default withRouter(GridImageUploaderIP);
