import React, {Component} from "react";
import {Document, Page} from "react-pdf/dist/entry.noworker";
import Webcam from "react-webcam";
import {withRouter} from "react-router-dom";
import atob from "atob";
import apiService from "../services/api";
import axios from "axios";
import images from "../utils/images";

/*
  "preview_title": "File Caricato",
  "preview_desc": "Ecco quello che hai caricato",
  "preview_order": "desc",
  "button_position": "top"
*/

const host = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");

class Uploader extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.hiddenInput = React.createRef();

		this.state = {
			scattaButton: false,
			loadingUploadImage: false,
			classCamera: null,
			classWarning: null,
			facingMode: null,
			metadata: [],
			showCamera: false,
			haveCameraPermission: true,
			tmpCameraImage: null,
			uploadErrors: [],
			beforeUploadErrors: [],
			cameraRotation: 0,
			pdfMimeType: [
				"application/pdf",
				"application/x-pdf",
				"application/acrobat",
				"applications/vnd.pdf",
				"text/pdf",
				"text/x-pdf",
			],
		};
		this.unregisterEvent = this.unregisterEvent.bind(this);
		this.showCamera = this.showCamera.bind(this);
		this.hideCamera = this.hideCamera.bind(this);
		this.renderModal = this.renderModal.bind(this);
		this.renderCamera = this.renderCamera.bind(this);
		this.renderButtons = this.renderButtons.bind(this);
		this.captureCamera = this.captureCamera.bind(this);
		this.onUserMediaError = this.onUserMediaError.bind(this);
		this.deleteCameraPicture = this.deleteCameraPicture.bind(this);
		this.togglePreview = this.togglePreview.bind(this);
		this.b64toBlob = this.b64toBlob.bind(this);
		this.rotationClicked = this.rotationClicked.bind(this);
		this.useNull = this.useNull.bind(this);
		this.photoClickHandler = this.photoClickHandler.bind(this);
	};

	registerEvent(e, p) {
		this.setState({beforeUploadErrors: []});

		if ((p.settings.sizeLimit && e.target.files[0].size <= p.settings.sizeLimit && p.settings.accept.indexOf(e.target.files[0].type) !== -1) || !p.settings.sizeLimit) {
			const formData = new FormData();

			Object.keys(e.target.files).forEach((fKey) => formData.append("files[]", new Blob([e.target.files[fKey]], {type: e.target.files[fKey].type}), e.target.files[fKey].name));
			this.performUpload(formData, p);
			e.target.value = null;
		} else {
			// In case user has defined a size limit and the file uploaded has a size greater than the limit defined show an error message to let them know which is the limit
			let updatedBeforeUploadErrors = [];

			if (p.settings.sizeLimit && e.target.files[0].size >= p.settings.sizeLimit) {
				updatedBeforeUploadErrors = [...updatedBeforeUploadErrors, `Non puoi caricare un file più grande di ${p.settings.sizeLimit/1048576} mb`];
				this.setState({beforeUploadErrors: updatedBeforeUploadErrors});
			}

			// If the user forces to upload a non accepted file type do not save
			if (p.settings.accept.indexOf(e.target.files[0].type) === -1) {
				updatedBeforeUploadErrors = [
					...updatedBeforeUploadErrors,
					`(${e.target.files[0].type}) non è un formato supportato, prova a ricaricarlo come pdf o immagine (${p.settings.accept.join(", ")})`,
				];
				this.setState({beforeUploadErrors: updatedBeforeUploadErrors});
			}
		}
	}

	registerEventAndCloseCamera(e, p) {
		this.registerEvent(e, p);
		this.hideCamera();
	}

	addMetadataValidation(p, validateArr) {
		// Validate images and update metadata with validation info
		if (p.settings && p.settings.validationEnabled) {
			return axios.all(validateArr.map((g) => apiService.get(g))).then((response) => {
				if (this._isMounted) {
					const metadata = [...this.state.metadata];

					response.forEach((res) => {
						metadata.forEach((m) => {
							if (m.id === (res.data && res.data._id)) {
								m.validate = (res.data.metadata && res.data.metadata.validate) ? res.data.metadata.validate : null;
								m.metadata.validate = m.validate;
							}
						});
					});
					this.setState({metadata});
				}
			});
		}
	}

	executeChangeListeners(isFirstInitialization, showMessages) {
		const p = this.props;
		const changeEvent = p.events && p.events.find((ev) => {
			return (ev.listener === "change");
		});

		if (changeEvent) {
			const domevent = {target: {value: "", id: p.id}, showMessages: showMessages};

			this.props.assignEvents(domevent, changeEvent, p, isFirstInitialization);
		}
	}

	performUpload(formData, p) {
		this.setState({
			loadingUploadImage: true,
		});

		const Input = this.hiddenInput.current; // document.getElementById(this.props.id);
		const apiFiles = "/api/" + window._TOKEN_ + "/upload-attachment";

		formData.append("widget_id", p.id);
		formData.append("name", p.name);

		if (p.readOnlyValues) {
			formData.append("readOnlyValues", JSON.stringify(p.readOnlyValues));
		}

		if (typeof p.file_type !== "undefined") {
			formData.append("file_type", p.file_type);
		}

		const config = {
			headers: {
				"content-type": `multipart/form-data; boundary=${formData._boundary}`,
			},
		};

		return apiService.post(apiFiles, formData, config).then((res) => {
			const linksArr = [];
			const validateArr = [];
			const errors = res.data.errors;
			const vitrSessionAuthToken = sessionStorage.getItem("Vitr-Session");

			res.data.uploaded.forEach((el) => {
				const action = {value: el._id};
				const json = {id: p.id};

				p.appendValues(json, action);
				linksArr.push("/api/" + window._TOKEN_ + "/attachment/" + el._id + "?vitr_session=" + vitrSessionAuthToken);
				validateArr.push(
					"/api/" +
          window._TOKEN_ +
          "/verify-attachment/" +
          el._id +
          "?models=" +
          (p.settings && p.settings.validatorsCommaList) +
          "&vitr_session=" +
          vitrSessionAuthToken);
			});

			return axios.all(linksArr.map((g) => apiService.get(g))).then((response) => {
				const metadata = [...this.state.metadata];

				response.forEach((res) => {
					if (res.data && res.data.metadata && res.data.metadata.file_type) {
						p.addEvents("DOC_UPLOADED", {doc_type: res.data.metadata.file_type});
					} else {
						p.addEvents("DOC_UPLOADED", {doc_type: "UNKNOWN"});
					}

					const uploadedEvent = p.events && p.events.find((ev) => {
						return (ev.listener === "uploaded");
					});

					if (uploadedEvent) {
						const domevent = {target: {value: "", id: p.id}, showMessages: false};

						p.assignEvents(domevent, uploadedEvent, p);
					}

					metadata.push({
						id: res.data._id,
						name: res.data.filename,
						metadata: res.data.metadata,
						validate: res.data.validate ? res.data.validate : null,
						preview: false,
						src: process.env.REACT_APP_BACKEND_BASEURL + "/api/" + window._TOKEN_ + "/download-attachment/" + res.data._id + "?vitr_session=" + vitrSessionAuthToken,
					});
				});

				if (this._isMounted) {
					this.setState({metadata, uploadErrors: errors, loadingUploadImage: false}, () => {
						if (Input) {
							this.executeChangeListeners(null, true);
						}

						// Validate images and update metadata with validation info
						this.addMetadataValidation(p, validateArr);
					});
				}
			});
		});
	}

	unregisterEvent(e, id) {
		const Input = this.hiddenInput.current;

		return apiService.delete("/api/" + window._TOKEN_ + "/attachment/" + id).then((res) => {
			const action = {value: id};
			const json = {id: this.props.id};

			this.props.removeValues(json, action);

			if (this.props.file_type) {
				this.props.addEvents("DOC_REMOVED", {doc_type: this.props.file_type});
			} else {
				this.props.addEvents("DOC_REMOVED", {doc_type: "UNKNOWN"});
			}

			if (this._isMounted) {
				this.setState({
					warningMessageValidator: false,
					metadata: this.state.metadata.filter(function(m) {
						return m.id !== id;
					}),
				}, () => {
					if (Input) {
						this.executeChangeListeners(null, true);
					}
				});
			}
		});
	}

	useNull(param) {
		return null;
	}

	componentDidMount() {
		this._isMounted = true;

		const p = this.props;

		p.triggerCheckScrolldown(p);

		const Input = this.hiddenInput.current;
		const vitrSessionAuthToken = sessionStorage.getItem("Vitr-Session");

		// unico set state
		this.setFacingMode(p).then((facingMode) => {
			if (p?.value?.length) {
				const linksArr = [];
				const validateArr = [];

				p.value.forEach((id) => {
					linksArr.push("/api/" + window._TOKEN_ + "/attachment/" + id + "?vitr_session=" + vitrSessionAuthToken);
					validateArr.push(
						"/api/" +
            window._TOKEN_ +
            "/verify-attachment/" +
            id + "?models=" +
            (p.settings && p.settings.validatorsCommaList) +
            "&vitr_session=" +
            vitrSessionAuthToken);
				});

				return axios.all(linksArr.map((g) => apiService.get(g).catch(this.useNull))).then((response) => {
					if (this._isMounted) {
						const metadata = [];
						const successResults = response && response.filter((promisReturned) => (promisReturned != null));
						const successResultsId = successResults.map((succRes) => succRes.data._id);

						successResults.forEach((res) => {
							const data = {
								id: res.data._id,
								name: res.data.filename,
								metadata: res.data.metadata,
								preview: false,
								src: process.env.REACT_APP_BACKEND_BASEURL + "/api/" + window._TOKEN_ + "/download-attachment/" + res.data._id + "?vitr_session=" + vitrSessionAuthToken,
							};

							if (p.readOnlyValues) {
								data.readOnly = !!(p.readOnlyValues.filter((id) => res.data._id === id).length);
							}

							metadata.push(data);
						});

						// /* Add also Errors as corrupted file icon */
						const errorResultsId = p.value.filter((id) => (successResultsId.indexOf(id) < 0));

						errorResultsId.forEach((errId) => {
							metadata.push({
								id: errId,
								name: "file corrotto",
								classes: "corrupted-file",
								metadata: {},
								preview: false,
								src: p.propagatedAppState.images["file-corrupted"],
							});
						});

						this.setState({metadata, ...facingMode}, () => {
							if (Input) {
								this.executeChangeListeners(null, true);
							}

							// Validate images and update metadata with validation info
							this.addMetadataValidation(p, validateArr);
						});
					}
				});
			} else {
				if (this._isMounted) {
					this.setState({...facingMode}, () => {
						if (Input) {
							if (p.validation && typeof p.validation.result === "undefined") {
								this.executeChangeListeners(null, false);
							}
						}
					});
				}
			}
		});

		// fix MIUI browser bug (pending promise) and similar
		if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
			// Firefox 38+ seems having support of enumerateDevicesx
			navigator.enumerateDevices = function(callback) {
				navigator.mediaDevices.enumerateDevices().then(callback);
			};
		}

		let canEnumerate = false;

		if (typeof MediaStreamTrack !== "undefined" && "getSources" in MediaStreamTrack) {
			canEnumerate = true;
		} else if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
			canEnumerate = true;
		}

		const isWebcamAlreadyCaptured = false;
		const hasWebcam = false;

		this.checkDeviceSupport(canEnumerate, isWebcamAlreadyCaptured, hasWebcam, (isWebcamAlreadyCaptured) => {
			this.setState({scattaButton: isWebcamAlreadyCaptured && !p.hideCapturePhotoButton});
		});
	}

	checkDeviceSupport = (canEnumerate, isWebcamAlreadyCaptured, hasWebcam, callback) => {
		if (!canEnumerate) {
			return;
		}

		if (!navigator.enumerateDevices && window.MediaStreamTrack && window.MediaStreamTrack.getSources) {
			navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack);
		}

		if (!navigator.enumerateDevices && navigator.enumerateDevices) {
			navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
		}

		if (!navigator.enumerateDevices) {
			if (callback) {
				callback(isWebcamAlreadyCaptured); // se la webcam è abilitata, metti a true e finisce tutto
			}

			return;
		}

		navigator.enumerateDevices(function(devices) {
			devices.forEach(function(_device) {
				const device = {};

				for (const d in _device) {
					device[d] = _device[d];
				}

				if (device.kind === "video" || device.kind === "videoinput") {
					device.kind = "videoinput";
					hasWebcam = true;

					if (device.kind === "videoinput" && !isWebcamAlreadyCaptured) {
						isWebcamAlreadyCaptured = true; // se la webcam non è abilitata, forzala a true
					}
				}
			});

			if (callback) {
				callback(isWebcamAlreadyCaptured);
			}
		});
	};

	setFacingMode(p) {
		return new Promise((resolve, reject) => {
			if (p.settings && p.settings.cameraDisabled === true) {
				resolve({
					facingMode: {exact: "environment"},
					classCamera: "hrms-popup-camera-content",
					classWarning: "hrms-popup-camera-warning",
				});
			} else if (typeof navigator.mediaDevices !== "undefined" && typeof navigator.mediaDevices.getUserMedia !== "undefined") {
				navigator.mediaDevices.getUserMedia({
					video: {facingMode: {exact: "environment"}},
				}).then((res) => {
					resolve({
						facingMode: {exact: "environment"},
						classCamera: "hrms-popup-camera-content",
						classWarning: "hrms-popup-camera-warning",
					});
				}).catch((err) => {
					resolve({
						facingMode: "user",
						classCamera: "hrms-popup-camera-content revert",
						classWarning: "hrms-popup-camera-warning",
					});
				});
			} else {
				resolve({
					facingMode: "user",
					classCamera: "hrms-popup-camera-content revert",
					classWarning: "hrms-popup-camera-warning",
				});
			}
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	isPdf(m) {
		return m && m.metadata && m.metadata.mimetype && (this.state.pdfMimeType.indexOf(m.metadata.mimetype.toLowerCase()) !== -1);
	}

	b64toBlob(b64Data, contentType, sliceSize) {
		contentType = contentType || "";
		sliceSize = sliceSize || 512;

		const byteCharacters = atob(b64Data.split(",")[1]);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);

			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, {type: contentType});

		return blob;
	}

	deleteCameraPicture() {
		this.setState({tmpCameraImage: null});
	}

	rotationClicked() {
		// Update variable state
		this.setState((state) => ({
			cameraRotation: this.state.cameraRotation + 90,
		}));
	}

	rotateBase64Image(base64Image) {
		// If it is it is a multiple of 360deg
		if ((this.state.cameraRotation % 360) === 0) {
			return base64Image;
		}

		// create an off-screen canvas
		const offScreenCanvas = document.createElement("canvas");
		const offScreenCanvasCtx = offScreenCanvas.getContext("2d");

		// cteate Image
		const img = new Image();

		img.src = base64Image;

		// If it is it is a multiple of (90+n180)deg, (90+n270)deg
		if ((this.state.cameraRotation % 360) === 270) {
			// Rotate 90 deg counterclockwise
			offScreenCanvas.width = img.height;
			offScreenCanvas.height = img.width;
			offScreenCanvasCtx.rotate(-90 * Math.PI / 180);
			offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
		} else if ((this.state.cameraRotation % 360) === 180) {
			// Rotate 180 deg
			offScreenCanvas.width = img.width;
			offScreenCanvas.height = img.height;
			offScreenCanvasCtx.translate(img.width, img.height);
			offScreenCanvasCtx.rotate(180 * Math.PI / 180);
		} else if ((this.state.cameraRotation % 360) === 90) {
			// Rotate 90 deg clockwise
			offScreenCanvas.width = img.height;
			offScreenCanvas.height = img.width;
			offScreenCanvasCtx.rotate(90 * Math.PI / 180);
			offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
		}

		offScreenCanvasCtx.drawImage(img, 0, 0);

		// encode image to data-uri with base64
		return offScreenCanvas.toDataURL("image/jpeg", 100);
	}

	uploadCameraPicture(p) {
		const rotatedImg = (p.settings && p.settings.imageRotationAvailable) ? this.rotateBase64Image(this.state.tmpCameraImage) : this.state.tmpCameraImage;
		const blobFile = this.b64toBlob(rotatedImg, "image/jpeg");
		const formData = new FormData();

		formData.append("files[]", blobFile, "camera-picture.jpg");
		this.performUpload(formData, p);
		this.hideCamera();
	}

	showCamera() {
		this.setState({
			showCamera: true,
			haveCameraPermission: (typeof navigator.mediaDevices !== "undefined" && typeof navigator.mediaDevices.getUserMedia !== "undefined") ? true : false,
		});
	}

	captureCamera() {
		// Get the webcam object
		const webcam = this.webcam;

		// Get the screen dimensions
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		// Calculate the aspect ratio of the canvas based on screen dimensions
		const canvasAspectRatio = screenWidth / screenHeight;

		if (webcam.video) {
			// Get the dimensions of the webcam video stream
			const videoWidth = webcam.video.videoWidth || 1280;
			const videoHeight = webcam.video.videoHeight || 720;
			const videoAspectRatio = videoWidth / videoHeight;

			// Initialize variables for cropping the video stream to fit the canvas
			let sourceWidth; let sourceHeight;

			// Calculate cropping dimensions to fit the canvas while maintaining aspect ratio
			if (videoAspectRatio > canvasAspectRatio) {
				// Video is wider than the canvas
				sourceWidth = videoHeight * canvasAspectRatio;
				sourceHeight = videoHeight;
			} else {
				// Video is taller than the canvas
				sourceWidth = videoWidth;
				sourceHeight = videoWidth / canvasAspectRatio;
			}

			// Calculate source coordinates to center the cropped area
			const sourceX = (videoWidth - sourceWidth) / 2;
			const sourceY = (videoHeight - sourceHeight) / 2;

			// Create a canvas element with the same dimensions as the cropped area
			const canvas = document.createElement("canvas");

			canvas.width = sourceWidth;
			canvas.height = sourceHeight;

			const context = canvas.getContext("2d");

			// Draw the cropped video frame onto the canvas
			context.drawImage(
				webcam.video,
				sourceX, sourceY, sourceWidth, sourceHeight,
				0, 0, sourceWidth, sourceHeight,
			);

			// Convert the canvas content to a data URI in JPEG format
			const capturedImage = canvas.toDataURL("image/jpeg");

			// Set the captured image in the component's state
			this.setState({tmpCameraImage: capturedImage});

			// Return the captured image data URI
			return capturedImage;
		} else {
			throw new Error(errorMessages.canvas); // Throw an error if canvas or webcam.video is not available
		}
	}

	onUserMediaError(e) {
		this.setState({haveCameraPermission: false});
	}

	hideCamera() {
		this.setState({showCamera: false, tmpCameraImage: null, cameraRotation: 0});
	}

	deleteImgAndHideCamera(e, _id) {
		this.hideCamera();
		this.unregisterEvent(e, _id);
	}

	onDocumentLoadSuccess(e) {
		// console.info(e)
	}

	photoClickHandler(e, p, m) {
		p.isPhotoOnlySelectable && p.photoSelectedCb && p.photoSelectedCb(m.id);
		e.stopPropagation();
	}

	renderPreviw(m, p, i) {
		let warning = null;

		if (typeof (m.metadata.validate) !== "undefined" && ((typeof m.metadata.validate.back !== "undefined" && m.metadata.validate.back < 0.75) ||
        (typeof m.metadata.validate.front !== "undefined" && m.metadata.validate.front < 0.75))) {
			warning = "Il documento caricato non sembra quello richiesto: prova a ricaricarlo di nuovo o scatta una foto migliore";
		}

		if (typeof (m.metadata.validate) !== "undefined" &&
        typeof m.metadata.validate.isVehicle !== "undefined" && !m.metadata.validate.isVehicle.vehiclePresence) {
			warning = "Attenzione, la foto non sembra contenere un veicolo";
		}

		if (typeof (m.metadata.validate) !== "undefined" &&
        typeof m.metadata.validate.no_cai !== "undefined" && m.metadata.validate.no_cai > 0.75) {
			warning = "Attenzione, la foto non sembra contenere un documento CAI";
		} else if (typeof (m.metadata.validate) !== "undefined" &&
      ((typeof m.metadata.validate.back_cai !== "undefined" && m.metadata.validate.back_cai < 0.75) &&
      (typeof m.metadata.validate.front_cai !== "undefined" && m.metadata.validate.front_cai < 0.75))) {
			warning = "Attenzione, la foto non sembra contenere un documento CAI";
		}

		const vehiclePresenceWarning = (typeof (m.metadata.validate) !== "undefined" &&
      typeof m.metadata.validate.isVehicle !== "undefined" &&
      !m.metadata.validate.isVehicle.vehiclePresence);
		const classVehiclePresenceWarning = vehiclePresenceWarning ? "vehicle-presence-warning" : "";
		const isSelectedItem = (p.isPhotoOnlySelectable && p.photoSelectedIds && p.photoSelectedIds.length > 0 &&
            p.photoSelectedIds.indexOf(m.id) >= 0);
		const isSelectedClass = isSelectedItem ? "is-selected" : "";

		return (
			<div className={`up-li-element ${isSelectedClass}`} key={m.id + i}
				onClick={(e) => this.photoClickHandler(e, p, m)}>
				{warning && <div className={"validate-warning " + classVehiclePresenceWarning}><span>{warning}</span></div>}
				{m.metadata.validate &&
          m.metadata.validate.isVehicle &&
          m.metadata.validate.isVehicle.vehiclePresence &&
          <div className={"validate-correct " + classVehiclePresenceWarning}><span></span></div>}
				{p.settings.preview && <div className={`up-preview ${m.classes}`} onClick={() => !p.isPhotoOnlySelectable && this.togglePreview(m.id)}>
					{isSelectedItem && <div className="selected-item-icon"><i className="fas fa-check"></i></div>}
					{this.isPdf(m) ?
						<Document
							file={m.src}
							renderMode="svg"
							error="Impossibile generare l'anteprima"
							loading="Caricamento..."
							onLoadSuccess={this.onDocumentLoadSuccess.bind(this)}
						>
							<Page
								width={166}
								key={`page_${1}`}
								pageNumber={1}
							/>
						</Document> :
						<img className={`up-image ${m.classes}`} src={m.src} />
					}
				</div>}
				<div className="up-chip">
					<span className="up-namebtn">{m.name}</span>
					{!m.readOnly &&
						<span
							className="up-closebtn"
							onClick={(e) => this.unregisterEvent(e, m.id)}><i className={p.hasTrashIcon ? "fas fa-trash-alt" : "fas fa-times"}></i>
						</span>}
				</div>
			</div>
		);
	}

	togglePreview(id) {
		let isModalOpening = false;
		const metadata = this.state.metadata.map((meta) => {
			if (meta.id == id) {
				meta.preview = !meta.preview;

				if (meta.preview) {
					isModalOpening = true;
				}
			}

			return meta;
		});

		if (isModalOpening) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "";
		}

		this.setState({metadata: metadata});
	}

	renderFullPreview(m, p) {
		const isPdfClass = this.isPdf(m) ? "is-pdf" : "";

		return (
			<div className={"fullSreenPreview " + isPdfClass} key={`page_${1}`}>
				<div className={"close-btn-full-modal up-label-container"} onClick={() => this.togglePreview(m.id)}>
					<label className="up-label cursor-pointer">
						<span>&times;</span>
					</label>
				</div>
				{p && p.settings && p.settings.deleteEnabled &&
					<div className="hrms-popup-camera-footer">
						<div className="up-label-container cursor-pointer delete-icon-container bk-transparent" onClick={(e) => this.deleteImgAndHideCamera(e, m.id)}>
							<span className="svg-icon" dangerouslySetInnerHTML={{__html: images.delete}} />
						</div>
					</div>
				}

				{p.settings.preview ?
					<div className={"up-label-container cursor-pointer"} onClick={(e) => this.deleteImgAndHideCamera(null, m.id)}>
						<span className="svg-icon" dangerouslySetInnerHTML={{__html: images.delete}} />
					</div> : null
				}
				{this.isPdf(m) ?
					<Document
						file={m.src}
						renderMode="svg"
						error="Impossibile generare l'anteprima"
						loading="Caricamento..."
						onLoadSuccess={this.onDocumentLoadSuccess.bind(this)}
					>
						<Page
							key={`page_${1}`}
							pageNumber={1}
						/>
					</Document> :
					<div className="fullSreenPreviewImg"><img className="up-image" src={m.src} /></div>
				}
			</div>
		);
	}

	renderCamera() {
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		if (this.state.tmpCameraImage) {
			return (<img className="camera-screenshot" src={this.state.tmpCameraImage} />);
		} else {
			return (<Webcam
				audio={false}
				height={screenHeight}
				width={screenWidth}
				onUserMediaError={this.onUserMediaError}
				ref={(webcam) => {
					this.webcam = webcam;
				}}
				screenshotFormat="image/jpeg"
				screenshotQuality={1}
				videoConstraints={{
					facingMode: this.state.facingMode,
				}}
			/>);
		}
	}

	renderFooter(p, accept) {
		let loadFileBtn;

		if (p.settings && p.settings.showLoadFileInCamera) {
			loadFileBtn = <div className="up-label-container cursor-pointer">
				<label className="up-label cursor-pointer load-file-in-camera">
					<span>{p.label}</span>
					<input
						id={p.id + "-upload-button"}
						className="up-input"
						type={p.settings.type_import}
						accept={accept}
						onChange={(e) => this.registerEventAndCloseCamera(e, p)}
					/>
					<input id={p.id} ref={this.hiddenInput} type="hidden" />
				</label>
			</div>;
		}

		return (
			<>
				<div className="hrms-popup-camera-header">
					<div className="macro-container">
						<div className="up-label-container cursor-pointer" onClick={this.hideCamera}>
							<span className="fas fa-times" dangerouslySetInnerHTML={{__html: images.close}} />
						</div>
					</div>
				</div>
				<div className="hrms-popup-camera-footer">
					{loadFileBtn}
					<div className="macro-container">
						{this.state.tmpCameraImage && (p.settings && p.settings.imageRotationAvailable) && <div className="up-label-container cursor-pointer rotate-img"
							onClick={() => this.rotationClicked()}>
							<span className="fas fa-sync-alt" dangerouslySetInnerHTML={{__html: images.rotate}} />
							<div className="up-label">RUOTA</div>
						</div>}
						<div className="up-label-container cursor-pointer" onClick={this.state.tmpCameraImage ? this.deleteCameraPicture : this.captureCamera}>
							<span className="fas fa-camera" dangerouslySetInnerHTML={{__html: images.camera}} />
							{this.state.tmpCameraImage && (p.settings && p.settings.imageRotationAvailable) ? <div className="up-label">RIPROVA</div> : <div className="up-label" style={{minHeight: "35px", visibility: "hidden"}}></div>}
						</div>
						{this.state.tmpCameraImage && <div className="up-label-container cursor-pointer" onClick={() => this.uploadCameraPicture(p)}>
							<span className="fas fa-check" dangerouslySetInnerHTML={{__html: images.check}} />
							<div className="up-label">CONFERMA</div>
						</div>}
					</div>
				</div>
			</>
		);
	}

	renderModal(p, accept) {
		let loadFileBtn;
		let warningTextLoadFileInfo;

		if (p.settings && p.settings.showLoadFileInCamera) {
			warningTextLoadFileInfo = " Puoi anche caricare documenti cliccando sul bottone sottostante.";
			loadFileBtn = <div className="up-label-container cursor-pointer load-file-warning-container">
				<label className="up-label cursor-pointer load-file-in-camera">
					<span>{p.label}</span>
					<input
						id={p.id + "-upload-button"}
						className="up-input"
						type={p.settings.type_import}
						accept={accept}
						onChange={(e) => this.registerEventAndCloseCamera(e, p)}
					/>
					<input id={p.id} ref={this.hiddenInput} type="hidden" />
				</label>
			</div>;
		}

		let roteteStyle = {};
		let roteteImgBkgd = "";

		if (this.state.cameraRotation !== 0) {
			roteteStyle = {
				transform: "rotate(" + this.state.cameraRotation + "deg)",
				transformOrigin: "50% 50%",
			};
			roteteImgBkgd = "rotate-img-background";
		}

		return (
			<div id="popup" className="overlay-camera">
				<div className="l-flex-parent">
					<div className={"hrms-popup-camera " + roteteImgBkgd}>
						{this.state.haveCameraPermission ?
							<div className={this.state.classCamera}
								style={roteteStyle} >
								{this.state.haveCameraPermission ? this.renderCamera() : null}
							</div> :
							<div>
								<div className={this.state.classWarning || "hrms-popup-camera-warning"}>
									<div id="hrms-warning-wrapper" className="hrms-warning-wrapper fix-content-message">
                                  La tua webcam sembra disabilitata, per favore controlla se il tuo browser supporta l&apos;autorizzazione di accesso alla cam.{warningTextLoadFileInfo}
									</div>
									<div className="up-label-container cursor-pointer" onClick={this.hideCamera} style={{marginLeft: "10px"}}>
										<span className="svg-icon" dangerouslySetInnerHTML={{__html: images.close}} />
									</div>
								</div>
								{loadFileBtn}
							</div>
						}
						{this.state.haveCameraPermission && this.renderFooter(p, accept)}
					</div>
				</div>
			</div>
		);
	}

	renderOnlyOneImageModal(_id) {
		const m = this.state.metadata && this.state.metadata.length > 0 && this.state.metadata[0];

		const vehicleNotPresentWarning = m && m.metadata && m.metadata.validate && m.metadata.validate.isVehicle && (m.metadata.validate.isVehicle.vehiclePresence === false);
		const warningVeichleNotPresentCmp = vehicleNotPresentWarning ? <div className="veichle-presence-warning">
			<span>Attenzione.</span> La foto non sembra contenere un veicolo</div> : null;
		const vitrSessionAuthToken = sessionStorage.getItem("Vitr-Session");

		return (
			<div id="popup-only-image" className="overlay-camera">
				{warningVeichleNotPresentCmp}
				{
					<div className="hrms-popup-camera">
						<div className={this.state.classCamera}>

							{this.isPdf(m) ?
								<Document
									file={m.src}
									renderMode="svg"
									error="Impossibile generare l'anteprima"
									loading="Caricamento..."
									onLoadSuccess={this.onDocumentLoadSuccess.bind(this)}
								>
									<Page
										width={166}
										key={`page_${1}`}
										pageNumber={1}
									/>
								</Document> :
								<img className="camera-screenshot" src={process.env.REACT_APP_BACKEND_BASEURL + "/api/" + window._TOKEN_ + "/download-attachment/" + _id + "?vitr_session=" + vitrSessionAuthToken} />
							}

							{/* <img className="camera-screenshot" src={'/api/' + window._TOKEN_ + "/download-attachment/" + _id} /> */}
						</div>
						<div className="hrms-popup-camera-footer">
							<div className="macro-container">
								<div className="up-label-container cursor-pointer" onClick={this.hideCamera}>
									<span className="svg-icon" dangerouslySetInnerHTML={{__html: images.close}} />
								</div>
								<div className="up-label-container cursor-pointer delete-icon-container" onClick={(e) => this.deleteImgAndHideCamera(e, _id)}>
									<span className="svg-icon" dangerouslySetInnerHTML={{__html: images.delete}} />
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}

	renderButtons() {
		const p = this.props;
		const disableButtonClass = this.state.loadingUploadImage ? "btn-disabled" : "";
		const firstImg = this.state.metadata && this.state.metadata.length > 0 && this.state.metadata[0];
		const accept = p.settings.accept.join();

		return (
			<div className="up-label-wrapper clearfix">
				<div className={disableButtonClass + " " + p.classes + " up-label-container load-file-btn-container " + (firstImg ? "at-least-one-image-present" : "")}>
					<label className="up-label cursor-pointer">
						<span>{p.hasCustomLabel && p?.value.length < p.settings.limitnumber && p?.value.length !== 0 ? p.addLabel : p.label}</span>
						<input
							id={p.id + "-upload-button"}
							className="up-input"
							type={p.settings.type_import}
							accept={accept}
							disabled={this.state.loadingUploadImage}
							onChange={(e) => this.registerEvent(e, p)}
						/>
						<input id={p.id} ref={this.hiddenInput} type="hidden" />
					</label>
				</div>

				{this.state.scattaButton && <div className={disableButtonClass + " " + p.classes + " up-label-container click-btn-container " + (firstImg ? "at-least-one-image-present" : "")}>
					<label className="up-label cursor-pointer" onClick={() => !this.state.loadingUploadImage ? this.showCamera() : null}>
						<span>{p.hasCustomLabel ? "Scatta foto" : "SCATTA"}</span>
					</label>
				</div>}
			</div>
		);
	}

	render() {
		const p = this.props;
		const accept = p.settings.accept.join();
		const previewList = (p.settings && p.settings.preview_order && p.settings.preview_order === "desc") ? this.state.metadata.reverse() : this.state.metadata;
		const firstImg = previewList && previewList.length > 0 && previewList[0];
		const loaderCmp = <div className="circle-loader">
			<div className="checkmark draw"></div>
		</div>;

		return (
			<div className={`up-global-container ${p.classes}`} id={p.id} >
				{(p.settings?.button_position === "top" && !(p.hideButtonsOnLimit && p?.value.length === p.settings.limitnumber)) ? this.renderButtons() :
					null}
				{this.state.metadata.length > 0 && p.settings && p.settings.preview_title && (<h6 className="hrms-preview-head">{p.settings.preview_title}</h6>)}
				{this.state.metadata.length ?
					<div className="hrms-section up-info-check"><br />
						{p.settings.preview_desc ? p.settings.preview_desc : "Ecco quello che hai caricato, verifica che l’immagine sia chiara e a fuoco."}
					</div> :
					null}
				<div className="up-li-container clearfix">
					{previewList.map((m, i) => this.renderPreviw(m, p, i))}
					{previewList.map((m, i) => {
						return m.preview ? this.renderFullPreview(m, p) : null;
					})}
				</div>
				{this.state.loadingUploadImage ? <div className="validate-loading">{loaderCmp}</div> : null}

				{(this.props.validation?.hasAtLeastOneErrorMessage && this.props.validation.result) && <div className="up-label-errors clearfix">
					<ul>
						{Object.keys(this.props.validation.result).map((errorKey) => {
							if (!this.props.validation.result[errorKey].isValid) {
								return (<li key={errorKey}>{this.props.validation.result[errorKey].message}</li>);
							}
						})}
						{this.state.uploadErrors && this.state.uploadErrors.map((error, errorIndex) => {
							return (<li key={"upload-" + errorIndex}>{error}</li>);
						})}
					</ul>
				</div>}
				{/* Display frontend errors */}
				<div className="up-label-errors clearfix">
					<ul>
						{this.state.beforeUploadErrors && this.state.beforeUploadErrors.map((error, errorIndex) => {
							return (<li key={"upload-" + errorIndex}>{error}</li>);
						})}
					</ul>
				</div>
				{(p.settings?.button_position !== "top" && !(p.hideButtonsOnLimit && p?.value.length === p.settings.limitnumber)) ?
					this.renderButtons() :
					null}
				{(this.state.showCamera && ((firstImg && p.settings.renderOnlyOneImage) ? (this.renderOnlyOneImageModal(firstImg.id)) : (this.renderModal(p, accept))))}
			</div >
		);
	}
}

export default withRouter(Uploader);
