import React from "react";

const Link = (props) => {
	const classes = props.classes ? props.classes : "";

	return <div>
		<a className={"hrms-link " + classes} href={props.value}>{props.label}</a>
	</div>;
};

export default Link;
