import React from "react";

const Popup = (props) => {
	const {settings} = props || {};
	const id = {settings} || {id: "popup"};

	return (
		<div id={id} className="overlay">
			<div className="hrms-popup">
				<div className="content">
					{props.widgets ? props.widgets.map((widget) => props.composeWidget(widget)) : null}
				</div>
			</div>
		</div>
	);
};

export default Popup;
