import React, {useContext} from "react";
import Markdown from "markdown-to-jsx";
import {AppContext} from "./../context/AppContext";
import DOMPurify from "dompurify";
import conversions from "./../utils/custom_conversions";

const ShareBtn = (props) => {
	const {appState} = useContext(AppContext);
	let loaderCmp = null;
	let loadingVisibleClass = "";
	let {url: _url, title: _title, text: _text} = props.settings;

	const executeCustomConversions = (txtIn) => {
		let txtOut = txtIn;

		conversions.conversionFunctionsName.forEach((fName) => {
			if (txtIn && txtIn.indexOf("$" + fName) >= 0) {
				txtOut = conversions[fName](txtOut, props.configValues);
			}
		});

		return txtOut;
	};

	_text = executeCustomConversions(_text);

	const executeClickListeners = (isFirstInitialization) => {
		const changeEvent = props.events && props.events.find((ev) => {
			return (ev.listener === "click");
		});

		if (changeEvent) {
			const domevent = {target: {value: "", id: props.id}};

			props.assignEvents(domevent, changeEvent, props, isFirstInitialization, appState);
		}
	};

	const executeShare = () => {
		if (navigator.share) {
			navigator.share({
				title: _title,
				text: _text,
				url: _url,
			}).then(() => {
				executeClickListeners(null);
			}).catch(() => {
				console.log("Inoltro annullato");
			});
		} else {
			props.setModalIsOpen(true);
			props.setModalMessage("Il browser non supporta la funzionalità richiesta. Si prega di provvedere con un inoltro manuale dell'SMS");
		}
	};

	const onClickHandler = (e) => {
		executeShare();
	};

	if (props.settings && props.settings.isLoadingEnabled && appState.isLoading) {
		loaderCmp = <div className="circle-loader">
			<div className="checkmark draw"></div>
		</div>;
		loadingVisibleClass = "loading-visible";
	}

	let imageIcon = null;

	if (typeof appState.images[props.icon] !== "undefined") {
		imageIcon = <img className={"image " + props.icon} src={appState.images[props.icon]} />;

		if (appState.images[props.icon] && appState.images[props.icon].startsWith("<svg")) {
			imageIcon = appState.images[props.icon];
		} else if (props.icon.includes("fas ")) {
			imageIcon = <i className={props.icon}></i>;
		}
	}

	const _label = props.markdown ? <Markdown>{DOMPurify.sanitize(props.markdown)}</Markdown> : props.label;

	return (
		<span className="btn-ext-container">
			<button id={props.id}
				className={props.type + " " + props.classes + " " + loadingVisibleClass}
				style={props.style}
				disabled={props.disabled}
				onClick={onClickHandler}>
				<div className="indents">
					{loaderCmp}
					{imageIcon}
					{_label && <span className="label">{_label}</span>}
				</div>
			</button>
		</span>
	);
};

export default ShareBtn;
