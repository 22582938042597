import {useEffect, useState} from "react";

const useDebounce = (val, offset = 600) => {
	const [dobouncedValue, setDobouncedValue] = useState(val);

	useEffect(() => {
		const timeutRef = setTimeout(() => {
			setDobouncedValue(val);
		}, offset);

		return () => {
			clearTimeout(timeutRef);
		};
	}, [val]);

	return dobouncedValue;
};

export default useDebounce;
