import React, {useState, useEffect, useContext} from "react";
import Markdown from "markdown-to-jsx";
import {AppContext} from "./../context/AppContext";
import DOMPurify from "dompurify";

const Btn = (props) => {
	const [_isMounted, set_isMounted] = useState(false);
	const [counterDown, setCounterDown] = useState(null);
	const [timeoutCountdown, setTimeoutCountdown] = useState(null);
	const [timeoutClickListener, setTimeoutClickListener] = useState(null);

	const {appState} = useContext(AppContext);

	useEffect(() => {
		// Did mount
		set_isMounted(true);
		props.triggerCheckScrolldown(props);

		if (props.settings) {
			if (props.settings.hasCountdown && props.settings.nCountdown && props.settings.nCountdown > 0) {
				setCounterDown(props.settings.nCountdown);
			}
		}

		// Unmount
		return () => {
			set_isMounted(false);
			timeoutCountdown && clearTimeout(timeoutCountdown);
			timeoutClickListener && clearTimeout(timeoutClickListener);
		};
	}, []);

	const executeClickListeners = (isFirstInitialization) => {
		const changeEvent = props.events && props.events.find((ev) => {
			return (ev.listener === "click");
		});

		if (changeEvent) {
			const domevent = {target: {value: "", id: props.id}};

			props.assignEvents(domevent, changeEvent, props, isFirstInitialization, appState);
		}

		if (props.settings && props.settings.computeStaticConditions === true) {
			props.computeStaticConditions(props.data);
		}
	};

	const onClickHandler = (e) => {
		executeClickListeners(null);
	};

	useEffect(() => {
		if (counterDown === null) {
			return;
		}

		if (counterDown > 0) {
			const tmpTimeoutHandleCountdown = setTimeout(() => {
				if (_isMounted) {
					setCounterDown(counterDown - 1000);
				}
			}, 1000);

			setTimeoutCountdown(tmpTimeoutHandleCountdown);
		} else {
			const tmpTimeoutClickListeners = setTimeout(() => {
				if (props.settings && props.settings.triggerEvents === "self") {
					executeClickListeners(null);
				}
			}, 1000);

			setTimeoutClickListener(tmpTimeoutClickListeners);
		}
	}, [counterDown]);

	let count = null;
	let loaderCmp = null;
	let loadingVisibleClass = "";

	if (props.settings && props.settings.isLoadingEnabled && appState.isLoading) {
		loaderCmp = <div className="circle-loader">
			<div className="checkmark draw"></div>
		</div>;
		loadingVisibleClass = "loading-visible";
	}

	if ((props.settings && props.settings.showCountDownNumbers) && (counterDown !== null)) {
		const c = counterDown / 1000;

		count = " (" + c + ")";
	}

	let imageIcon = null;

	if (props.icon) {
		const icon = appState.images[props.icon];

		if (icon && typeof icon !== "undefined") {
			if (icon.startsWith("<svg")) {
				imageIcon = icon;
			} else {
				imageIcon = <img className={"image " + props.icon} src={icon} />;
			}
		} else if (props.icon.includes("fas ")) {
			imageIcon = <i className={props.icon}></i>;
		}
	}

	const _label = props.markdown ? <Markdown>{DOMPurify.sanitize(props.markdown)}</Markdown> : props.label;

	const disabled = props.disabled? props.disabled : false;

	let Btn = <button id={props.id} className={props.type + " " + props.classes + " " + loadingVisibleClass} style={props.style} disabled={props.disabled}
		onClick={onClickHandler}>
		<div className="indents">
			{loaderCmp}
			{props.iconPosition !== "right" && imageIcon}
			{_label && <span className="label">{_label}{count}</span>}
			{props.iconPosition === "right" && imageIcon}
		</div>
	</button>;

	if (props.download && !disabled) {
		Btn = <a disabled={disabled} href={props.download.url + "?vitr_session=" + props.tk_download} target="_blank" rel="noreferrer">{Btn}</a>;
	}

	if (props.href && !disabled) {
		Btn = <a disabled={disabled} href={props.href}>{Btn}</a>;
	}

	return (
		<span className="btn-ext-container">
			{Btn}
		</span>
	);
};

export default Btn;
