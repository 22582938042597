import React, {Component} from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import L from "leaflet";
import LF from "leaflet-geometryutil";
import {Marker, Map} from "react-leaflet";
import "leaflet-providers";
import axios from "axios";

class MapAddressFinder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			targetPosition: null,
			address: props.value[2],
			suggestions: [],
			initAddress: null,
			centerMap: null,
			timeout: null,
			proximityWarning: false,
		};
		this.dragEndHandler = this.dragEndHandler.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.hideWarning = this.hideWarning.bind(this);
		this.submitPosition = this.submitPosition.bind(this);
		this.selectedPosition = this.selectedPosition.bind(this);
		this.initialMap = [props.value[0], props.value[1], props.value[2]];
		this.geoSearchProvider = null;
	}

	componentDidMount() {
		const p = this.props;
		const map = this.refs.map.leafletElement; // TODO: fix Using this.refs is deprecated

		// eslint-disable-next-line
    new L.tileLayer.provider("HERE.terrainDay", {
			app_id: p.configValues.app_id,
			app_code: p.configValues.app_code,
			maxZoom: 20,
		}).addTo(map);
		this.setState({
			initAddress: p.value[2],
			targetPosition: {lat: this.initialMap[0], lng: this.initialMap[1]},
			centerMap: {lat: this.initialMap[0], lng: this.initialMap[1]},
		});
	}
	// se digito il testo mi restituisce una serie di suggerimenti possibili
	handleChange(event) {
		const {timeout} = this.state;
		const props = this.props;
		const proximity = props.value[0] + "," + props.value[1] + "," + props.settings.maxDistance;

		clearTimeout(timeout);
		this.setState({address: event.target.value}, () => {
			this.setTemplateAddressLabel(props.id, this.state.targetPosition.lat, this.state.targetPosition.lng, this.state.address);

			if (this.state.address) {
				const elaborateQuery = this.state.address.replace(" ", "+");

				this.setState({
					timeout: setTimeout(() => {
						axios({
							url: ("https://autocomplete.geocoder.api.here.com/6.2/suggest.json?app_id=" +
              props.configValues.app_id +
              "&app_code=" +
              props.configValues.app_code +
              "&query=" +
              elaborateQuery +
              "&maxresults=10&prox=" + proximity),
							method: "get",
						}).then((response) => {
							const sugg = response.data.suggestions;
							const elencoSugg = [];

							sugg.forEach((res) => {
								if (res.distance < props.settings.maxDistance) {
									res.elaboratedLabel = this.hanldeAddress(res.address, "geosearch");
									elencoSugg.push(res);
								}
							});
							this.setState({suggestions: elencoSugg});
						}).catch((error) => {
							// console.log(error);
						});
					}, 500),
				});
			} else {
				this.setState({suggestions: []});
			}
		});
		this.setState({proximityWarning: true});
	}

	hideWarning() {
		this.setState({proximityWarning: false});
	}

	/*
        TODO: RECUPERARE IL PRIMO SUGGEST CHE VIENE FUORI.
    */

	getLatLonFromJsonP(jsonPStrRespose) {
		// Remove first 'H.service.jsonp.handleResponse(6)(' string and last ')' char
		let cleandeStr = jsonPStrRespose.replace("H.service.jsonp.handleResponse(6)(", "");

		cleandeStr = cleandeStr.slice(0, -1);

		const jResponse = JSON.parse(cleandeStr);
		const viewRespObj = jResponse && jResponse.Response && jResponse.Response.View && (jResponse.Response.View.length > 0) &&
            jResponse.Response.View[0];
		const firstResObj = viewRespObj && viewRespObj.Result && (viewRespObj.Result.length > 0) && viewRespObj.Result[0];
		const locationObj = firstResObj && firstResObj.Location;

		return {
			Latitude: locationObj && locationObj.DisplayPosition && locationObj.DisplayPosition.Latitude,
			Longitude: locationObj && locationObj.DisplayPosition && locationObj.DisplayPosition.Longitude,
			AddressLabel: locationObj && locationObj.Address && locationObj.Address.Label,
		};
	}

	submitPosition(event) {
		event.preventDefault();
		this.selectedPosition(this.state.suggestions && this.state.suggestions[0]);
	}

	setTemplateAddressLabel(_id, _latitude, _longitude, _address) {
		const action = {target: null, value: null};
		const json = {id: _id, domevent: {target: {value: [_latitude, _longitude, _address]}}};

		this.props.appendValue(json, action);
	}

	selectedPosition(suggestion, userSelectedSuggestion) {
		const p = this.props;

		// If user has clicked enter and there were not suggestions, then update only the label (the map will not move)
		if (!userSelectedSuggestion && !suggestion) {
			this.setState({
				suggestions: [],
			}, () => {
				if (!this.props.testUT) {
					this.refs.map.leafletElement.setView({lat: this.state.targetPosition.lat, lng: this.state.targetPosition.lng}); // TODO: fix Using this.refs is deprecated
					this.setTemplateAddressLabel(p.id, this.state.targetPosition.lat, this.state.targetPosition.lng, this.state.address);
				}
			});
		} else { // if a suggestion was present, then execute reverse geocoding
			axios({
				url:
          "https://geocoder.api.here.com/6.2/geocode.json?xnlp=CL_JSMv3.0.17.0&app_id=" +
          p.configValues.app_id +
          "&app_code=" +
          p.configValues.app_code +
          "&locationId=" +
          suggestion.locationId +
          "&jsoncallback=H.service.jsonp.handleResponse(6)",
				method: "get",
			}).then((response) => {
				const {Latitude, Longitude, AddressLabel} = this.getLatLonFromJsonP(response.data);

				// If the user has clicked Enter and there were a suggestion (or if the user selected the suggestion), the label will be set to what the user typed
				// the lat-lon will be set to the reverse-geocoding value and the map will go to the first suggestion
				const addressLabel = userSelectedSuggestion ? AddressLabel : this.state.address;

				this.setState({
					suggestions: [],
					targetPosition: {lat: Latitude, lng: Longitude},
					address: addressLabel,
					// address: suggestion.elaboratedLabel                                   manteniamo l'indirizzo digitato dall'utente
				}, () => {
					this.refs.map.leafletElement.setView({lat: Latitude, lng: Longitude}); // TODO: fix Using this.refs is deprecated
					this.setTemplateAddressLabel(p.id, Latitude, Longitude, addressLabel);
				});
			}).catch((error) => {
				// console.log(error);
			});
		}
	}

	hanldeAddress(addr, state) {
		/* let way = null;
        let city = null;
        Object.keys(addr).forEach(field => {
            if (highway.includes(field))
                way = field;
        });
        Object.keys(addr).forEach(field => {
            if (place.includes(field))
                city = field;
        });
        let addr_n = addr.house_number ? " " + addr.house_number : "";
        if (addr.postcode === "undefined") {
            addr.postcode = "";
        }*/
		if (state === "reverse") {
			let organizeAddress = addr.Street + " " + addr.HouseNumber + ", " + addr.PostalCode + " " + addr.City + " " + addr.County;

			if (addr.Street === "undefined") {
				organizeAddress = addr.PostalCode + " " + addr.City + " " + addr.County;
			}

			return organizeAddress;
		}

		;

		if (state === "geosearch") {
			let organizeAddress = addr.street + " " + addr.houseNumber + ", " + addr.postalCode + " " + addr.city + " " + addr.county;

			if (!addr.houseNumber) {
				organizeAddress = addr.street + " " + addr.postalCode + " " + addr.city + " " + addr.county;
			}

			return organizeAddress;
		}

		;
	}

	dragEndHandler(e) {
		const p = this.props;

		if (e.target.getCenter() !== this.initialMap[2]) {
			const targetPosition = e.target.getCenter();

			this.setState({
				targetPosition: {lat: e.target.getCenter().lat, lng: e.target.getCenter().lng},
			}, () => {
				const _distance = LF.distance(this.refs.map.leafletElement, this.state.targetPosition, this.state.centerMap); // TODO: fix Using this.refs is deprecated

				if (_distance <= p.settings.maxDistance) {
					axios({
						url: ("https://reverse.geocoder.api.here.com/6.2/reversegeocode.json?app_id=" +
            p.configValues.app_id +
            "&app_code=" +
            p.configValues.app_code +
            "&prox=" +
            targetPosition.lat +
            "," + targetPosition.lng +
            ",50&mode=retrieveAddresses&pos=" +
            targetPosition.lat +
            "," + targetPosition.lng),
						method: "get",
					}).then((response) => {
						const probablyAddress = response.data.Response.View[0].Result[0].Location.Address;
						// let organizeAddress = this.hanldeAddress(probablyAddress, "reverse");
						const organizeAddress = probablyAddress.Label;

						this.setState({address: organizeAddress}, () => {
							const action = {target: null, value: null};
							const json = {id: p.id, domevent: {target: {value: [targetPosition.lat, targetPosition.lng, organizeAddress]}}};

							this.props.appendValue(json, action);
						});
					}).catch((error) => {
						// console.log(error);
					});
				} else {
					this.resetPosition();
				}
			});
		}
	}

	resetPosition() {
		const p = this.props;

		this.setState({
			suggestions: [],
			targetPosition: {lat: this.initialMap[0], lng: this.initialMap[1]},
			address: this.state.initAddress,
		}, () => {
			this.refs.map.leafletElement.setView({lat: this.initialMap[0], lng: this.initialMap[1]}, p.zoom); // TODO: fix Using this.refs is deprecated
		});
	}

	onEnterPress = (e) => {
		if (e.keyCode == 13 && e.shiftKey == false) {
			e.preventDefault();
			this.submitPosition(e);
		}
	};

	render() {
		const p = this.props;
		const marker = p.propagatedAppState && p.propagatedAppState.images && p.propagatedAppState.images.marker;
		let labelMap = null;
		const mapstyle = p.style;
		const center = {lat: this.initialMap[0], lng: this.initialMap[1]};
		const iconCrash = new L.Icon({
			iconUrl: marker,
			iconSize: new L.Point(60, 60),
			className: "div-icon",
			iconAnchor: [30, 55],
			popupAnchor: [0, -60],
		});
		let touchZoom = true;
		let scrollWheelZoom = true;
		let doubleClickZoom = true;
		let dragging = true;
		let resumeButton = <div className="buttonMap" onClick={(e) => {
			this.refs.map.leafletElement && this.resetPosition(e); // TODO: fix Using this.refs is deprecated
		}}>x</div>;
		let fakeMarker = null;
		let formSearch = null;
		let markerMap = null;

		if (p.settings) {
			if (p.settings.fixedMarkerDropMap === true) {
				fakeMarker = <img className="marker-map-centered" src={marker} />;
			}

			if (p.settings.withSearch === true) {
				formSearch = <form onSubmit={this.submitPosition}>
					<div className="form-class">
						<textarea onKeyDown={this.onEnterPress} type="text" placeholder="Inserisci un indirizzo" value={this.state.address}
							onChange={this.handleChange} />
						{this.state.address.length ? <div className="suggest-list">
							{this.state.suggestions.map((suggestion, i) =>
								<div className="text-suggestion" key={i} onClick={() => this.selectedPosition(suggestion, true)}>{suggestion.elaboratedLabel}</div>,
							)}
						</div> : null}
					</div>
					{p.settings && p.settings.hasProximityWarning && this.state.proximityWarning && <div className='proximity-warning-container' onClick={this.hideWarning}>
						<div className='little-cube'></div>
						<i className="fa">&#xf00d;</i>
						<div>
							<i className="fa">&#xf06a;</i>
							<p>Puoi cercare un indirizzo solo in prossimità del luogo rilevato.</p>
						</div>
					</div>}
				</form>;
			}

			if (p.settings.displayOnly === true) {
				labelMap = <div className="label-map">L&apos;incidente risulta avvenuto nei pressi di <strong>{this.initialMap[2]}</strong>?</div>;
				touchZoom = false;
				doubleClickZoom = false;
				scrollWheelZoom = false;
				dragging = false;
				resumeButton = null;
				markerMap = <Marker position={center} icon={iconCrash}></Marker>;
			}
		}

		const componentMap = <div className={p.settings.displayOnly ? "parent-stop-events" : "parent-map"}>
			<Map
				touchZoom={touchZoom} scrollWheelZoom={scrollWheelZoom} dragging={dragging}
				doubleClickZoom={doubleClickZoom} zoomControl={false} attributionControl={false}
				style={mapstyle} center={center} zoom={p.zoom} maxZoom={p.settings.maxZoom} minZoom={p.settings.minZoom}
				onDragEnd={(e) => this.dragEndHandler(e)}
				onZoomEnd={(e) => this.dragEndHandler(e)}
				ref='map'>
				{markerMap}
			</Map>
		</div>;

		return (
			<>
				{p.configValues && <div className={p.type + " " + p.classes} style={p.style}>
					{labelMap}
					{fakeMarker}
					{resumeButton}
					{formSearch}
					{componentMap}
				</div>}
			</>
		);
	}
}

export default MapAddressFinder;
