import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";

class Breadcrumb extends Component {
	constructor(props) {
		super(props);

		this.handleGoBack = this.handleGoBack.bind(this);
	};

	// Pass breadcrumb item indexes (array of indexes) and verify if all corresponding steps are disabled
	areRoutesDisabled(_steps, _breadIds) {
		const enabledIndex = _breadIds.findIndex((brId) => {
			return _steps[brId].disabled === false;
		});

		// Return true if at least one enabled step has been found disabled step has been found
		return enabledIndex === -1;
	}

	// Add 'enabled' to <li> if the next secion-step (based on doneIfCurrentBreadCrumbIs json array values) is active
	isPreviouslyCompleted(_currentBreadCrumbStep, _currentBreadCrumbJsonElement) {
		return (_currentBreadCrumbStep &&
      _currentBreadCrumbJsonElement.doneIfCurrentBreadCrumbIs &&
      _currentBreadCrumbJsonElement.doneIfCurrentBreadCrumbIs.indexOf(_currentBreadCrumbStep.breadcrumbId) >= 0);
	}

	handleGoBack() {
		this.props.goBack(this.props);
	}

	isElementAfterCurrentActiveBreadcrumbItem(breadcrumbList, el, currentBreadCrumbStep) {
		let indexEl; let indexCurrentBreadCrumbStep;

		if (!breadcrumbList || !el || !currentBreadCrumbStep) {
			return;
		}

		breadcrumbList.forEach((bi, index) => {
			if (bi.breadcrumbId == el.breadcrumbId) {
				indexEl = index;
			}

			if (bi.breadcrumbId == currentBreadCrumbStep.breadcrumbId) {
				indexCurrentBreadCrumbStep = index;
			}
		});

		return indexCurrentBreadCrumbStep >= indexEl;
	}

	renderBreadcrumb(data, route, currentBreadCrumbStep) {
		const list = [];
		const routeStr = route.toString();

		if ((typeof data.breadcrumb !== "undefined") && (typeof data.breadcrumb.show === "undefined" || data.breadcrumb.show === true)) {
			data.breadcrumb.list.forEach((el, index, arr) => {
				const nextPageId = (el.landingId || el.id[0]);
				const conditionalLink = <li key={index}
					className={"link br br-" + nextPageId + " " +
                        (el.id.includes(routeStr) ? "enabled" : "") +
                        (this.areRoutesDisabled(data.steps, el.id) ? " not-active" : "") +
                        ((this.isPreviouslyCompleted(currentBreadCrumbStep, el) && !el.disabled) ? " enabled" : "")}>
					{
						(((this.isElementAfterCurrentActiveBreadcrumbItem(arr, el, currentBreadCrumbStep) &&
                            !(this.areRoutesDisabled(data.steps, el.id))) ||
                            (this.isPreviouslyCompleted(currentBreadCrumbStep, el))) &&
                            !(el.disabled)) ? (
								<Link to={"/" + window._TOKEN_ + "/" + nextPageId}
									className={"text " + (el.id.includes(routeStr) ? "enabled" : "underline")}>
									{el.title}
								</Link>
							) : (
								<a className={"text " + (el.id.includes(routeStr) ? "enabled" : "")}>
									{el.title}
								</a>
							)}
				</li>;

				list.push(
					conditionalLink,
				);

				const stepperSeparator = (<span key={index + arr.length} className={"br-separator" +
                    (this.isPreviouslyCompleted(currentBreadCrumbStep, el) ? " enabled" : "")}></span>);

				list.push(stepperSeparator);
			});
		}

		return list;
	}

	backButtonVisible(data, route) {
		const routeStr = route.toString();

		return (data.backComponent && data.backComponent.enabled && (data.backComponent.stepsList.includes(routeStr)));
	}

	render() {
		const p = this.props;

		if (!p.data || typeof p.data.breadcrumb === "undefined") {
			return null;
		}

		// Identify in which breadcrumb step are we. If no step is found, breadcrumb disappears
		let sbt; let stepFound;
		const currentRouteId = p.actualRoute;

		if (currentRouteId !== undefined && currentRouteId !== null) {
			stepFound = p.data.breadcrumb.list.find((b) => {
				return (b.id.indexOf(currentRouteId.toString()) >= 0);
			});

			sbt = stepFound && stepFound.subtitle;
		}

		// back button
		const btnVisible = this.backButtonVisible(p.data, currentRouteId);
		const backBtn = (p.propagatedAppState.routeNavigated && btnVisible) ?
			<div className="l-flex-parent breadcrumb-parent"><nav className="hrms-breadcrumb back-content">
				<div className="breadcrumbs">
				</div><div className="back-label"
					onClick={this.handleGoBack}><span className="arrow-icon">&#8592;</span>Indietro</div>
			</nav>
			</div> : null;

		const BreadCrumb = this.renderBreadcrumb(p.data, currentRouteId, stepFound);
		const breadcrumbComponent = stepFound ? (
			p.data.breadcrumb.enabled && <div className="l-flex-parent breadcrumb-parent"><nav className="hrms-breadcrumb">
				<div className="breadcrumbs">
					{BreadCrumb}
				</div>
				<p className="sub-title">{sbt}</p>
			</nav></div>
		) : false;

		return <React.Fragment>
			{backBtn}
			{breadcrumbComponent}
		</React.Fragment>;
	}
}

;

export default withRouter(Breadcrumb);
