const images = {
	"logo": require("./logo.png"),
	"pdf": require("./pdf.png"),
	"applestore": require("./applestore.png"),
	"googleplay": require("./googleplay.png"),
	"info": require("./info.png"),
	"unipol_sai_logo_white": require("./unipol-sai-logo-white.png"),
};

export default images;
