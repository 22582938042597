import React, {useContext} from "react";
import moment from "moment";
import Markdown from "markdown-to-jsx";
import {AppContext} from "./../context/AppContext";
import DOMPurify from "dompurify";

const getAppUrl = () => {
	const origin = window.location.origin;
	const appUrlOutput = `${origin}/${window._TOKEN_}`;

	return appUrlOutput;
};

const SmsPreview = (props) => {
	const settings = props.settings;
	const sender = settings && settings.sender;
	const {appState} = useContext(AppContext);

	const executeClickListeners = (smsItem) => {
		const isFirstInitialization = false;
		const changeEvent = smsItem.events && smsItem.events.find((ev) => {
			return (ev.listener === "click");
		});

		if (changeEvent) {
			const domevent = {target: {value: "", id: props.id}};

			props.assignEvents(domevent, changeEvent, props, isFirstInitialization, appState);
		}
	};

	const onClickHandler = (smsItem) => {
		executeClickListeners(smsItem);
	};

	return (
		<>
			<div className={props.type + " " + props.classes} style={props.style}>
				<div className="sms-header">
					<div className="left-side">
						<i className="fas fa-arrow-up h-icon back-icon"></i>
						<div className="sender-txt">{sender}</div>
					</div>
					<div className="right-side">
						<i className="fas fa-phone h-icon"></i>
						<i className="fas fa-ellipsis-v h-icon"></i>
					</div>
				</div>
				<div className="sms-body">
					<div className="messages-content">
						{
							settings && settings.smsItems && settings.smsItems.map((smsItem, index) => {
								return (
									<div className="message-item" key={index}>
										<p className="date-container">{smsItem.receptionDate || new moment().format("DD-MM-YYYY")}</p>
										<div className="message-box">
											<Markdown>{DOMPurify.sanitize(smsItem.markdown)}</Markdown>
											<span onClick={() => onClickHandler(smsItem)}
												className="link-to-app"> {getAppUrl()}</span>
										</div>
										<p className="sms-time">{smsItem.receptionTime || new moment().format("HH.mm")}</p>
									</div>
								);
							})
						}
					</div>
				</div>
				<div className="sms-footer">
					<i className="fas fa-paperclip f-icon"></i>
					<input className="sms-input" placeholder="Inserisci il testo" />
					<i className="fas fa-arrow-circle-right f-icon"></i>
				</div>
			</div>
		</>
	);
};

export default SmsPreview;
