import React, {useContext} from "react";
import {BrowserRouter, Switch} from "react-router-dom";
import {Breadcrumb} from "../utils/imports";
import Header from "./../components/header";

import ScrollToTop from "./scrolltotop";
import {AppContext} from "./../context/AppContext";


const Layout = (props) => {
	const {appState} = useContext(AppContext);

	const isHomeRoute = () => {
		return window.location.href.endsWith("/home");
	};

	return (
		<div className={appState.theme + " globalRoot"}>
			<div className={"support-locked-content " + (appState.communicationSupportLocked ? "is-locked" : "")}>
				<div className="cover-spin-opaque"></div>
				<div className="cover-spin-content">
					<div className="content">
						<h5>{appState.communicationSupportMessage} ...</h5>
					</div>
				</div>
			</div>
			<Header data={props.data} propagatedAppState={appState} />
			<BrowserRouter>
				<ScrollToTop>
					<div className="l-flex-parent flex-1 body-parent-container">
						<div className="body-container">
							{(!isHomeRoute()) && <Breadcrumb
								globalStyle={appState.theme}
								data={props.data}
								actualRoute={props.actualRoute}
								goBack={props.handleGoBack}
								propagatedAppState={appState}
							/>}
							<Switch>
								{props.routes}
							</Switch>
						</div>
					</div>
				</ScrollToTop>
			</BrowserRouter>
		</div>
	);
};

export default Layout;
