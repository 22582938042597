import "core-js/es6/map";
import "core-js/es6/set";
import React from "react";
import ReactDOM from "react-dom";
import AppContainer from "./containers/AppContainer";
import {
	GetUrlTokenComposition, GetUrlStepComposition, getQueryParameterByName,
	keepValueWithoutQueryParams, activateAdobeAnalyticsHandler,
	activateHotjarHandler,
} from "./utils/utils";

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

/*
  For different environment, to be compatible with Drakkar version, we need to copy process.env variables to window object
*/
(() => {
	/* istanbul ignore next */
	if (window.location.href.indexOf("localhost") > -1) {
		window.REACT_APP_BACKEND_BASEURL = "http://localhost:8080";
	}
})();

window._TOKEN_ = keepValueWithoutQueryParams(GetUrlTokenComposition(window.location.href));
window._STEP_ = keepValueWithoutQueryParams(GetUrlStepComposition(window.location.href));
window._GLOBASTYLE_ = null;

window._SUPPORT_OG_PARAM = getQueryParameterByName("support_op");

ReactDOM.render(
	<AppContainer />, document.getElementById("app"),
);

// CSS 100vh is too tall on mobile due to browser UI, thus we reset it
// The following line replaces the style
//    .globalRoot {
//        min-height: 100vh;
//    }
// That has now been commented
// the following is applied only if the app is not in an Iframe
// if (window.top === window.self) {
//     setInterval(function resizeEffectiveHeight() {
//         let globalRoot = document.querySelector(".cai.globalRoot");
//         if (globalRoot) {
//             globalRoot.style.minHeight = `${window.innerHeight}px`;
//         }
//         return resizeEffectiveHeight;
//     }(), 200);
// }

window.activateAdobeAnalytics = activateAdobeAnalyticsHandler;
window.activateHotjar = activateHotjarHandler;

// If iFrame, add class to the body to recognize it and dispatch height
if (window.top != window.self) {
	const elmnt = document.getElementsByTagName("body")[0];

	elmnt.classList.add("is-iframe");

	const isCaiIframe = true;
	const minHeight = 0;
	const MIN_HEIGHT_WITH_CAPTCHA = 500;
	let currentPath = window.location.pathname;
	let height = 0;
	let body;
	let isCaptchaPresent;

	const message = () => {
		parent.postMessage({isCaiIframe, minHeight, height}, "*");
	};

	setInterval(() => {
		body = body || document.getElementById("app");
		isCaptchaPresent = body && document.getElementById("invisibleCatchaDetection");

		if (body) {
			let h = body.clientHeight;

			if (isCaptchaPresent) {
				h = MIN_HEIGHT_WITH_CAPTCHA;
			}

			if ((h != height) || (currentPath != window.location.pathname)) {
				height = h;
				currentPath = window.location.pathname;
				message();
			}
		}
	}, 200);

	message();
}

if (module.hot) {
	module.hot.accept();
}
