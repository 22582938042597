import React, {Component} from "react";

class IncrementalInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentValue: 0,
		};

		this.onValueChangedHandler = this.onValueChangedHandler.bind(this);
		this.decrementHandler = this.decrementHandler.bind(this);
		this.incrementHandler = this.incrementHandler.bind(this);
		this.minValueValidation = this.minValueValidation.bind(this);
		this.maxValueValidation = this.maxValueValidation.bind(this);
	};

	componentDidMount() {
		// Trigger actions (if any) on load
		const p = this.props;
		const _value = (p.value instanceof Array && p.value.length > 0) ? p.value[0] : p.value;

		this.setState(() => {
			return {currentValue: +_value};
		}, () => {
			this.onValueChangedHandler(true);
		});
	}

	executeChangeListeners(e, isFirstInitialization) {
		const p = this.props;
		const changeEvent = p.events && p.events.find((ev) => {
			return (ev.listener === "change");
		});

		if (changeEvent) {
			// let domevent = { target: { value: e.target.value, id: e.target.id } };
			this.props.assignEvents(e, changeEvent, p, isFirstInitialization);
		}
	}

	onValueChangedHandler(isFirstInitialization) {
		const p = this.props;
		const action = {target: null, value: null};
		const json = {id: p.id, domevent: {target: {value: [this.state.currentValue]}}};

		p.appendValue(json, action);

		const _e = {target: {value: this.state.currentValue, id: p.id}};

		this.executeChangeListeners(_e, isFirstInitialization);
	}

	decrementHandler() {
		this.setState((currentState) => {
			return {currentValue: currentState.currentValue - 1};
		}, () => {
			this.onValueChangedHandler(null);
		});
	}

	incrementHandler() {
		this.setState((currentState) => {
			return {currentValue: currentState.currentValue + 1};
		}, () => {
			this.onValueChangedHandler(null);
		});
	}

	minValueValidation(p) {
		if (p && p.settings && p.settings.minValue && !isNaN(p.settings.minValue) && !isNaN(this.state.currentValue)) {
			return +p.settings.minValue < +this.state.currentValue;
		}

		return true;
	}

	maxValueValidation(p) {
		if (p && p.settings && p.settings.maxValue && !isNaN(p.settings.maxValue) && !isNaN(this.state.currentValue)) {
			return +p.settings.maxValue > +this.state.currentValue;
		}

		return true;
	}

	render() {
		const p = this.props;
		const disableDecrementClass = this.minValueValidation(p) ? "" : "disabled";
		const disableIncrementClass = this.maxValueValidation(p) ? "" : "disabled";

		return (
			<React.Fragment>
				<div className={p.type + " " + p.classes} style={p.style}>
					<button onClick={(e) => this.decrementHandler(e)}
						className={"minus " + disableDecrementClass}><span>&#8722;</span></button>
					<label id={p.id}>{this.state.currentValue}</label>
					<button onClick={(e) => this.incrementHandler(e)}
						className={"plus " + disableIncrementClass}><span>&#43;</span></button>
				</div>
				<div className="input-validation-errors">
					{this.props.validation && this.props.validation.result && Object.keys(this.props.validation.result).map((errorKey) => {
						if (!this.props.validation.result[errorKey].isValid) {
							return (<span key={errorKey}>{this.props.validation.result[errorKey].message + ". "}</span>);
						}
					})}
				</div>
			</React.Fragment>
		);
	};
}

;

export default IncrementalInput;
