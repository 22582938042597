import conversions from "./custom_conversions";

// Go to element by property value (by default property name is id)
const gotoElement = (node, propValue, propName) => {
	propName = propName || "id";

	if (!node || !node.steps || !node.steps.length) {
		return;
	}

	const numOfSteps = node.steps.length;
	let foundNode;

	for (let i = 0; i < numOfSteps; i++) {
		const q = [node.steps[i]];

		foundNode = BFS(q, propValue, propName);

		if (foundNode) {
			break;
		}
	}

	return foundNode;
};


const BFT = (q) => {
	if (!q || q.length === 0) {
		return;
	}

	const node = q.shift();
	const arr = node && (node.widgets || node.options);

	if (arr && arr.length) {
		arr.forEach((w) => {
			q.push(w);
		});
	}

	return BFT(q);
};

const BFS = (q, propValue, propName) => {
	if (!q || q.length === 0) {
		return;
	}

	const node = q.shift();

	if (node && node[propName] === propValue) {
		return node;
	}

	const arr = node && (node.widgets || node.options);

	if (arr && arr.length) {
		arr.forEach((w) => {
			q.push(w);
		});
	}

	return BFS(q, propValue, propName);
};

const gotoElementByName = (tree, name) => {
	return gotoElement(tree, name, "name");
};

const transformationValue = (resItem, action) => {
	if (!resItem || !resItem.value || !action) {
		return resItem;
	}

	if (action.format === "UTC_TIME" && resItem.value && resItem.value.length) {
		resItem.value.push(conversions.toUtcOffset(resItem.value[0], "HH:mm"));
	}

	return resItem;
};

const postValue = (data, json, action) => {
	const result = [];

	if (typeof action.target === "string") {
		const widgetTarget = gotoElement(data, action.target);
		let resItem = {
			widget_id: action.target,
			name: widgetTarget.name,
			value: widgetTarget.value,
			valueLabel: widgetTarget.valueLabel,
		};

		resItem = transformationValue(resItem, action);
		result.push(resItem);
	} else if (action.targetMulti && action.targetMulti instanceof Array) {
		action.targetMulti.forEach((t) => {
			let widgetTarget = gotoElement(data, t);
			const resItem = {
				widget_id: t,
				name: widgetTarget.name,
				value: widgetTarget.value,
				valueLabel: widgetTarget.valueLabel,
			};

			widgetTarget = transformationValue(widgetTarget, action);
			result.push(resItem);
		});
	} else {
		if (typeof action.target !== "string" && action.target) {
			action.target.forEach((t) => {
				const widgetTarget = gotoElement(data, t);
				let resItem = {
					widget_id: t,
					name: widgetTarget.name,
					value: widgetTarget.value,
					valueLabel: widgetTarget.valueLabel,
				};

				resItem = transformationValue(resItem, action);
				result.push(resItem);
			});
		} else {
			const widget = gotoElement(data, json.id);
			let resItem = {
				widget_id: widget.id,
				name: widget.name,
				value: json.domevent.target.value,
				valueLabel: widget.valueLabel,
			};

			resItem = transformationValue(resItem, action);
			result.push(resItem);
		}
	}

	;

	return result;
};

const postConfirm = (data, json, action, actualRoute) => {
	const result = {};

	Object.keys(action.value).forEach(function(key) {
		Object.keys(action.value[key]).forEach((element) => {
			if (result[element] && result[element].length) {
				result[element].push(action.value[key][element]);
			} else result[element] = [action.value[key][element]];
		});
	});
	// disabilitare lato frontend tutte le route precedenti
	data.steps.forEach((step, index) => {
		const parentRoute = actualRoute;

		if (parseInt(parentRoute) > parseInt(step.id)) { // verificare quando gli ID sono puntati (102 vs 1.0.2)
			step.disabled = true;
		}
	});

	return result;
};

/**
 *
 * @param {data} data
 * @param {json} json
 * @param {action} action
 * @return {object}
 *
 */
const storeAndValidateDataOnDemand = (data, json, action) => {
	return action && action.targets ? action.targets.map((t) => {
		const widgetTarget = gotoElement(data, t);
		const result = {
			widget_id: t,
			name: widgetTarget.name,
			value: widgetTarget.value,
			readOnlyValues: widgetTarget.value,
		};

		if (widgetTarget.file_type) {
			result.file_type = widgetTarget.file_type;
		}

		return result;
	}) : [];
};

const postListener = (data, json, action) => {
	const result = {};

	Object.keys(action.value).forEach(function(key) {
		Object.keys(action.value[key]).forEach((element) => {
			if (result[element] && result[element].length) {
				result[element].push(action.value[key][element]);
			} else result[element] = [action.value[key][element]];
		});
	});

	return result;
};


export default {
	postValue,
	postConfirm,
	postListener,
	gotoElement,
	gotoElementByName,
	BFT,
	transformationValue,
	storeAndValidateDataOnDemand,
};
