import React, {useState, useEffect, useRef} from "react";
import useDebounce from "../utils/hooks/useDebounce";
import apiService from "../services/api";

const Autocomplete = (props) => {
	const autocompleteRef = useRef(null);
	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [userInput, setUserInput] = useState((props.value && props.value.length && props.value[0]) || "");
	const debouncedSearchClientTxt = useDebounce(userInput, 400);

	const loadSuggestions = (filterTxt) => {
		const loadSuggestionPromise = new Promise((resolve, reject) => {
			/* istanbul ignore next */
			if (props.settings && props.settings.apiUrl) {
				const apiUrl = `/api/${window._TOKEN_}/${props.settings.apiUrl}?filterTxt=${filterTxt}`;

				apiService.get(apiUrl).then((data) => {
					const suggestions = data && data.data;

					if (suggestions && suggestions.length) {
						resolve(suggestions);
					} else {
						resolve([]);
					}
				});
			} else {
				// Filter our suggestions that don't contain the user's input
				const filteredSuggestions = (props.options || []).filter(
					(suggestion) =>
						suggestion.description.toLowerCase().indexOf(filterTxt.toLowerCase()) > -1,
				);

				resolve(filteredSuggestions);
			}
		});

		return loadSuggestionPromise;
	};

	const clickOutsideHandler = (ev) => /* istanbul ignore next */ {
		if (autocompleteRef &&
            autocompleteRef.current &&
            !autocompleteRef.current.contains(ev.target)) {
			setShowSuggestions(false);
		}
	};

	useEffect(() => {
		(props.value instanceof Array && props.value.length > 0) ? props.value[0] : props.value;
	}, []);

	useEffect(() => {
		window.addEventListener("mousedown", clickOutsideHandler);

		return () => {
			window.addEventListener("mousedown", clickOutsideHandler);
		};
	}, []);

	useEffect(() => {
		(async () => {
			if (!debouncedSearchClientTxt) return;

			const newSuggestions = await loadSuggestions(debouncedSearchClientTxt);

			setFilteredSuggestions(newSuggestions);
		})();
	}, [debouncedSearchClientTxt]);

	// Event fired when the input value is changed
	const onChange = (e) => {
		setUserInput(e.currentTarget.value);
		setShowSuggestions(true);
	};

	// Event fired when the user clicks on a suggestion
	const onClick = (suggestion) => /* istanbul ignore next */ {
		// Update the user input and reset the rest of the state
		setUserInput(suggestion.description);
		saveUpdatedInput(suggestion.description);
		setFilteredSuggestions([]);
		setShowSuggestions(false);
	};

	const saveUpdatedInput = (newValue) => /* istanbul ignore next */ {
		const action = {target: null, value: null};
		const json = {id: props.id, domevent: {target: {value: newValue}}};

		props.appendValue(json, action);
	};

	let suggestionsListComponent = <></>;

	if (showSuggestions && userInput && filteredSuggestions && filteredSuggestions.length) {
		if (filteredSuggestions.length) {
			suggestionsListComponent = (
				<ul className="suggestions">
					{filteredSuggestions.map((suggestion, index) => {
						let className;

						return (
							<li
								className={className}
								key={suggestion.value}
								onClick={() => onClick(suggestion)}
								tabIndex="0"
							>
								{suggestion.description}
							</li>
						);
					})}
				</ul>
			);
		}
	}

	return (
		<React.Fragment>
			{props.label && (<label htmlFor={props.id}>{props.label}</label>)}
			<div className="hrms-autocomplete" ref={autocompleteRef}>
				<input
					type="text"
					onChange={onChange}
					value={userInput}
					id={props.id}
					name={props.id}
				/>
				{suggestionsListComponent}
			</div>
		</React.Fragment>
	);
};

export default Autocomplete;
