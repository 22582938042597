import React, {Component} from "react";
import Markdown from "markdown-to-jsx";
import DOMPurify from "dompurify";

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			helpIsOpen: false,
		};
		this.toggleHelp = this.toggleHelp.bind(this);
	}

	toggleHelp() {
		this.setState({helpIsOpen: !this.state.helpIsOpen});
	}

	render() {
		const {data, propagatedAppState} = this.props;
		let logo = undefined;

		if (data && data.header) {
			logo = propagatedAppState && propagatedAppState.images && propagatedAppState.images.logo;

			if (data.header.logo) logo = data.header.logo;
		}

		const info = propagatedAppState && propagatedAppState.images && propagatedAppState.images.info;

		if (data && typeof data.header === "undefined") return (<div></div>);
		else {
			return (
				<div className="container vtv-header">
					<div className="toolbar">
						<div className="left-side">
							{logo && <img className="image" src={logo} alt="logo" />}
						</div>
						<div className="right-side right-side-info">
							{data && typeof data.header !== "undefined" && data.header.help.enabled && (
								<div className="container help-btn" onClick={this.toggleHelp}>
									<img src={info} width={35} height={35} alt={"Aiuto"} />
								</div>
							)}
						</div>
						{data && typeof data.header !== "undefined" && typeof data.header.text !== "undefined" && data.header.text.enabled && (
							<div className="header-text-wrapper">
								<div className="header-text-content">
									<Markdown>{data.header.text.markdown}</Markdown>
								</div>
							</div>
						)}
						{data && typeof data.header !== "undefined" && data.header.help.enabled && this.state.helpIsOpen && (
							<div className="overflow-help-wrapper">
								<Markdown>{DOMPurify.sanitize(data.header.help.markdown)}</Markdown>
								<br />
								<div className="btn-wrapper">
									<span className="btn-ext-container btn-center">
										<button id="help-btn" className="hrms-btn" onClick={this.toggleHelp}>
											<div className="indents">
												<span className="label">Chiudi</span>
											</div>
										</button>
									</span>
								</div>
							</div>
						)}
					</div>
				</div>
			);
		}
	}
}

export default Header;
