import axios from "axios";

let apiService;

export const interceptorRequestHandler = (request) => {
	if (window._FOURTH_PARAM_ && window._FOURTH_PARAM_.includes("IsSupportOperator")) {
		request.headers["is-support-operator"] = "true";
	}

	const vitrSessionAuthToken = sessionStorage.getItem("Vitr-Session");

	if (vitrSessionAuthToken) {
		request.headers["Vitr-Session"] = vitrSessionAuthToken;
	}

	request.headers["Pragma"] = "no-cache";

	return request;
};

export const responseInterceptorHandler = (error) => {
	const homeUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}/${window._TOKEN_}/home/`;

	if (error &&
      error.response &&
      error.response.status === 500 &&
      error.response.data &&
      (error.response.data.error === "DB_CONNECTION_AWAY" || error.response.data.error === "STATIC_DB_CONNECTION_AWAY")) {
		if (window.location.href !== homeUrl) {
			window.location.href = homeUrl;
		}

		return Promise.reject(error);
	} else if (error.response.status === 401) {
		sessionStorage.removeItem("Vitr-Session");

		if (window.location.href !== homeUrl) {
			window.location.href = homeUrl;
		}

		return Promise.reject(error);
	}

	return Promise.reject(error);
};

if (process.env.NODE_ENV !== "test") {
    apiService = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASEURL,
    });
	
    apiService.interceptors.request.use(
        (request) => interceptorRequestHandler(request),
    );

    apiService.interceptors.response.use(null, responseInterceptorHandler);
}

if (process.env.NODE_ENV === "test") {
    apiService = axios;
}


export default apiService;
