import React, {Component} from "react";
import Markdown from "markdown-to-jsx";
import DOMPurify from "dompurify";

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			position: "absolute",
		};
	};

	componentDidMount() {
		if (window.innerHeight < document.documentElement.offsetHeight) {
			return this.setState({position: "relative"});
		}
		// this.setState({ position: "absolute" });
		// document.getElementsByClassName('lol')[0].setAttribute("style", "height: calc(100vh + 150px)")
	}

	render() {
		const p = this.props;
		const footer = p.data &&p.data.footer;

		return (
			(footer && footer.enabled) ? (
				<div className="hrms-footer" style={{position: "relative"}}>
					<div className="text-container">
						{footer.markdown ? <Markdown>{DOMPurify.sanitize(footer.markdown)}</Markdown> : footer.value}
					</div>
				</div>) :
				null
		);
	};
}

export default Footer;
