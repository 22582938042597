import React, {Component} from "react";
import {withRouter} from "react-router-dom";

class Page extends Component {
	constructor(props) {
		super(props);
	};

	componentDidMount() {
		this.executeMountListeners();
	}

	executeMountListeners() {
		const p = this.props;
		const mountEvent = p.events && p.events.find((ev) => {
			return (ev.listener === "mount");
		});

		if (mountEvent) {
			const domevent = {target: {value: "", id: p.id}};

			this.props.assignEvents(domevent, mountEvent, p, null);
		}
	}

	render() {
		return (
			<div className="main-content">
				{this.props.page}
				{this.props.scrolldown}
				{this.props.footer}
			</div>
		);
	};
}

;

export default withRouter(Page);
