export const appReducer = (state, action) => {
	switch (action.type) {
	case "UPDATE_STATE":
		const newState = {
			...state,
			...action.payload,
		};

		return newState;
	case "IS_APP_LOADING":
		return {
			...state,
			isLoading: true,
		};
	case "APP_ENDED_LOADING":
		return {
			...state,
			isLoading: false,
		};

	default:
		return state;
	}
};
