import Validator, {
	ValidationCollection,
} from "./validator";

import {
	evaluate,
} from "mathjs";

import utilsFunctions from "./utils_actions";

const gotoElement = utilsFunctions.gotoElement;
const gotoElementByName = utilsFunctions.gotoElementByName;

// Callback to apply value to a target (based on target id).
// It expects as input an object with properties "targetId" and "value"/"markdown"
const applyValueBasedOnTargetId = (data, valueToApply) => {
	const _targetId = valueToApply.targetId;
	// let _targetValue;
	const _valueAttributePropertyName = valueToApply.propName;
	const _targetValue = valueToApply.propValue;

	data = setTargetValue(data, _targetId, _targetValue, _valueAttributePropertyName);

	return data;
};

const applyActionBasedOnTargetId = (data, actionToApply) => {
	const _targetId = actionToApply.target;
	const _actionName = actionToApply.name;
	const _targetValue = actionToApply.value;

	switch (_actionName) {
	case "changeRoute":
		data = changeRouteByTargetId(data, _targetId, _targetValue);
		break;
	default:
		break;
	}

	return data;
};

const changeRouteByTargetId = (data, targetId, newRouteValue) => {
	const widgetTarget = gotoElement(data, targetId);

	widgetTarget.events.forEach((evTarget, evIndex) => {
		evTarget.actions.forEach((actTarget, actIndex) => {
			if (actTarget.name === "route") {
				widgetTarget.events[evIndex].actions[actIndex].redirect = newRouteValue;
			}
		});
	});

	return data;
};

const computeStaticConditions = (data) => {
	if (!data.static_interactions ||
        !data.static_interactions.enabled ||
        !data.static_interactions.conditions ||
        !data.static_interactions.conditions.length) {
		return data;
	}

	// conditions
	data.static_interactions.conditions.forEach((interaction) => {
		let conditionRespected = true;

		// and conditions
		const and_conditions_length = (interaction.andConditions && interaction.andConditions.length) || 0;

		for (let i = 0; i < and_conditions_length; i++) {
			const and_conditions = interaction.andConditions[i];

			// interaction.andConditions.forEach(and_conditions => {
			const _sourceId = and_conditions.sourceId;
			const _sourceValue = and_conditions.value;

			// Get current value from id
			const currentValue = getTargetValue(data, _sourceId);

			if (currentValue !== _sourceValue) {
				conditionRespected = false;
				break;
			}
		}

		;

		// --  applyValues  --  If all and_conditions have been met, then execute applyValues array commands
		let _valuesToApply = [];

		if (conditionRespected && interaction.applyValues && interaction.applyValues.length) {
			_valuesToApply = interaction.applyValues;
		} else if (interaction.elseApplyValues && interaction.elseApplyValues.length) {
			_valuesToApply = interaction.elseApplyValues;
		}

		_valuesToApply.forEach((valueToApply) => {
			data = applyValueBasedOnTargetId(data, valueToApply);
		});

		// --  applyActions  --  If all and_conditions have been met, then execute applyActions array commands
		let _actionsToApply = [];

		if (conditionRespected && interaction.applyActions && interaction.applyActions.length) {
			_actionsToApply = interaction.applyActions;
		} else if (interaction.elseapplyActions && interaction.elseapplyActions.length) {
			_actionsToApply = interaction.elseapplyActions;
		}

		_actionsToApply.forEach((actionToApply) => {
			data = applyActionBasedOnTargetId(data, actionToApply);
		});
	});

	return data;
};

const getTargetValue = (data, id, valueAttributePropertyName) => {
	valueAttributePropertyName = valueAttributePropertyName || "value";

	const widget = gotoElement(data, id);
	const w = widget && widget[valueAttributePropertyName];
	const wValue = (w && w instanceof Array) ? w.join(" ") : w;

	return wValue;
};

const setTargetValue = (data, id, newValue, valueAttributePropertyName) => {
	valueAttributePropertyName = valueAttributePropertyName || "value";

	const widget = gotoElement(data, id);

	if (widget) {
		widget[valueAttributePropertyName] = newValue;
	}

	return data;
};

const setTemplateTargetPropValue = (data, json, action) => {
	const retData = setTargetValueByName(data, action.targetElementName, action.propertyValue, action.propertyName);

	return retData;
};

const getTargetValueByName = (data, name, valueAttributePropertyName) => {
	valueAttributePropertyName = valueAttributePropertyName || "value";

	const widget = gotoElementByName(data, name);
	const w = widget && widget[valueAttributePropertyName];
	const wValue = (w && w instanceof Array) ? w.join(" ") : w;

	return wValue;
};

const getDropdownTargetValueByName = (data, name) => {
	const widget = gotoElementByName(data, name);
	const w = widget && widget.value;
	const wValue = (w && w instanceof Array) ? w[0] : w;

	return (typeof wValue.value !== "undefined") ? wValue.value : wValue;
};

const setTargetValueByName = (data, name, newValue, valueAttributePropertyName) => {
	valueAttributePropertyName = valueAttributePropertyName || "value";

	const widget = gotoElementByName(data, name);

	if (widget) {
		const propertiesHierarchy = valueAttributePropertyName.split(".");
		let wToUpdate = widget;

		for (let i = 0; i < propertiesHierarchy.length - 1; i++) {
			wToUpdate = wToUpdate && wToUpdate[propertiesHierarchy[i]];
		}

		newValue = replaceNewValuePlaceholders(data, newValue);
		wToUpdate[propertiesHierarchy[propertiesHierarchy.length - 1]] = newValue;
	}

	return data;
};

const urlReplacer = (data, action) => {
	let textOut = action.url;
	const idRegex = new RegExp("\\$FE\\((.*?)\\)", "g");
	let m;

	do {
		m = idRegex.exec(action.url);

		const foundText = m && (m.length > 0) && m[1];
		let textSubstitution = foundText;

		try {
			if (foundText) {
				const phSlitted = foundText.split("=");

				if (phSlitted.length === 2) {
					const phKey = phSlitted[0];
					const phval = phSlitted[1];

					if (phKey === "elValueName") {
						const _val = getTargetValueByName(data, phval, "value");

						textSubstitution = (_val instanceof Array) ? _val[0] : _val;
					} else if (phKey === "dropDownElValueName") {
						const _val = getDropdownTargetValueByName(data, phval);

						textSubstitution = (_val instanceof Array) ? _val[0] : _val;
					}
				}

				textOut = textOut.replace("$FE(" + foundText + ")", textSubstitution);
			}
		} catch (err) { }
	} while (m);

	return textOut;
};

export const replaceNewValuePlaceholders = (data, textIn) => {
	let textOut = textIn;
	// let resRE = textIn.match(new RegExp("\\$FE\\((.*?)\\)", 'g'));
	const regex = new RegExp("\\$FE\\((.*?)\\)", "g");
	let m;

	do {
		m = regex.exec(textIn);

		const foundText = m && (m.length > 0) && m[1];
		let textSubstitution = foundText;

		try {
			if (foundText) {
				const phSlitted = foundText.split("=");

				if (phSlitted.length === 2) {
					const phKey = phSlitted[0];
					const phval = phSlitted[1];

					if (phKey === "elValueName") {
						const _val = getTargetValueByName(data, phval, "value");

						textSubstitution = (_val instanceof Array) ? _val[0] : _val;
					} else if (phKey === "dropDownElValueName") {
						const _val = getDropdownTargetValueByName(data, phval);

						textSubstitution = (_val instanceof Array) ? _val[0] : _val;
					}
				} else if (phSlitted.length === 1) {
					const phKey = phSlitted[0];

					if (phKey === "baseAppUrl") {
						textSubstitution = window.location.origin;
					}
				}

				textOut = textOut.replace("$FE(" + foundText + ")", textSubstitution);
			}
		} catch (err) { }
	} while (m);

	return textOut;
};

const copyValue = (data, json, action) => {
	const widget = gotoElement(data, json.id);

	if (action.target) {
		const t = gotoElement(data, action.target);

		const checkIfIsBlank = action.ifIsBlank ? action.ifIsBlank : false;

		if (checkIfIsBlank && t.value) { // I don't want to copyValue if value is already filled,
			if (t.value instanceof Array) {
				if (t.value.length > 0) {
					return;
				}
			} else {
				return;
			}
		}

		t.value = widget.value;
		t.label = widget.value;
		t.markdown = widget.value;

		if (widget.valueLabel) {
			t.valueLabel = widget.valueLabel;
		}
	}

	return data;
};

const setValue = (data, json, action) => {
	const widget = gotoElement(data, json.id);

	if (typeof action.targetValue !== "undefined") {
		const t = gotoElement(data, action.targetValue);

		if (action.target && typeof action.value !== "undefined") {
			const target = gotoElement(data, action.target);

			target.value = t.value;
		} else {
			widget.value = t.value;
		}
	} else if (action.target && typeof action.value !== "undefined") {
		const target = gotoElement(data, action.target);

		if (typeof action.value !== "string") {
			target.value = action.value;
		} else {
			target.value = [action.value];
		}
	} else {
		if (typeof json.domevent !== "undefined" && typeof json.domevent.target !== "undefined" && typeof json.domevent.target.value !== "string") {
			widget.value = json.domevent.target.value;

			if (typeof json.domevent.target.label !== "undefined") {
				widget.valueLabel = json.domevent.target.label;
			}
		} else {
			widget.value = [json.domevent.target.value];

			if (typeof json.domevent.target.label !== "undefined") {
				widget.valueLabel = [json.domevent.target.label];
			}
		}
	}

	return data;
};

const setValueCalculation = (data, json, action) => {
	const widget = gotoElement(data, action.target);
	let operationOut = action.operations;
	const idRegex = new RegExp("#{(.*?)}", "g");
	let m;

	do {
		m = idRegex.exec(action.operations);

		const foundId = m && (m.length > 0) && m[1];
		const textSubstitution = gotoElement(data, foundId);

		if (textSubstitution && textSubstitution.value && textSubstitution.value.length > 0) {
			operationOut = operationOut.replace("#{" + foundId + "}", (textSubstitution.value[0] != "" || textSubstitution.value[0]) ? textSubstitution.value[0] : 0);
		} else {
			operationOut = operationOut.replace("#{" + foundId + "}", 0);
		}
	} while (m);

	widget.value = [Number(evaluate(operationOut))];

	return data;
};

const appendValues = (data, json, action) => {
	const widget = gotoElement(data, json.id);

	if (action.target && typeof action.value !== "undefined") {
		const target = gotoElement(data, action.target);

		target.value = [...target.value, action.value];
	} else if (action.copyValueFromWidget) {
		const fromWidget = gotoElement(data, action.copyValueFromWidget);

		if (action.pasteValueToWidget) {
			const toWidget = gotoElement(data, action.pasteValueToWidget);

			toWidget.value = fromWidget.value;
		}
	} else if (typeof action.value !== "undefined") {
		widget.value = [...widget.value, action.value];
	} else {
		widget.value = [...widget.value, json.domevent.target.value];
	}

	return data;
};

const removeValues = (data, json, action) => {
	const widget = gotoElement(data, json.id);

	if (action.target && typeof action.value !== "undefined") {
		const target = gotoElement(data, action.target);

		target.value = target.value.filter(function(m) {
			return m !== action.value;
		});
	} else if (typeof action.value !== "undefined") {
		widget.value = widget.value.filter(function(m) {
			return m !== action.value;
		});
	} else {
		widget.value = widget.value.filter(function(m) {
			return m !== json.domevent.target.value;
		});
	}

	return data;
};

const enableTarget = (data, action) => {
	const widget = gotoElement(data, action.target);

	widget.disabled = false;

	return data;
};

const disableTarget = (data, action) => {
	const widget = gotoElement(data, action.target);

	widget.disabled = true;

	return data;
};

const enableDisableBreadcrumb = (data, json) => {
	const widget = gotoElement(data, json.id);
	const option = gotoElement(data, json.domevent.target.id);

	widget.events.forEach((ev) => {
		ev.actions.forEach((act) => {
			if (act.name === "enableDisableBreadcrumb" && (option.id == act.option || !act.option) &&
                conditionsMet(data, json, act)) {
				const value = act.value;

				if (data && data.breadcrumb) {
					data.breadcrumb.enabled = value;
				}

				;
			}
		});
	});

	return data;
};

const enableDisableBreadcrumbItems = (data, json) => {
	const widget = gotoElement(data, json.id);
	const option = gotoElement(data, json.domevent.target.id);

	widget.events.forEach((ev) => {
		ev.actions.forEach((act) => {
			if (act.name === "enableDisableBreadcrumbItems" && (option.id == act.option || !act.option) &&
                conditionsMet(data, json, act)) {
				const itemTitles = act.value && act.value.itemsTitle;

				if (itemTitles && itemTitles instanceof Array && data && data.breadcrumb) {
					data.breadcrumb.list.forEach((bi) => {
						if (itemTitles.indexOf(bi.breadcrumbId) >= 0) {
							bi.disabled = act.value.disable === true;
						}
					});
				}
			}
		});
	});

	return data;
};

const enable = (data, json, action) => {
	let widget = gotoElement(data, json.id);
	const option = gotoElement(data, json.domevent.target.id);

	// let _data = null;
	widget.events.forEach((ev) => {
		ev.actions.forEach((act) => {
			if (act.target) {
				widget = gotoElement(data, act.target);
			}

			if (act.name === "enable" && (option.id == act.option || !act.option) &&
                conditionsMet(data, json, act)) {
				widget.disabled = false;
				// _data = data;
			}
		});
	});

	return data;
};

const disable = (data, json, action) => {
	let widget = gotoElement(data, json.id);
	const option = gotoElement(data, json.domevent.target.id);

	// let _data = null;
	widget.events.forEach((ev) => {
		ev.actions.forEach((act) => {
			if (act.target) {
				widget = gotoElement(data, act.target);
			}

			if (act.name === "disable" && (!act.option || option.id == act.option) &&
                conditionsMet(data, json, act)) {
				widget.disabled = true;
				// _data = data;
				// data;
			}
		});
	});

	return data;
};

const disableWhen = (data, json, action) => {
	const values = action.predicate.split(":");
	const targetHavingValue = values[0];
	const operator = values[1];
	const valueToCheck = values[2];
	const targetToDisable = action.target;

	const widgetHavingValue = gotoElement(data, targetHavingValue);
	const flatValue = (widgetHavingValue.value instanceof Array) ? widgetHavingValue.value[0] : widgetHavingValue.value;

	let condition;

	switch (operator) {
	case ("ne"):
		condition = (flatValue !== valueToCheck);
		break;
	case ("eq"):
		condition = (flatValue === valueToCheck);
		break;
	}

	const widgetToDisable = gotoElement(data, targetToDisable);

	if (condition) {
		widgetToDisable.disabled = true;
		widgetToDisable.value = [];
	} else {
		widgetToDisable.disabled = false;
	}

	return data;
};

const readOnly = (data, json, action) => {
	const widget = gotoElement(data, json.id);

	widget.events.forEach((ev) => {
		ev.actions.forEach((act) => {
			if (act.name === "readOnly") {
				const widget = gotoElement(data, act.target);

				if (widget.disabled == false) widget.disabled = true;
			}
		});
	});

	return data;
};

const remoteInteraction = (data, res, action, mapper) => {
	if (!mapper) {
		return;
	}

	Object.keys(mapper).forEach((key) => {
		const widget = gotoElement(data, mapper[key]);

		if (action && action.settings && action.settings.overrideOnlyifEmpty &&
            (widget.value && widget.value.length && widget.value[0])) {
			return;
		}

		widget.value = (res && typeof res[key] !== "undefined") ? [res[key]] : [];
	});

	return data;
};

const changeRoute = (data, json, action) => {
	const widget = gotoElement(data, json.id);
	const option = gotoElement(data, json.domevent.target.id);

	widget.events.forEach((ev) => {
		ev.actions.forEach((act) => {
			if ((act.name === "changeRoute")) {
				const value = act.value;
				const widgetTarget = gotoElement(data, act.target);

				widgetTarget.events.forEach((evTarget, evIndex) => {
					evTarget.actions.forEach((actTarget, actIndex) => {
						if (actTarget.name === "route" && (option.id == act.option || !act.option) &&
                            conditionsMet(data, json, act)) {
							widgetTarget.events[evIndex].actions[actIndex].redirect = value;
							// actTarget.redirect = value;
							// return data;
						}
					});
				});
			}
		});
	});

	return data;
};

const validate = (data, json, action) => {
	return new Promise((resolve, reject) => {
		const widget = action.target ? gotoElement(data, action.target) : gotoElement(data, json.id);

		if (typeof widget.validation !== "undefined") {
			const validator = new Validator(widget, data);

			validator.validate(json.showMessages).then((res) => {
				widget.validation.result = Object.assign({}, res);

				if (typeof (json.doNotResolve) !== "undefined" && json.doNotResolve) {
					const resOut = Object.keys(res).map((el) => (res[el].isValid));

					if (resOut.every((elOut) => elOut)) {
						resolve(res);
					} else {
						reject(new Error("Validation failed"));
					}
				} else {
					resolve(data);
				}
			});
		} else {
			resolve(data);
		}
	},
	);
};

const isValid = (data, step, action) => {
	// const currentStep = data.steps.filter((st) => st.id === step.id);
	const validation = new ValidationCollection(step, data);

	return validation.isValid();
};

const isNotValid = (data, step, action) => {
	// const currentStep = data.steps.filter((st) => st.id === step.id);
	const validation = new ValidationCollection(step, data);

	return !validation.isValid();
};

const show = (data, json, action) => {
	const widget = gotoElement(data, json.id);
	const option = gotoElement(data, json.domevent.target.id);
	let hasOption = false;

	widget.events.forEach((ev) => {
		ev.actions.forEach((act) => {
			if (((option && option.id == act.option && act.name === "show") || act.name === "toggle") &&
                conditionsMet(data, json, act)) {
				hasOption = true;

				const target = gotoElement(data, act.target);

				target.visible = true;
			}
		});
	});

	if (!hasOption) {
		widget.events.forEach((ev) => {
			ev.actions.forEach((act) => {
				if (act.name === "show" && !act.option &&
                    conditionsMet(data, json, act)) {
					const target = gotoElement(data, act.target);

					target.visible = true;
				}
			});
		});
	}

	return data;
};

const hideWhen = (data, json, action) => {
	const values = action.predicate.split(":");
	const targetHavingValue = values[0];
	const operator = values[1];
	const valueToCheck = values[2];
	const targetToHide = action.target;

	const widgetHavingValue = gotoElement(data, targetHavingValue);
	const flatValue = (widgetHavingValue.value instanceof Array) ? widgetHavingValue.value[0] : widgetHavingValue.value;


	let isVisible = true;
	let condition;

	switch (operator) {
	case ("ne"):
		condition = (flatValue !== valueToCheck);
		break;
	case ("eq"):
		condition = (flatValue === valueToCheck);
		break;
	}

	if (condition) isVisible = false;

	const widgetToHide = gotoElement(data, targetToHide);

	widgetToHide.visible = isVisible;

	return data;
};

const hide = (data, json, action) => {
	const widget = gotoElement(data, json.id);
	const option = gotoElement(data, json.domevent.target.id);
	let hasOption = false;

	widget.events.forEach((ev) => {
		ev.actions.forEach((act) => {
			if (((option && option.id == act.option && act.name === "hide") || act.name === "toggle") &&
                conditionsMet(data, json, act)) {
				hasOption = true;

				const target = gotoElement(data, act.target);

				target.visible = false;
			}
		});
	});

	if (!hasOption) {
		widget.events.forEach((ev) => {
			ev.actions.forEach((act) => {
				if ((act.name === "hide" && !act.option) &&
                    conditionsMet(data, json, act)) {
					const target = gotoElement(data, act.target);

					target.visible = false;
				}
			});
		});
	}

	return data;
};

const toggle = (data, json) => {
	let result;
	const isVisible = json.domevent?.target?.value?.includes(json.action?.optionValue);

	if (json.action.targetVisibleWhenChecked) {
		result = isVisible ? show(data, json) : hide(data, json);
	} else {
		result = !isVisible ? show(data, json) : hide(data, json);
	}

	return result;
};

const editText = (data, json, action) => {
	const target = gotoElement(data, action.target);

	if (action.option === json.domevent.target.id) {
		if (action.value) {
			target.value = action.value;
			target.markdown = null;
		} else if (action.markdown) {
			target.markdown = action.markdown;
			target.value = null;
		}
	}

	;

	return data;
};

const disableRoute = (data, json, action) => {
	data.steps.forEach((step, index) => {
		if (index == action.target ||
            (action.multiTarget && action.multiTarget.length >0 &&
                action.multiTarget.indexOf(index) >0)
		) {
			step.disabled = true;
		}
	});

	return data;
};


const conditionsMet = (data, json, action) => {
	if (!action.conditions) {
		return true;
	}

	let result = true;

	action.conditions.forEach((condition) => {
		if (!isConditionMet(data, json, condition)) {
			result = false;
		}
	});

	return result;
};

const isConditionMet = (data, json, condition) => {
	const targetId = condition.target || json.id;
	const widget = gotoElement(data, targetId);
	const _value = widget.value;
	const flatValue = (_value instanceof Array) ? _value[0] : _value;
	const flatValueStr = flatValue && flatValue.toString();

	switch (condition.operator) {
	case "in":
		return condition.value && (condition.value.length > 0) && (condition.value.indexOf(flatValueStr) >= 0);
	case "nin":
		return condition.value && (condition.value.length > 0) && (condition.value.indexOf(flatValueStr) < 0);
	default:
		return true;
	}
};

const validateCustomAction = (data, json, action) => {
	return new Promise((resolve, reject) => {
		const widget = gotoElement(data, json.id);

		if (typeof widget.validation !== "undefined") {
			const validator = new Validator(widget, data);

			validator.validate(json.showMessages).then((res) => {
				widget.validation.result = Object.assign({}, res);
				resolve(widget.validation.result);
			});
		}
	});
};

export default {
	urlReplacer,
	copyValue,
	setValue,
	setValueCalculation,
	appendValues,
	removeValues,
	validate,
	validateStep: validate,
	isValid,
	isNotValid,
	enableTarget,
	disableTarget,
	show,
	hide,
	hideWhen,
	toggle,
	enable,
	disable,
	disableWhen,
	readOnly,
	editText,
	disableRoute,
	changeRoute,
	computeStaticConditions,
	enableDisableBreadcrumb,
	getTargetValue,
	enableDisableBreadcrumbItems,
	gotoElement,
	conditionsMet,
	getTargetValueByName,
	setTargetValueByName,
	setTemplateTargetPropValue,
	remoteInteraction,
	validateCustomAction,
	replaceNewValuePlaceholders,
	applyActionBasedOnTargetId,
};
