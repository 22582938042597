import React, {useState, useEffect, useRef} from "react";
import apiService from "../services/api";
import Recaptcha from "react-google-recaptcha";
import {useHistory} from "react-router-dom";
import {isOperatorSupportUser} from "../utils/utils";

import Loader from "./loader";

const ChallengeCaptcha = (props) => {
	const history = useHistory();

	const captcha = useRef(null);
	const [showbar, setShowbar] = useState(true);
	const [canShowComponent, setCanShowComponent] = useState(false);
	const [challengeId, setChallengeId] = useState(null);

	const captchaDoneHandler = (value) => {
		props.executeCallData(value, challengeId, history);
	};

	useEffect(() => {
		// on did mount
		if (props.configValues.skip_captcha || props.configValues.templateOptions.skip_captcha) {
			captchaDoneHandler(null);
		} else {
			apiService.get("api/" + window._TOKEN_ + "/captchaPreview").then((res2) => {
				setCanShowComponent(true);

				if (captcha.current) {
					captcha.current.execute();

					const evBody = {event_name: "captcha_show"};

					if (isOperatorSupportUser(window._SUPPORT_OG_PARAM)) {
						evBody.metadata = {
							isSupport: true,
						};
					}

					apiService.post("api/" + window._TOKEN_ + "/event", evBody).then((res) => {
						setChallengeId(res.data);
					}).catch(function(err) {
						console.log(err);
					});
				}
			}).catch(function(err) {
				console.log(err);
			});
		}

		// on unmount
		return () => {
			setShowbar(false);
		};
	}, []);

	const Challenge = <div>
		<div id="invisibleCatchaDetection" style={{visibility: "hidden", opacity: 0}}>Invisible Captcha Detection</div>
		<Recaptcha
			style={{display: "flex", justifyContent: "center", height: "100%", marginTop: "50px"}}
			sitekey={props.configValues.recaptcha_key}
			onChange={captchaDoneHandler}
			ref={captcha}
			size="invisible" />
		{showbar && <Loader globalStyle={props.globalStyle} />}
	</div>;

	return (
		<React.Fragment>
			{canShowComponent && <div>{Challenge}</div>}
		</React.Fragment>
	);
};

export default ChallengeCaptcha;
