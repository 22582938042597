import React, {createContext, useReducer} from "react";
import {appReducer} from "../reducers/AppReducer";
import {initialAppState as importedInitialAppState} from "./initialAppState";

export const initialAppState = importedInitialAppState;
export const AppContext = createContext();

const AppContextProvider = (props) => {
	const [appState, appDispatcher] = useReducer(appReducer, initialAppState);

	return (
		<AppContext.Provider value={{appState, appDispatcher}}>
			{props.children}
		</AppContext.Provider>);
};

// eslint-disable-next-line
export const getMockAppContextProvider = (_initialAppState) => ((props) => {
	const [appState, appDispatcher] = useReducer(appReducer, _initialAppState);

	return (
		<AppContext.Provider value={{appState, appDispatcher}}>
			{props.children}
		</AppContext.Provider>);
});

export default AppContextProvider;


