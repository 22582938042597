import React, {useState, useEffect} from "react";
import {Route, Redirect} from "react-router-dom";
import {Observable} from "rxjs";
import {GetRedirectTarget, getPreviousStepIfDisabled, getNextStepIfDisabled} from "./utils";

/* Example of route guard taken from npm package 'react-route-guard' (https://github.com/pixelfusion/react-route-guard#readme) */

const SecureRouteLoggerConsoleTheme = {
	normal: "",
	testing: "color: darkcyan; font-size: 0.7rem; font-style: italic;",
	important: "color: green; font-size: 0.7rem; font-style: normal; font-weight: bold",
	error: "color: red; font-size: 0.7rem; font-style: normal; font-weight: bold",
};

export const debugLogger = (className, methodName, msg, displayFormat, extraData) => {
	const messageToPrint = displayFormat ? `%c[${className} - ${methodName}] ${msg}` : `[${className} - ${methodName}] ${msg}`;

	if (displayFormat) {
		if (extraData) {
			console.log(messageToPrint, displayFormat, extraData);
		} else {
			console.log(messageToPrint, displayFormat);
		}
	} else {
		if (extraData) {
			console.log(messageToPrint, extraData);
		} else {
			console.log(messageToPrint);
		}
	}
};

const SecureRoute = (props) => {
	const [hasRouteGuard] = useState(props.hasRouteGuard); // TODO: eslint -> fix unused setHasRouteGuard
	const [routeGuardFinished, setRouteGuardFinished] = useState(false);
	const [routeGuardResult, setRouteGuardResult] = useState(null);

	const shouldRoute = () => {
		let redirectTarget = GetRedirectTarget(window.location.href);

		if (redirectTarget === "home") {
			return true;
		} else {
			redirectTarget = parseInt(redirectTarget);
		}

		if (isNaN(redirectTarget)) {
			redirectTarget = 0;
		}

		const tree = JSON.parse(JSON.stringify(props.appState.data));
		let needsToRoute = false;
		let n = redirectTarget;
		const stepLength = tree.steps.length;
		let searchRoute = true;

		while (searchRoute) {
			if ((n < 0) || (n >= stepLength)) {
				searchRoute = false;
			} else {
				const disabled = tree.steps[n].disabled;

				if (disabled) {
					const nextDefaultStep = props.appState.actualRoute > redirectTarget ? getPreviousStepIfDisabled(tree.steps, n) : getNextStepIfDisabled(tree.steps, n);

					if (nextDefaultStep !== undefined) {
						n = nextDefaultStep;
					} else {
						n = props.appState.actualRoute > redirectTarget ? (n - 1) : (n + 1);
					}
				} else {
					needsToRoute = true;
					searchRoute = false;
					props.appDispatcher({type: "UPDATE_STATE", payload: {actualRoute: n, forceRedirect: props.appState.forceRedirect++}});
				}
			}
		}

		return needsToRoute;
	};

	useEffect(() => {
		if (!hasRouteGuard) {
			return;
		}

		const tempRouteGuardResult = shouldRoute(props.appState, props.appDispatcher);

		if (typeof (tempRouteGuardResult) === "boolean") {
			setRouteGuardFinished(true);
			setRouteGuardResult(tempRouteGuardResult);
		} else if (tempRouteGuardResult instanceof Promise) {
			tempRouteGuardResult.then((result) => {
				setRouteGuardFinished(true);
				setRouteGuardResult(tempRouteGuardResult);
			});
		} else if (tempRouteGuardResult instanceof Observable) {
			tempRouteGuardResult
				.take(1)
				.subscribe((result) => {
					setRouteGuardFinished(true);
					setRouteGuardResult(result);
				});
		}
	}, []);

	let SuccessRoute = <Route {...props} />;

	const redirectPath = props.redirectToPathWhenFail ? props.redirectToPathWhenFail : "/";
	const failRedirect = <Redirect to={redirectPath} />;
	const failComponentRoute = props.componentWhenFail ? <Route path={props.path} component={props.componentWhenFail} /> : null;

	if (routeGuardFinished) {
		if (props.enableDebug) {
			let debugMsg = "route guard passed, render <Route>.";
			let debugTheme = SecureRouteLoggerConsoleTheme.testing;

			if (!routeGuardResult) {
				debugMsg = `route guard fail, render <Redirect to=${redirectPath} />`;
				debugTheme = SecureRouteLoggerConsoleTheme.error;
			}

			debugLogger("secured-route", "render", debugMsg, debugTheme);
		}

		if (!routeGuardResult) {
			// `componentWhenFail` got higher priority than `redirectToPathWhenFail`
			SuccessRoute = props.componentWhenFail ? failComponentRoute : failRedirect;
		}
	} else {
		SuccessRoute = null;
	}

	return (
		<>
			{hasRouteGuard ? SuccessRoute : <Route {...props} />}
		</>
	);
};

export default SecureRoute;
