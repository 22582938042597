import React, {Component} from "react";
import InputMask from "react-input-mask";
import numbro from "numbro";
import conversions from "./../utils/custom_conversions";

class Input extends Component {
	constructor(props) {
		super(props);
		this.eventHandler = this.eventHandler.bind(this);
		this.executeCustomConversions = this.executeCustomConversions.bind(this);
	};


	executeCustomConversions(txtIn) {
		let txtOut = txtIn;

		conversions.conversionFunctionsName.forEach((fName) => {
			if (txtIn && txtIn.indexOf("$" + fName) >= 0) {
				txtOut = conversions[fName](txtOut, this.props.configValues);
			}
		});

		return txtOut;
	}


	componentDidMount() {
		// Trigger actions (if any) on load
		const p = this.props;
		let _value = (p.value instanceof Array && p.value.length > 0) ? p.value[0] : p.value;

		_value = this.executeCustomConversions(_value);

		if (p && p.settings && p.settings.formatSettings && p.settings.formatMethod) {
			_value = numbro.unformat(_value);
		}

		const _e = {target: {value: _value, id: p.id}};

		this.eventHandler(_e);
		this.executeEventListeners(_e, true, false, "change");

		const mountedEvent = p.events && p.events.find((ev) => {
			return (ev.listener === "mount");
		});

		if (mountedEvent) {
			const domevent = {target: {value: "", id: p.id}, showMessages: false};

			this.props.assignEvents(domevent, mountedEvent, p);
		}
	}

	eventHandler(e, eventType) {
		const action = {target: null, value: null};
		let _value = e.target.value;

		if (this.props.settings && this.props.settings.formatSettings && this.props.settings.formatMethod) {
			_value = numbro.unformat(_value);
		}

		const json = {
			id: this.props.id,
			domevent: {
				target: {
					value: (_value !== "" && !Array.isArray(_value)) ? [_value] : (Array.isArray(_value) && _value.length > 0) ? _value : [],
				},
			},
		};

		this.props.appendValue(json, action);
		this.executeEventListeners(e, null, true, eventType);
	}

	executeEventListeners(e, isFirstInitialization, showMessages, eventListener) {
		const p = this.props;
		const changeEvent = p.events && p.events.find((ev) => {
			return (ev.listener === eventListener);
		});

		if (changeEvent) {
			let _value = e.target.value;

			if (p && p.settings && p.settings.formatSettings && p.settings.formatMethod) {
				_value = numbro.unformat(_value);
			}

			const domevent = {
				target: {
					value: (_value !== "" && !Array.isArray(_value)) ? [_value] : (Array.isArray(_value) && _value.length > 0) ? _value : [],
					id: e.target.id,
				},
				showMessages: showMessages,
			};

			this.props.assignEvents(domevent, changeEvent, p, isFirstInitialization);
		}
	}

	render() {
		const p = this.props;
		let _type = (p.settings && p.settings.type) ? p.settings.type : "text";
		const nativeValidation = typeof p.nativeValidation != "undefined" ? true : false;
		let rawValue = typeof this.props.value[0] !== "undefined" ? this.props.value[0] : "";

		rawValue = this.executeCustomConversions(rawValue);

		let _value = rawValue;

		if (p && p.settings && p.settings.formatSettings && p.settings.formatMethod && rawValue !== "") {
			_value = numbro(rawValue)[p.settings.formatMethod](p.settings.formatSettings);
			_type = "text";
		}

		const disabled = this.props.disabled || (this.props.readOnlyValues && this.props.readOnlyValues.length);

		const errors = this.props.validation && this.props.validation.result && Object.keys(this.props.validation.result).map((errorKey) => {
			if (!this.props.validation.result[errorKey].isValid && this.props.validation.result[errorKey].message) {
				return (<li key={errorKey}>{this.props.validation.result[errorKey].message}</li>);
			}
		});

		let Input = <input id={p.id} name={p.id} type={_type} placeholder={p.placeholder} disabled={disabled}
			onChange={(e) => this.eventHandler(e, "change")}
			className={p.type + " " + p.classes}
			value={_value}
		/>;

		if (nativeValidation) {
			Input = <input id={p.id} name={p.id} type={_type} placeholder={p.placeholder} disabled={disabled}
				{...p.nativeValidation}
				onChange={(e) => this.eventHandler(e, "change")}
				className={p.type + " " + p.classes}
				value={_value}
			/>;
		}

		const defineMask = (p.settings && p.settings.defineMask) ? p.settings.defineMask : null;

		if (defineMask) {
			Input = <InputMask className={p.type + " inputmask " + p.classes} id={p.id} name={p.id} mask={defineMask} disabled={disabled}
				placeholder={p.placeholder}
				onChange={(e) => this.eventHandler(e, "change")}
				value={_value}
			/>;
		}

		const hasBlurEvent = p.settings && p.settings.hasBlurEvent;

		if (hasBlurEvent) {
			Input = <input id={p.id} name={p.id} type={_type} placeholder={p.placeholder} disabled={disabled}
				onChange={(e) => this.eventHandler(e, "change")}
				onBlur={(e) => this.eventHandler(e, "blur")}
				className={p.type + " " + p.classes}
				value={_value}
			/>;
		}

		const errorClass = errors && errors.find((el) => el !== undefined) ? "error-inside-input" : "";

		return (
			<>
				<div className={p.type + " " + p.classes + " " + errorClass} style={p.style}>
					<div className="up-label-errors">
						<ul>
							{errors}
						</ul>
					</div>
					{p.label && (<label htmlFor={p.id}>{p.label}</label>)}
					{Input}
				</div>

			</>
		);
	};
}

;

export default Input;
