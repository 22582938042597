import React, {Component} from "react";

class ErrorMessage extends Component {
	constructor(props) {
		super(props);
	};

	render() {
		return (
			<div style={{background: "#193a56", color: "#FFFFFF", position: "fixed", width: "100%", height: "100%", top: 0, bottom: 0, left: 0, right: 0}}>
				{/*
                <div style={{textAlign: 'center', fontFamily: 'Helvetica, Arial, Sans-Serif', marginTop: '7em'}}>
                    <span style={{fontSize: '8em'}}>{this.props.status}</span>
                </div>
                */}
				<div style={{textAlign: "center", fontFamily: "Helvetica, Arial, Sans-Serif", marginTop: "7em"}}>
					<span style={{fontSize: "1em"}}>{this.props.message}</span>
				</div>
				{/*
                <div style={{textAlign: 'center', fontFamily: 'Helvetica, Arial, Sans-Serif', marginTop: 20}}>
                    <span style={{fontSize: '2.2em'}}>{this.props.message}</span>
                </div>
                */}
			</div>
		);
	};
}

export default ErrorMessage;
