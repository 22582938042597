import React, {useState, useEffect, useRef, useCallback} from "react";
import apiService from "../services/api";
import {withRouter} from "react-router-dom";
import {Uploader} from "../utils/imports";
import Modal from "react-modal";
import Moveable from "react-moveable";
import {Frame} from "scenejs";
import {v4 as uuidv4} from "uuid";

if (document.getElementById("app")) {
	Modal.setAppElement("#app");
}

const modalCustomStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};

const impatcPointsCode = {
	TOP_LEFT: "top-left",
	TOP_CENTER: "top-center",
	TOP_RIGHT: "top-right",
	CENTER_LEFT: "center-left",
	CENTER_CENTER: "center-center",
	CENTER_RIGHT: "center-right",
	BOTTOM_LEFT: "bottom-left",
	BOTTOM_CENTER: "bottom-center",
	BOTTOM_RIGHT: "bottom-right",
};

const getCoordsFromContainer = (containerId, clientX, clientY) => {
	const moveableContainer = document.getElementById(containerId);
	const rect = moveableContainer.getBoundingClientRect();

	const xFromContainer = clientX - rect.left;
	const yFromContainer = clientY - rect.top;

	return {
		xFromContainer,
		yFromContainer,
	};
};

const getSimplifiedImpactedPopintsList = (ipList) => {
	return (ipList.map((ip) => ({relCoords: ip.relCoords, id: ip.id, photosId: ip.photosId}))) || [];
};

const isInsideContainer = (containerId, left, top) => {
	const container = document.getElementById(containerId);
	const rect = container.getBoundingClientRect();
	const widthContainer = rect.width;
	const heightContainer = rect.height;

	return (
		left >= 0 &&
        left <= (widthContainer) &&
        top >= 0 &&
        top <= (heightContainer)
	);
};

const GridImageUploaderUrto = (props) => {
	const [currentModalOpenedIp, setCurrentModalOpenedIp] = useState(null);
	const [modalIsOpen, setIsOpen] = React.useState(false);

	const [impactedPoints, setImpactedPoints] = useState([]);
	const itemsRef = useRef([]);
	const [algoImpactedPoints, setAlgoImpactedPoints] = useState([]);

	const postValueImpactedPoints = (impactedPoints) => {
		const _impactedPoints = (impactedPoints &&
            impactedPoints.length &&
            getSimplifiedImpactedPopintsList(impactedPoints)
		) || [];
		const body = {
			"data": [
				{
					"widget_id": props.id,
					"name": props.name,
					"value": _impactedPoints,
				},
			],
		};
		const apiValue = "/api/" + window._TOKEN_;

		apiService.post(apiValue, body);
	};

	const saveUpdatedImpactedPoints = (updatedImpactedPoints) => {
		postValueImpactedPoints(updatedImpactedPoints);
		setImpactedPoints(updatedImpactedPoints);

		const action = {target: null, value: null};
		const json = {id: props.id, domevent: {target: {value: getSimplifiedImpactedPopintsList(updatedImpactedPoints)}}};

		props.appendValue(json, action);
	};

	const computeImpactedPoints = () => {
		const _impactedPoints = props.value;

		// First see if impacted points have already been selected by user
		if (_impactedPoints) {
			const userImpactedPoints = (_impactedPoints && _impactedPoints.length &&
				_impactedPoints.map((ip) => ({
					...ip,
					target: null,
					frame: null,
				}))
			) || [];

			setImpactedPoints(userImpactedPoints);

			return;
		}
	};

	const algorithmImpactedPoints = () => {
		const _algoImpactedPoints = [];

		if (props.settings && props.settings.hideDefaultSuggestedDamages) {
			return;
		}

		// Otherwise, show predicted points

		// If the user confirmed the proposed dynamic (if "id": "35-0-0-11" ("name": "bareme_dynamic_user_confirmed_or_modified")
		// has value ["BATCH_BAREMES_CONFIRMED"]), or in case of kasco (if "id": "35-0-0-12" has value ["true"])
		// -->  than search for the main impact point
		// (template "main_impact_point" property, that is inserted as placeholder in "id": "35-0-0-10" ("name": "main_impact_point_widget")
		const btnConfirmedModifiedPlainValue = props.getTargetValueByName("bareme_dynamic_user_confirmed_or_modified", "value");
		const kascoCaseValue = props.getTargetValueByName("is_kasco_case", "value");

		if ((btnConfirmedModifiedPlainValue === "BATCH_BAREMES_CONFIRMED") || (kascoCaseValue === "true")) {
			const mainImpactPointValue = props.getTargetValueByName("main_impact_point_widget", "value");

			if (mainImpactPointValue) {
				_algoImpactedPoints.push(mainImpactPointValue.toLowerCase());
			}
		}

		if (_algoImpactedPoints && _algoImpactedPoints.length >= 0) {
			setAlgoImpactedPoints(_algoImpactedPoints);
		}
	};

	useEffect(() => {
		itemsRef.current = itemsRef.current.slice(0, impactedPoints.length);
		itemsRef.current.forEach((curr) => {
			curr.updateTarget();
		});
	}, [impactedPoints.length]);

	useEffect(() => {
		computeImpactedPoints();
		algorithmImpactedPoints();
	}, []);

	const onWindowResize = useCallback(() => {
		itemsRef.current.forEach((curr) => {
			curr.updateTarget();
		});
	}, []);

	useEffect(() => {
		if (!impactedPoints || !impactedPoints.length) {
			return;
		}

		const _ips = [...impactedPoints];

		if (_ips.length && _ips[0] === null) {
			_ips = [];

			return;
		}

		_ips.forEach((ip, i) => {
			ip.target = document.querySelector(`.moveable_${ip.id}`);
			ip.frame = new Frame({
				width: "38px",
				height: "38px",
			});

			let _x = 0;
			let _y = 0;

			if (ip.relCoords) {
				_x = ip.relCoords.x;
				_y = ip.relCoords.y;
			}

			ip.frame.set("left", `${_x}px`);
			ip.frame.set("top", `${_y}px`);
			ip.target.style.cssText = ip.frame.toCSS();
		});
		setImpactedPoints(_ips);

		window.addEventListener("resize", onWindowResize);

		return () => {
			window.removeEventListener("resize", onWindowResize);
		};
	}, [onWindowResize, impactedPoints.length]);

	const openModal = (ip) => {
		setCurrentModalOpenedIp(ip);
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
		setCurrentModalOpenedIp(null);
	};

	const moveTarget = (target, left, top) => {
		const currentMovId = target.dataset.movid;
		const _ips = impactedPoints.map((ip, i) => {
			if (ip.id == currentMovId) {
				const _ip = {...ip};

				_ip.frame.set("left", `${left}px`);
				_ip.frame.set("top", `${top}px`);
				_ip.target.style.cssText = _ip.frame.toCSS();
				_ip.relCoords = {
					x: left,
					y: top,
				};

				return _ip;
			}

			return ip;
		});

		setImpactedPoints(_ips);
	};

	const onDrag = (e) => {
		const {target, left, top} = e;

		moveTarget(target, left, top);
	};
	const onDragEnd = (e, ip) => {
		const {target, lastEvent} = e;

		if (target && !lastEvent) {
			openModal(ip);
		}

		if (!target || !lastEvent) {
			return;
		}

		const xFromContainer = (lastEvent && lastEvent.left) || 0;
		const yFromContainer = (lastEvent && lastEvent.top) || 0;

		const deltaLeft = (lastEvent.translate && lastEvent.translate.length && lastEvent.translate[0]) || 0;
		const deltaTop = (lastEvent.translate && lastEvent.translate.length > 0 && lastEvent.translate[1]) || 0;
		const xPrevious = xFromContainer - deltaLeft;
		const yPrevious = yFromContainer - deltaTop;

		if (!isInsideContainer("moveableContainer", xFromContainer, yFromContainer)) {
			moveTarget(target, xPrevious, yPrevious);
		} else {
			saveUpdatedImpactedPoints([...impactedPoints]);
			openModal(ip);
		}
	};

	const addIpHandler = (xFromContainer = 0, yFromContainer = 0) => {
		const newIp = {
			id: uuidv4(),
			target: null,
			frame: null,
			relCoords: {
				x: xFromContainer,
				y: yFromContainer,
			},
			photosId: [],
		};

		saveUpdatedImpactedPoints([...impactedPoints, newIp]);
	};

	const newPointOnMouseClickHandler = (e) => {
		// check if click was not on existing impact point
		if (!e.target || !e.target.id || e.target.id != "moveableContainer") {
			return;
		}

		const {xFromContainer, yFromContainer} = getCoordsFromContainer("moveableContainer",
			e.clientX,
			e.clientY);

		addIpHandler(xFromContainer, yFromContainer);
	};

	const photoSelectedHandler = (photo_id) => {
		photoClickHandler(photo_id);
	};

	const renderUpload = () => {
		let uploadEl = props && props.widgets && props.widgets.length > 0 && props.widgets[0];

		if (props.settings && props.settings.uploadRefId) {
			uploadEl = props.gotoElement(props.settings.uploadRefId);
		}

		if (uploadEl) {
			return <div>
				<div>
					<Uploader
						appendValues={props.appendValues} removeValues={props.removeValues}
						triggerCheckScrolldown={props.triggerCheckScrolldown} globalStyle={props.globalStyle}
						{...uploadEl} composeWidget={props.composeWidget} propagatedAppState={props.propagatedAppState}
						data={props.data} assignEvents={props.assignEvents} addEvents={props.addEvents}
						isPhotoOnlySelectable={true} photoSelectedIds={currentModalOpenedIp && currentModalOpenedIp.photosId}
						photoSelectedCb={photoSelectedHandler} />
				</div>
			</div>;
		}

		return null;
	};

	const photoSelectionConfirmHandler = () => {
		if (!currentModalOpenedIp) {
			return;
		}

		const _ips = impactedPoints.map((ip) => {
			if (ip.id !== currentModalOpenedIp.id) {
				return ip;
			} else {
				return {
					...ip,
					photosId: currentModalOpenedIp.photosId,
				};
			}
		});

		saveUpdatedImpactedPoints(_ips);
		closeModal();
	};

	const removePointOnMouseClickHandler = () => {
		if (!currentModalOpenedIp) {
			return;
		}

		const _ips = impactedPoints.filter((ip) => ip.id !== currentModalOpenedIp.id);

		if (!_ips.length) {
			_ips.push[null];
		}

		saveUpdatedImpactedPoints(_ips);
		closeModal();
	};

	const photoClickHandler = (ph_id) => {
		const _currentModalOpenedIp = {...currentModalOpenedIp};

		if (!_currentModalOpenedIp.photosId ||
            !_currentModalOpenedIp.photosId.length) {
			_currentModalOpenedIp.photosId = [ph_id];
		} else {
			const foundId = _currentModalOpenedIp.photosId.find((ciph) => ciph === ph_id);

			if (foundId || foundId === 0) {
				_currentModalOpenedIp.photosId = _currentModalOpenedIp.photosId.filter((ciph) => ciph !== ph_id);
			} else {
				_currentModalOpenedIp.photosId.push(ph_id);
			}
		}

		setCurrentModalOpenedIp(_currentModalOpenedIp);
	};

	const renderGridImpactedPoints = () => {
		const gridElementsArray = [];

		if (!algoImpactedPoints || !algoImpactedPoints.length) {
			return;
		}

		for (const ipc in impatcPointsCode) {
			let isImpactedClass = "";
			const ipcValue = impatcPointsCode[ipc];

			if (algoImpactedPoints.indexOf(ipcValue) >= 0) {
				isImpactedClass = "is-impacted";
			}

			gridElementsArray.push(<div
				className={`ip ${isImpactedClass}`}
				key={ipc}>
			</div>);
		}

		return gridElementsArray;
	};

	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={modalCustomStyles}
				className="grid-uploader-modal-content"
			>
				<div className="modal-header">
					<p className="title">Foto urto</p>
					<button className="button close-modal-btn" onClick={closeModal}><i className="fas fa-times"></i></button>
				</div>
				<div className="modal-content">
					{props.settings && props.settings.modalMainText &&
                    <div className="modal-main-text">{props.settings.modalMainText}</div>
					}
					{renderUpload()}
					<button className="button add-phoyto-btn"
						onClick={photoSelectionConfirmHandler}><i className="fas fa-camera"></i> Conferma</button>
					<button className="button link-btn"
						onClick={removePointOnMouseClickHandler}>Elimina urto</button>
				</div>
			</Modal>

			<div className={props.type + " " + props.classes} style={props.style}>
				<div className="pure-container">
					<div className="hrms-grid-image-uploader-urto">

						<div className="algo-impacted-points-container">
							<div className="grid-impacted-points">
								{
									renderGridImpactedPoints()
								}
							</div>
						</div>

						{impactedPoints.map((ip, i) => {
							return (<Moveable
								key={ip.id}
								ref={(el) => itemsRef.current[i] = el}
								target={ip.target}
								pinchThreshold={20}
								container={document.getElementById("moveableContainer")}
								draggable={true}
								scalable={false}
								resizable={false}
								warpable={false}
								rotatable={false}
								pinchable={false}
								origin={false}
								throttleDrag={1}
								throttleRotate={0.2}
								throttleResize={1}
								throttleScale={0.01}
								onDrag={onDrag}
								onDragEnd={(e) => onDragEnd(e, ip)}
							/>
							);
						})}

						<div className="container" id="moveableContainer"
							onMouseDown={newPointOnMouseClickHandler}>
							{impactedPoints.map((ip, i) => {
								const hasPhotoAssociated = !!(ip.photosId && ip.photosId.length > 0);
								let associatedClass = "";

								if (hasPhotoAssociated) {
									associatedClass = "has-photo-associated";
								}

								return (<div className={`moveable_${ip.id} ${associatedClass}`} data-movid={`${ip.id}`}
									// onClick={() => setSelectedMoveableHandler(ip)
									onClick={() => openModal(ip)}
									key={ip.id}>
									<div className="moveable-content"
										onClick={() => openModal(ip)}>
										{hasPhotoAssociated && <div><i className="fas fa-check"></i></div>}
										{!hasPhotoAssociated && <div><i className="fas fa-camera"></i></div>}
									</div>
								</div>
								);
							},
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withRouter(GridImageUploaderUrto);
